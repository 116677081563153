import React from "react";
import { Badge } from "react-bootstrap";
import { PiCheckFat, PiHandLight } from "react-icons/pi";

const ActivationBadge = ({ activationValue, size }) => {
  const defaultSize = size ? size : 18;

  return (
    <>
      {!activationValue ? (
        <Badge bg="danger" text="light">
          <PiHandLight size={defaultSize}></PiHandLight>
        </Badge>
      ) : (
        <Badge bg="success" text="light">
          <PiCheckFat size={defaultSize}></PiCheckFat>
        </Badge>
      )}
    </>
  );
};

export default ActivationBadge;
