import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const generateXLSX = ({ csvData, filename, button }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const FileExtension = ".xlsx";

  const ws = XLSX.utils.json_to_sheet(csvData);

  // Calculer la largeur maximale de chaque colonne
  const colWidths = csvData.reduce((widths, row) => {
    Object.values(row).forEach((value, i) => {
      const length = String(value).length;
      if (length > (widths[i] || 0)) widths[i] = length;
    });
    return widths;
  }, []);

  // Définir la largeur des colonnes
  ws["!cols"] = colWidths.map((wch) => ({ wch }));

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, filename + FileExtension);
};

export default generateXLSX;
