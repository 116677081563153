import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { Accordion, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  LuArrowLeftSquare,
  LuFile,
  LuFileScan,
  LuSquareStack,
} from "react-icons/lu";
import * as yup from "yup";
import OperateurGroupeIcon from "../../../../components/CustomIcons/OperateurGroupeIcon/OperateurGroupeIcon";
import PosteTypeIcon from "../../../../components/CustomIcons/PosteTypeIcon/PosteTypeIcon";
import StockageTypeIcon from "../../../../components/CustomIcons/StockageTypeIcon/StockageTypeIcon";
import ProductStatutBadge from "../../../../components/badgeComponents/ProductStatutBadge/ProductStatutBadge";
import BigInputSearch from "../../../../components/inputs/BigInputSearch/BigInputSearch";
import config from "../../../../config/config";
import { useArrowScroll } from "../../../../customHooks/useArrowScroll";
import useFetch from "../../../../customHooks/useFetch";
import "./AtelierLocaliser.css";

const schema = yup
  .object({
    // code_barre: yup.string()
    // .matches(/^[0-9]{8}[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}[1234]{1}[0-9A-Z]{3}$/, 'Le code-barre ne correspond pas aux critères requis')
    // .length(20, 'Le code-barre doit contenir exactement 20 caractères')
    // .required('Le code-barre est requis'),
  })
  .required();

const AtelierLocaliser = () => {
  const apiUrlSeachProduct = config.apiUrl + "/product-localiser/";
  const inputRef = useRef(null);
  const scrollRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [localData, setLocalData] = useState(null);
  const { data, triggerFetch: triggerFetchSearchProduct } = useFetch(
    apiUrlSeachProduct,
    "GET",
    []
  );

  useArrowScroll(scrollRef);

  const {
    register,
    formState: { isValid },
    handleSubmit,
    setFocus,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleKeyDown = (event) => {
    if (event && event.key === "Enter") {
      event.preventDefault();
      handleSubmit(handleFormSubmit)();
      reset();
    }
  };

  const handleFormSubmit = (data) => {
    setLocalData(null);
    if (isValid) {
      setSearchValue(data.search);
      triggerFetchSearchProduct(
        apiUrlSeachProduct + data.search,
        {
          method: "GET",
        },
        false,
        false
      );
      inputRef.current.value = "";
    }
  };

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (data) {
      setLocalData(data);
    }
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        // Clic à l'extérieur du champ, rétablir le focus
        inputRef.current.focus();
      }
    };

    // Ajouter un écouteur d'événements pour gérer les clics à l'extérieur
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Nettoyer l'écouteur d'événements lors du démontage du composant
      document.removeEventListener("click", handleClickOutside);
    };
  }, [inputRef]);

  return (
    <div className="atelier-localiser h-100">
      <Form noValidate>
        <BigInputSearch
          inputRef={inputRef}
          labelClassName="h2 text-uppercase"
          className="bg-perform text-uppercase border border-3 border-black fw-bold"
          controlId="search"
          control={control}
          name="search"
          setFocus={setFocus}
          registerFunction={register}
          onKeyDown={handleKeyDown}
        />
      </Form>

      <Row className="w-100 h-100">
        {localData && Object.keys(localData).length ? (
          <div ref={scrollRef} className="content text-center">
            <h1 className="mb-4">
              Résultat pour votre recherche "{searchValue}":
            </h1>
            <Accordion
              defaultActiveKey={Object.keys(localData).length === 1 && 0}
            >
              {Object.keys(localData).map((extension, indexExtension) => (
                <Accordion.Item
                  key={indexExtension}
                  eventKey={indexExtension}
                  className="border-0 mb-1"
                >
                  <Accordion.Header className="border">
                    <h2>
                      <LuFile className="me-2" />
                      {extension}
                    </h2>
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    <Accordion
                      defaultActiveKey={
                        Object.keys(localData[extension]).length === 1 &&
                        indexExtension + ".0"
                      }
                      className="mx-3 my-1"
                    >
                      {Object.keys(localData[extension]).map(
                        (position, indexPosition) => (
                          <Accordion.Item
                            key={indexExtension + "." + indexPosition}
                            eventKey={indexExtension + "." + indexPosition}
                            className="border-0 mb-1"
                          >
                            <Accordion.Header className="border">
                              <h2>
                                <LuFileScan className="me-2 ms-2" />
                                position {parseInt(position)}
                              </h2>
                            </Accordion.Header>
                            <Accordion.Body className="p-0">
                              <Accordion
                                className="mx-3 my-1"
                                defaultActiveKey={
                                  Object.keys(localData[extension][position])
                                    .length === 1 &&
                                  indexExtension + "." + indexPosition + ".0"
                                }
                              >
                                {Object.keys(
                                  localData[extension][position]
                                ).map((quantite, indexQuantite) => (
                                  <Accordion.Item
                                    key={
                                      indexExtension +
                                      "." +
                                      indexPosition +
                                      "." +
                                      indexQuantite
                                    }
                                    eventKey={
                                      indexExtension +
                                      "." +
                                      indexPosition +
                                      "." +
                                      indexQuantite
                                    }
                                    className="border-0 mb-1"
                                  >
                                    <Accordion.Header className="border">
                                      <h2>
                                        <LuSquareStack className="me-2 ms-4" />
                                        quantité {parseInt(quantite)}
                                      </h2>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <Row>
                                        {Object.keys(
                                          localData[extension][position][
                                            quantite
                                          ]
                                        ).map((cadres) =>
                                          Object.keys(
                                            localData[extension][position][
                                              quantite
                                            ][cadres]
                                          ).map((cadre, indexCadre) => {
                                            const cadreValue =
                                              localData[extension][position][
                                                quantite
                                              ][cadres][cadre];
                                            console.log(cadreValue);
                                            console.log(cadres);
                                            return (
                                              <>
                                                <Col
                                                  key={
                                                    indexExtension +
                                                    "." +
                                                    indexPosition +
                                                    "." +
                                                    indexQuantite +
                                                    "." +
                                                    indexCadre
                                                  }
                                                  lg={6}
                                                  xl={4}
                                                  xxl={3}
                                                >
                                                  <Card className="m-0">
                                                    <Card.Body>
                                                      <Card.Title className="mb-4">
                                                        <h2 className="text-uppercase">
                                                          {cadres === "00"
                                                            ? "dormant"
                                                            : "ouvrant " +
                                                              parseInt(cadres)}
                                                        </h2>
                                                      </Card.Title>
                                                      <Card.Text className="h2 text-start">
                                                        {cadreValue.mouvement
                                                          .poste ? (
                                                          <>
                                                            <p className="text-secondary text-center">
                                                              {
                                                                cadreValue
                                                                  .mouvement
                                                                  .creation
                                                              }
                                                            </p>
                                                            <p>
                                                              <ProductStatutBadge
                                                                idStatut={
                                                                  cadreValue
                                                                    .mouvement
                                                                    .product
                                                                    .statut.id
                                                                }
                                                                libelleStatut={
                                                                  cadreValue
                                                                    .mouvement
                                                                    .product
                                                                    .statut
                                                                    .libelle
                                                                }
                                                                size={"xl"}
                                                              ></ProductStatutBadge>
                                                            </p>
                                                            <p>
                                                              <PosteTypeIcon
                                                                idPosteType={
                                                                  cadreValue
                                                                    .mouvement
                                                                    .poste.type
                                                                    .id
                                                                }
                                                                size={2}
                                                              />
                                                              {
                                                                cadreValue
                                                                  .mouvement
                                                                  .poste.nom
                                                              }
                                                            </p>
                                                            <p>
                                                              <OperateurGroupeIcon
                                                                idOperateurGroupe={
                                                                  cadreValue
                                                                    .mouvement
                                                                    .operateur
                                                                    .groupe.id
                                                                }
                                                                size={2}
                                                              />
                                                              {cadreValue
                                                                .mouvement
                                                                .operateur.nom +
                                                                " " +
                                                                cadreValue
                                                                  .mouvement
                                                                  .operateur
                                                                  .prenom}
                                                            </p>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <p className="text-secondary text-center">
                                                              {
                                                                cadreValue
                                                                  .mouvement
                                                                  .creation
                                                              }
                                                            </p>
                                                            <p>
                                                              <ProductStatutBadge
                                                                idStatut={
                                                                  cadreValue
                                                                    .mouvement
                                                                    .product
                                                                    .statut.id
                                                                }
                                                                libelleStatut={
                                                                  cadreValue
                                                                    .mouvement
                                                                    .product
                                                                    .statut
                                                                    .libelle
                                                                }
                                                                size={"xl"}
                                                              ></ProductStatutBadge>
                                                            </p>
                                                            <p>
                                                              <StockageTypeIcon
                                                                idStockageType={
                                                                  cadreValue
                                                                    .mouvement
                                                                    .stockage
                                                                    .type.id
                                                                }
                                                                size={2}
                                                              />
                                                              {
                                                                cadreValue
                                                                  .mouvement
                                                                  .stockage.nom
                                                              }
                                                            </p>
                                                            <p>
                                                              <OperateurGroupeIcon
                                                                idOperateurGroupe={
                                                                  cadreValue
                                                                    .mouvement
                                                                    .operateur
                                                                    .groupe.id
                                                                }
                                                                size={2}
                                                              />
                                                              {cadreValue
                                                                .mouvement
                                                                .operateur.nom +
                                                                " " +
                                                                cadreValue
                                                                  .mouvement
                                                                  .operateur
                                                                  .prenom}
                                                            </p>
                                                          </>
                                                        )}
                                                      </Card.Text>
                                                    </Card.Body>
                                                  </Card>
                                                  <p></p>
                                                </Col>
                                              </>
                                            );
                                          })
                                        )}
                                      </Row>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                ))}
                              </Accordion>
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      )}
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        ) : (
          <div className="content text-center d-flex justify-content-center align-items-center">
            <h1>
              <p>Scannez un code barre</p> <p>OU</p>
              <p>
                Saisissez un numéro de commande et appuyez sur la touche "
                <LuArrowLeftSquare /> ENTREE" <br />
                <span className="h2 fst-italic">(exemple: 2412513)</span>
              </p>
            </h1>
          </div>
        )}
      </Row>
    </div>
  );
};

export default AtelierLocaliser;
