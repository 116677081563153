import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import AlignBox from "../../../../components/AlignBox/AlignBox";
import DashboardCard from "../../../../components/CustomCard/DashboardCard/DashboardCard";
import PosteTypeIcon from "../../../../components/CustomIcons/PosteTypeIcon/PosteTypeIcon";
import CancelFormButton from "../../../../components/buttons/CancelFormButton/CancelFormButton";
import SubmitFormButton from "../../../../components/buttons/SubmitFormButton/SubmitFormButton";
import BasicInput from "../../../../components/inputs/BasicInput/BasicInput";
import FetchSelect from "../../../../components/inputs/FetchSelect/FetchSelect";
import config from "../../../../config/config";
import useFetch from "../../../../customHooks/useFetch";

const schema = yup
  .object({
    type: yup.number().required("Type obligatoire").positive().integer(),

    nom: yup
      .string()
      .required("Nom du poste obligatoire")
      .matches(/^[A-Za-z0-9À-ÖØ-öø-ÿ\s'-]{2,}$/, "Entrez un nom valide"),
  })
  .required();

const PosteEditer = ({ posteData, onSubmitted }) => {
  const apiUrl = config.apiUrl;
  const isComponentMounted = useRef(true);
  const { nom, type } = posteData;
  const posteDataRelevant = {
    nom,
    type: type.id,
  };
  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: posteDataRelevant,
  });

  const formValues = watch();

  const { loading, triggerFetch } = useFetch(apiUrl, isComponentMounted, []);

  const setInitValues = () => {
    reset(posteDataRelevant);
  };

  const hasChanged =
    JSON.stringify(formValues) !== JSON.stringify(posteDataRelevant);

  const onSuccess = () => {
    onSubmitted();
    toast.success("Poste modifié avec succès");
  };

  const onSubmit = (data) => {
    const fullData = { ...data, id: posteData.id };
    triggerFetch(
      apiUrl + "/poste-update",
      {
        method: "POST",
        body: JSON.stringify(fullData),
      },
      onSuccess
    );
  };

  useEffect(() => {
    setInitValues();
  }, []);

  return (
    <Row className="w-100 mt-5">
      <Col xl={3} />
      <Col xl={6}>
        <Form noValidate>
          <DashboardCard theme="light" className="p-5">
            <BasicInput
              labelText="Nom"
              controlId="nom"
              control={control}
              name="nom"
              placeholder="Entrez le nom de l'opérateur"
              autoFocus
              errorMessage={errors.nom?.message}
              registerFunction={register("nom")}
            />
            <FetchSelect
              urlOptions={apiUrl + "/poste-types"}
              labelText="Type"
              onSelectChange={(selectedValue) =>
                setValue("type", selectedValue)
              }
              defaultIdValue={
                formValues.type || (posteDataRelevant && posteDataRelevant.type)
              }
              placeholder="Selectionnez le type de poste"
              control={control}
              name="type"
              option={{
                value: "id",
                text: (row) => (
                  <>
                    <AlignBox>
                      <PosteTypeIcon
                        idPosteType={row.id}
                        size={2.3}
                        className="me-3"
                      />
                      {row.libelle}
                    </AlignBox>
                  </>
                ),
              }}
              setValue={setValue}
              errorMessage={errors.type?.message}
              registerFunction={register("type")}
            />
            <div className="text-end mt-5">
              <CancelFormButton
                disabled={!hasChanged}
                onClick={() => setInitValues()}
              />
              <SubmitFormButton
                disabled={!hasChanged}
                className="ms-3"
                onClick={handleSubmit(onSubmit)}
                loading={loading}
              ></SubmitFormButton>
            </div>
          </DashboardCard>
        </Form>
      </Col>
      <Col xl={3} />
    </Row>
  );
};

export default PosteEditer;
