import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { PiWarningBold } from "react-icons/pi";
import { TbSquareLetterA } from "react-icons/tb";
import * as yup from "yup";
import BasicInput from "../../../../components/inputs/BasicInput/BasicInput";
import config from "../../../../config/config";
import useFetch from "../../../../customHooks/useFetch";
import ScanOperateur from "../../scanComponents/ScanOperateur";
import ScanProduct from "../../scanComponents/ScanProduct";
import ScanEmplacement from "../../scanComponents/scanEmplacement";

const schema = yup
  .object({
    // code_barre: yup.string()
    // .matches(/^[0-9]{8}[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}[1234]{1}[0-9A-Z]{3}$/, 'Le code-barre ne correspond pas aux critères requis')
    // .length(20, 'Le code-barre doit contenir exactement 20 caractères')
    // .required('Le code-barre est requis'),
  })
  .required();

const AtelierDefault = ({ handleError, handleSuccess }) => {
  const apiUrlScanAtelier = config.apiUrl + "/scan-atelier/";
  const apiUrlMouvementCreate = config.apiUrl + "/mouvement-create/";
  const apiUrlProductCreate = config.apiUrl + "/product-create/";
  const inputRef = useRef(null);
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);
  const [scannedOperateur, setScannedOperateur] = useState(null);
  const [scannedEmplacement, setScannedEmplacement] = useState(null);
  const [scannedProduct, setScannedProduct] = useState(null);

  const {
    register,
    formState: { isValid },
    handleSubmit,
    setFocus,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data, triggerFetch: triggerFetchScanAtelier } = useFetch(
    apiUrlScanAtelier,
    "GET",
    []
  );

  const { triggerFetch: triggerFetchMouvementCreate } = useFetch(
    apiUrlMouvementCreate,
    "POST",
    []
  );

  const { triggerFetch: triggerFetchProductCreate } = useFetch(
    apiUrlProductCreate,
    "POST",
    []
  );

  const handleKeyDown = (event) => {
    if (event && event.key === "Enter") {
      event.preventDefault();
      handleSubmit(handleFormSubmit)();
    }

    setIsCapsLockOn(event.getModifierState("CapsLock"));
  };

  const handleFormSubmit = (data) => {
    if (isValid) {
      triggerFetchScanAtelier(
        apiUrlScanAtelier + data.code,
        {
          method: "GET",
        },
        false,
        handleError
      );
      inputRef.current.value = "";
    }
  };

  useEffect(() => {
    switch (data?.type) {
      case "operateur":
        setScannedOperateur(data);
        break;
      case "poste":
        setScannedEmplacement(data);
        break;
      case "stockage":
        setScannedEmplacement(data);
        break;
      case "cadre":
        setScannedProduct(data);
        break;
      default:
        break;
    }
  }, [data]);

  useEffect(() => {
    if (scannedEmplacement && scannedProduct && scannedOperateur) {
      const data = {
        operateur: scannedOperateur.code,
        cadre: scannedProduct.code,
        emplacement: scannedEmplacement.code,
      };

      if (scannedEmplacement.data.type.libelle === "Soudeuse") {
        triggerFetchProductCreate(
          apiUrlProductCreate,
          {
            method: "POST",
            body: JSON.stringify(data),
          },
          handleSuccess,
          handleError
        );
      } else {
        triggerFetchMouvementCreate(
          apiUrlMouvementCreate,
          {
            method: "POST",
            body: JSON.stringify(data),
          },
          handleSuccess,
          handleError
        );
      }
    }
  }, [scannedEmplacement, scannedProduct, scannedOperateur]);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        // Clic à l'extérieur du champ, rétablir le focus
        inputRef.current.focus();
      }
    };

    // Ajouter un écouteur d'événements pour gérer les clics à l'extérieur
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Nettoyer l'écouteur d'événements lors du démontage du composant
      document.removeEventListener("click", handleClickOutside);
    };
  }, [inputRef]);

  return (
    <>
      {isCapsLockOn && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999,
            backgroundColor: "rgba(0,0,0,0.7)",
            textAlign: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "white",
            }}
          >
            <h1>
              <PiWarningBold size={80} /> <br /> Veuillez désactiver la touche
              "Verr Maj <TbSquareLetterA size={60} />
              "
              <br />
              située à gauche du clavier.
            </h1>
          </div>
        </div>
      )}
      <Form noValidate>
        <BasicInput
          inputRef={inputRef}
          labelText="code"
          controlId="code"
          control={control}
          name="code"
          setFocus={setFocus}
          placeholder="code"
          registerFunction={register}
          onKeyDown={handleKeyDown}
          hidden
        />
      </Form>
      <Row className="h-100">
        <motion.div
          key={1}
          className="col-4 h-100"
          initial={{ x: 200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0 }}
        >
          <ScanOperateur operateur={scannedOperateur} />
        </motion.div>
        <motion.div
          key={2}
          className="col-4 h-100"
          initial={{ x: 200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.1 }}
        >
          <ScanEmplacement emplacement={scannedEmplacement} />
        </motion.div>
        <motion.div
          key={3}
          className="col-4 h-100"
          initial={{ x: 200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <ScanProduct product={scannedProduct} />
        </motion.div>
      </Row>
    </>
  );
};

export default AtelierDefault;
