import { motion } from "framer-motion";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import "./Auth.css";

const Auth = () => {
  const location = useLocation();

  return (
    <motion.div
      initial={{ y: -900 }}
      animate={{ y: 0 }}
      exit={{ y: 100 }}
      transition={{ ease: "easeInOut", duration: 0.4 }}
    >
      <div className="authentification-wrapper align-items-center justify-content-center d-flex">
        <motion.div layout>
          <div>
            <div className="content">
              <motion.div
                key={location.pathname}
                initial={{ y: -8, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -8, opacity: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5, delay: 0.2 }}
              >
                <Outlet />
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};
export default Auth;
