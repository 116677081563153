import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import DashboardCard from "../../../components/CustomCard/DashboardCard/DashboardCard";
import OperateurGroupeIcon from "../../../components/CustomIcons/OperateurGroupeIcon/OperateurGroupeIcon";
import PfIconPolygon from "../../../components/CustomIcons/PfIconPolygon/PfIconPolygon";
import QRCodePDF from "../../../components/PDF/QRCodePDF/QRCodePDF";
import DetailsPanel from "../../../components/Panels/DetailsPanel/DetailsPanel";
import QRCodeLogo from "../../../components/QRCodeLogo/QRCodeLogo";
import ActivationBadge from "../../../components/badgeComponents/ActivationBadge/ActivationBadge";
import config from "../../../config/config";
import useFetch from "../../../customHooks/useFetch";
import OperateurEditer from "./OperateurEditer/OperateurEditer";
import TimelineOperateur from "./TimelineOperateur/TimelineOperateur";

const OperateurFiche = () => {
  const { id } = useParams();
  const apiUrl = config.apiUrl + "/operateur/" + id;
  const [contentPage, setContentPage] = useState("activite");
  const isComponentMounted = useRef(true);
  const { data: dataOperateur, triggerFetch: triggerFetchOperateur } = useFetch(
    apiUrl,
    isComponentMounted,
    []
  );
  const triggerFetchOperateurRef = useRef(triggerFetchOperateur);
  const linksContentMenu = [
    {
      text: "Activité",
      nom: "activite",
      onClick: () => {
        setContentPage("activite");
      },
    },
    {
      text: "Editer",
      nom: "editer",
      onClick: () => {
        setContentPage("editer");
      },
    },
  ];
  const refreshData = () => {
    triggerFetchOperateurRef.current(apiUrl, { method: "GET" });
  };

  useEffect(() => {
    triggerFetchOperateurRef.current(apiUrl, {
      method: "GET",
    });
  }, [apiUrl]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 25 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
      className="h-100"
    >
      {dataOperateur && (
        <DetailsPanel>
          <DetailsPanel.Main>
            <DetailsPanel.Main.Title
              title={dataOperateur.nom + " " + dataOperateur.prenom}
              subTitle={dataOperateur.groupe.libelle}
              linksMenu={linksContentMenu}
              activePage={contentPage}
            />
            <DetailsPanel.Main.Content key={contentPage}>
              {contentPage === "editer" ? (
                <OperateurEditer
                  operateurData={dataOperateur}
                  onSubmitted={refreshData}
                />
              ) : (
                <TimelineOperateur idOperateur={id} />
              )}
            </DetailsPanel.Main.Content>
          </DetailsPanel.Main>
          <DetailsPanel.Details>
            <DetailsPanel.Details.Title
              title={dataOperateur.nom + " " + dataOperateur.prenom}
              subTitle={dataOperateur.groupe.libelle}
              iconTitle={
                <OperateurGroupeIcon
                  size={4.5}
                  idOperateurGroupe={dataOperateur.groupe.id}
                />
              }
            />
            <DetailsPanel.Details.Content>
              <DashboardCard className="mb-4">
                <Stack direction="horizontal" gap={3}>
                  <div>
                    <DashboardCard theme="light" className="d-inline-block">
                      <QRCodeLogo
                        size={100}
                        viewBox={`0 0 100 100`}
                        value={dataOperateur.qrcode}
                      >
                        <PfIconPolygon size={50}></PfIconPolygon>
                      </QRCodeLogo>
                    </DashboardCard>
                  </div>
                  <div className="w-50">
                    <div className="Montserrat-SemiBold h5 mb-0">
                      Identification
                    </div>
                    <div className="Montserrat-Regular h6 text-uppercase">
                      QR Code
                    </div>
                    <div className="w-100">{dataOperateur.qrcode}</div>
                  </div>
                </Stack>
                <Stack direction="horizontal" gap={3}>
                  <div className="ms-auto">
                    <QRCodePDF
                      qrCodeData={dataOperateur.qrcode}
                      title={dataOperateur.nom + " " + dataOperateur.prenom}
                      text="Imprimer"
                      variant="textural"
                      buttonSize="sm"
                    />
                  </div>
                </Stack>
              </DashboardCard>
              <DetailsPanel.Infos>
                <DetailsPanel.Infos.Title>Détails</DetailsPanel.Infos.Title>
                <DetailsPanel.Infos.Row
                  libelle="Activation"
                  value={
                    <ActivationBadge
                      activationValue={dataOperateur.activation}
                      size={22}
                    />
                  }
                />
                <DetailsPanel.Infos.Row
                  libelle="Nom"
                  value={dataOperateur.nom}
                />
                <DetailsPanel.Infos.Row
                  libelle="Prénom"
                  value={dataOperateur.prenom}
                />
                <DetailsPanel.Infos.Row
                  libelle="Groupe"
                  value={
                    <div className="d-flex align-items-center">
                      {dataOperateur.groupe.libelle}
                      <OperateurGroupeIcon
                        idOperateurGroupe={dataOperateur.groupe.id}
                        reverse
                        size={2}
                      />
                    </div>
                  }
                />
                <DetailsPanel.Infos.Row
                  libelle="Date de création"
                  value={dataOperateur.creation}
                />
              </DetailsPanel.Infos>
            </DetailsPanel.Details.Content>
          </DetailsPanel.Details>
        </DetailsPanel>
      )}
    </motion.div>
  );
};

export default OperateurFiche;
