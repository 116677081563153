import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveLine } from "@nivo/line";
import { ResponsivePie } from "@nivo/pie";
import React from "react";
import { Badge, Button, Card, Col, Row, Stack } from "react-bootstrap";
import {
  PiArrowBendLeftDown,
  PiArrowBendRightDown,
  PiChartBarHorizontalFill,
  PiChartDonutFill,
  PiChartLineLight,
  PiClockLight,
  PiHammerLight,
  PiTrashLight,
} from "react-icons/pi";
import { animated } from "react-spring";
import "./HalfChartCard.css";

const HalfChartCard = ({
  dataPostes,
  dataPostesLineChart,
  dataOperateurs,
  posteFabricationCount,
  posteAttenteCount,
  posteRebutCount,
  showLineChart,
  onClickRebutButton,
  title,
  subtitle,
  icon,
  footer,
}) => {
  const containerHeight = 50 * dataOperateurs.length;

  return (
    <Card className="m-2 half-chart-card Roboto-Regular">
      {(subtitle || title) && (
        <HalfChartCard.Header icon={icon}>
          {title && <HalfChartCard.Title>{title}</HalfChartCard.Title>}
          {subtitle && (
            <HalfChartCard.Subtitle>{subtitle}</HalfChartCard.Subtitle>
          )}
        </HalfChartCard.Header>
      )}
      {dataPostes && dataPostes.length > 0 && (
        <>
          <Row className="text-center px-3 mt-2">
            <Col>
              <h5>
                <Badge
                  bg="light-primary"
                  text="primary"
                  className="border border-primary border-1 p-2 fw-normal "
                >
                  <PiHammerLight className="me-2" />
                  {posteFabricationCount} opération
                  {posteFabricationCount > 1 && "s"}
                </Badge>
              </h5>
            </Col>
            <Col>
              <h5>
                <Badge
                  bg="light-warning"
                  text="warning"
                  className={
                    "border border-warning border-1 p-2 fw-normal " +
                    (posteAttenteCount ? "opacity-100" : "opacity-25")
                  }
                >
                  <PiClockLight className="me-2" />
                  {posteAttenteCount ? posteAttenteCount : 0} mise
                  {posteAttenteCount > 1 && "s"} en attente
                </Badge>
              </h5>
            </Col>
            <Col>
              <h5>
                <Button
                  className={
                    "bg-light-secondary text-secondary border border-secondary border-1 shadow-sm px-2 py-1 " +
                    (posteRebutCount ? "opacity-100" : "opacity-25")
                  }
                  onClick={onClickRebutButton}
                >
                  <PiTrashLight className="me-2" />
                  {posteRebutCount ? posteRebutCount : 0} mise
                  {posteRebutCount > 1 && "s"} au rebut
                </Button>
                <Badge></Badge>
              </h5>
            </Col>
          </Row>
          <Row className="text-center px-3 mb-2">
            <Col sm={4} xs={12}>
              <div className="text-center px-3 pt-2 text-secondary">
                <PiArrowBendLeftDown size={15} className="mt-2" />
                <PiChartDonutFill size={15} className="me-2" />
                Quantité par poste sur la période
              </div>
              <div style={{ height: 300 }}>
                <ResponsivePie
                  data={dataPostes}
                  margin={{ top: 30, bottom: 55, right: 160 }}
                  startAngle={0}
                  endAngle={180}
                  innerRadius={0.5}
                  padAngle={1.8}
                  cornerRadius={0}
                  activeOuterRadiusOffset={8}
                  borderWidth={1.5}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 1]],
                  }}
                  colors={{ datum: "data.color" }}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#000000"
                  arcLinkLabelsColor={{ from: "color" }}
                  arcLabelsSkipAngle={10}
                  arcLinkLabelComponent={(d) => {
                    return (
                      <animated.g opacity={d.style.opacity}>
                        <animated.path
                          fill="none"
                          stroke={d.style.linkColor}
                          strokeWidth={d.style.thickness}
                          d={d.style.path}
                        />
                        {d.label.split(" ").map((segment, i) => (
                          <animated.text
                            y={i * 14}
                            key={`arcLinkLabel_segment_${segment}`}
                            transform={d.style.textPosition}
                            textAnchor={d.style.textAnchor}
                            dominantBaseline="central"
                            style={{
                              fill: d.style.textColor,
                            }}
                          >
                            {segment}
                          </animated.text>
                        ))}
                      </animated.g>
                    );
                  }}
                  arcLinkLabelsThickness={2}
                  arcLabelsTextColor="#ffffff"
                />
              </div>
            </Col>
            <Col sm={8} xs={12}>
              <div className="text-center px-3 pt-2 text-secondary">
                <PiChartBarHorizontalFill size={15} className="me-2" />
                Quantité par opérateur sur la période
                <PiArrowBendRightDown size={15} className="mt-2" />
              </div>
              <div
                style={{ height: 300 }}
                className="overflow-y-auto overflow-x-hidden custom-scrollbar"
              >
                <div style={{ height: containerHeight }}>
                  <ResponsiveBar
                    data={dataOperateurs}
                    indexBy="operateur"
                    keys={["production"]}
                    margin={{ right: 10, left: 180 }}
                    padding={0.3}
                    layout="horizontal"
                    valueScale={{ type: "linear" }}
                    colors={({ id, data }) => String(data[`${id}Color`])}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    axisBottom={null}
                    axisTop={null}
                    axisRight={null}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor="#000000"
                    barThickness={10}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {dataPostesLineChart &&
        showLineChart &&
        dataPostesLineChart.length > 0 && (
          <Row>
            <Col
              xs={12}
              className="text-center text-secondary"
              style={{ height: 290 }}
            >
              <div className="text-center px-3 pt-2 text-secondary">
                <PiChartLineLight size={17} className="me-2" />
                Quantité par poste et par jour sur la période
                <PiArrowBendRightDown size={15} className="mt-2" />
              </div>
              <ResponsiveLine
                data={dataPostesLineChart}
                margin={{ top: 10, right: 80, bottom: 80, left: 60 }}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: 0,
                  max: "auto",
                  stacked: false,
                  reverse: false,
                }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 22,
                  truncateTickAt: 0,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  truncateTickAt: 5,
                }}
                colors={{
                  datum: "color",
                }}
                enableSlices="x"
                enableTouchCrosshair
                pointColor={{ theme: "background" }}
                enableArea={true}
                areaOpacity={0}
                useMesh={true}
              />
            </Col>
          </Row>
        )}
      {footer && <HalfChartCard.Footer>{footer}</HalfChartCard.Footer>}
    </Card>
  );
};

HalfChartCard.Header = ({ children, icon }) => {
  return (
    <>
      <Stack direction="horizontal" gap={3} className="mb-2 me-3">
        {icon && <div>{icon}</div>}
        <div className="text-left">{children}</div>
      </Stack>
      <div className="separation"></div>
    </>
  );
};

HalfChartCard.Title = ({ children }) => {
  return <h6 className="Montserrat-SemiBold mb-0">{children}</h6>;
};

HalfChartCard.Subtitle = ({ children }) => {
  return <div className="Montserrat-Regular">{children}</div>;
};

HalfChartCard.Footer = ({ children }) => {
  return <div>{children}</div>;
};

export default HalfChartCard;
