import { motion } from "framer-motion";
import React from "react";
import { Stack } from "react-bootstrap";
import "./DetailsPanel.css";

const DetailsPanel = ({ children, className }) => {
  return <div className={"details-panel " + className}>{children}</div>;
};

DetailsPanel.Main = ({ children, className }) => {
  return (
    <div className={(className && className) + " main flex-grow-1"}>
      {children}
    </div>
  );
};

DetailsPanel.Main.Title = ({ title, subTitle, linksMenu, activePage }) => {
  return (
    <Stack direction="horizontal" className="ms-4 me-4 mt-3 title" gap={3}>
      <div>
        <div className={"Montserrat-SemiBold h5 text-uppercase mb-0"}>
          {title}
        </div>
        <div className="Montserrat-Regular h6">{subTitle}</div>
      </div>
      <div className="ms-auto h6 menu">
        <Stack direction="horizontal" gap={2} className={"m-4 "}>
          {linksMenu.map((link, index) => (
            <span key={index}>
              <button
                className={
                  "button text-capitalize Montserrat-Regular " +
                  (link.nom === activePage && "active")
                }
                onClick={link.onClick}
              >
                {link.text}
              </button>
              {index + 1 < linksMenu.length && <span className="mx-2">|</span>}
            </span>
          ))}
        </Stack>
      </div>
    </Stack>
  );
};

DetailsPanel.Main.Content = ({ children, className }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={"h-100"}
    >
      <div className={"content ps-4 " + (className && className)}>
        {children}
      </div>
    </motion.div>
  );
};

DetailsPanel.Details = ({ children, className }) => {
  return (
    <div className={"details h-100 w-25 p-4 " + (className && className)}>
      {children}
    </div>
  );
};

DetailsPanel.Details.Title = ({ title, subTitle, iconTitle }) => {
  return (
    <>
      <Stack direction="horizontal" gap={2} className="title mb-3">
        <div>{iconTitle}</div>
        <div>
          <div className="Montserrat-SemiBold h5 text-uppercase mb-0 mt-2">
            {title}
          </div>
          <div className="Montserrat-Regular h6">{subTitle}</div>
        </div>
      </Stack>
      <div className="separation"></div>
    </>
  );
};

DetailsPanel.Details.Content = ({ children, className }) => {
  return (
    <div
      className={"content Montserrat-Regular mt-4 " + (className && className)}
    >
      {children}
    </div>
  );
};

DetailsPanel.Infos = ({ children, className, title, text }) => {
  return <div className={"infos " + (className && className)}>{children}</div>;
};

DetailsPanel.Infos.Title = ({ children }) => {
  return <h5 className="Montserrat-SemiBold mb-4 ">{children}</h5>;
};

DetailsPanel.Infos.Row = ({ className, libelle, value }) => {
  return (
    <Stack
      direction="horizontal"
      gap={2}
      className={"mb-3 " + (className && className)}
    >
      <h6>{libelle}</h6>
      <h6 className="ms-auto fw-bold">{value}</h6>
    </Stack>
  );
};

export default DetailsPanel;
