import React, { useEffect, useRef, useState } from "react";
import { Stack } from "react-bootstrap";
import DatatableHeaderDropdown from "../../CustomDropdown/DatatableHeaderDropdown/DatatableHeaderDropdown";
import SortArrow from "../SortArrow/SortArrow";
import "./HeadColumn.css";

const HeadColumn = ({
  sort,
  sortShow,
  title,
  onClickTitle,
  firstRender,
  onFirstRender,
  filterData,
  onFilter,
}) => {
  const [hover, setHover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [marginLeftArrow, setMarginLeftArrow] = useState(0);
  const containerRef = useRef();
  const arrowDivWidth = 17;

  useEffect(() => {
    if (containerRef.current && arrowDivWidth) {
      setMarginLeftArrow(
        containerRef.current.offsetWidth / 2 - arrowDivWidth / 2
      );
    }
  }, []);

  const handleHover = (bool) => {
    setHover(bool);
  };

  const handleToggle = (isOpen) => {
    setIsOpen(isOpen);
  };

  const handleFilter = (isfiltered, filterData) => {
    setIsFiltered(isfiltered);
    onFilter(filterData);
  };

  return (
    <div
      className={`h-100 head-column ${isOpen && "active"}`}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      ref={containerRef}
    >
      <Stack
        className="h-100"
        direction="horizontal"
        style={{ alignItems: "stretch" }}
      >
        <div className="w-100 ps-2 pe-2">
          <div
            style={{
              height: 8,
              left: marginLeftArrow,
              position: "absolute",
            }}
            onClick={() => onClickTitle()}
          >
            {sortShow && <SortArrow sort={sort} />}
          </div>
          <div
            className="w-100 text-truncate pt-2"
            onClick={() => onClickTitle()}
          >
            {title}
          </div>
        </div>
        <div>
          {filterData.enabled && (
            <div
              className={
                "h-100 px-1 d-flex align-items-center " +
                (hover || isOpen || isFiltered ? "opacity-100" : "opacity-0")
              }
              style={{
                borderLeft: "1px solid #dee2e6",
              }}
            >
              <DatatableHeaderDropdown
                firstRender={firstRender}
                onFirstRender={onFirstRender}
                onToggle={handleToggle}
                onFilter={handleFilter}
                menuType={filterData.type}
                menuData={filterData.values}
              />
            </div>
          )}
        </div>
      </Stack>
    </div>
  );
};

export default HeadColumn;
