import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import NavbarDropdown from "../CustomDropdown/NavbarDropdown/NavbarDropdown";
import NavLinkItem from "../NavLinkItem/NavLinkItem";
import "./SecondNavBar.css";

const SecondNavBar = React.forwardRef((props, ref) => {
  const containerRef = useRef(null);
  const location = useLocation();
  const currentMainPath = location.pathname.split("/")[1];

  useEffect(() => {
    if (props.setRef) {
      props.setRef(containerRef);
    }
  }, [props.setRef, containerRef]);

  return (
    <motion.div
      key={currentMainPath}
      initial={{ opacity: 0, y: -25 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -25 }}
    >
      <Navbar expand="sm" className="secondary-navbar" data-bs-theme="light">
        <Navbar.Collapse id="basic-navbar-nav" className="Roboto-Regular m-0">
          <Nav className="me-auto m-0 ms-2 mt-1 mb-1">
            {props.links &&
              props.links.map((link, linkIndex) => {
                return (
                  <React.Fragment key={linkIndex}>
                    {link.type === "link" ? (
                      <NavLinkItem
                        className={
                          "h-100" +
                          (!location.pathname.split("/")[2] &&
                            linkIndex === 0 &&
                            " active")
                        }
                        to={link.to}
                        icon={link.icon}
                        text={link.text}
                        disabled={link.disabled}
                      />
                    ) : (
                      link.type === "dropdown" && (
                        <NavbarDropdown
                          className={"text-secondary"}
                          links={link.links}
                          libelle={link.text}
                          icon={link.icon}
                          disabled={link.disabled}
                        />
                      )
                    )}
                  </React.Fragment>
                );
              })}
          </Nav>
          <Nav className="ms-auto me-2 mt-1">
            <div
              className="d-flex  align-items-center"
              ref={containerRef}
            ></div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </motion.div>
  );
});

export default SecondNavBar;
