import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { createPortal } from "react-dom";
import { FaSearch } from "react-icons/fa";
import { FaCirclePlus, FaClock, FaLayerGroup, FaTrash } from "react-icons/fa6";
import { PiWarningBold } from "react-icons/pi";
import { FooterContext } from "../../App";
import { useBodyHeight } from "../../customHooks/useBodyHeight";
import { useContainer } from "../../customHooks/useContainer";
import useWindowFocus from "../../customHooks/useWindowFocus";
import AtelierDefault from "./atelierStates/AtelierDefault/AtelierDefault";
import AtelierError from "./atelierStates/AtelierError/AtelierError";
import AtelierForcerAjout from "./atelierStates/AtelierForcerAjout/AtelierForcerAjout";
import AtelierLocaliser from "./atelierStates/AtelierLocaliser/AtelierLocaliser";
import AtelierMenu from "./atelierStates/AtelierMenu/AtelierMenu";
import BigButtonMenu from "./atelierStates/AtelierMenu/BigButtonMenu/BigButtonMenu";
import AtelierPostesPrecedents from "./atelierStates/AtelierPostesPrecedents/AtelierPostesPrecedents";
import AtelierRebus from "./atelierStates/AtelierRebut/AtelierRebut";
import AtelierStockageGroupe from "./atelierStates/AtelierStockageGroupe/AtelierStockageGroupe";
import AtelierSuccess from "./atelierStates/AtelierSuccess/AtelierSuccess";
import AtelierWait from "./atelierStates/AtelierWait/AtelierWait";

const Atelier = () => {
  const footerRefs = useContext(FooterContext);
  const [currentMode, setCurrentMode] = useState("default");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const bodyHeight = useBodyHeight(32);
  const containerAction = useContainer(footerRefs && footerRefs.start);
  const isFocused = useWindowFocus();
  const [isShowMenu, setIsShowMenu] = useState(false);
  let timeOutErrorId = useRef(null);
  let timeOutSuccessId = useRef(null);

  const modes = {
    default: {
      label: "Nouvelle opération",
    },
    menu: {
      label: "Menu",
      component: <AtelierMenu />,
    },
    error: {
      label: "Erreur",
      component: <AtelierError />,
    },
    success: {
      label: "Succès",
      component: <AtelierSuccess />,
    },
    localiser: {
      label: "Localiser un produit",
      component: <AtelierLocaliser />,
    },
    stockage_groupe: {
      label: "Stockage groupé",
      component: <AtelierStockageGroupe />,
    },
    attente: {
      label: "Mettre en attente",
      component: <AtelierWait />,
    },
    forcer: {
      label: "Forcer l'ajout d'un produit",
      component: <AtelierForcerAjout />,
    },
    rebus: {
      label: "Mettre au rebut un produit",
      component: <AtelierRebus />,
    },
    retour: {
      label: "Retour postes précédents",
    },
  };

  const linksMenuAtelier = [
    {
      icon: <FaSearch size={60 + "%"} />,
      text: "Localiser",
      onClick: () => setCurrentMode("localiser"),
      bg: "primary",
    },
    {
      icon: <FaLayerGroup size={60 + "%"} />,
      text: "Stockage groupé",
      onClick: () => setCurrentMode("stockage_groupe"),
      bg: "primary",
    },
    {
      icon: <FaClock size={60 + "%"} />,
      text: "Mettre en attente un produit",
      onClick: () => setCurrentMode("attente"),
      bg: "primary",
    },
    {
      icon: <FaCirclePlus size={60 + "%"} />,
      text: "Forcer l'ajout",
      onClick: () => setCurrentMode("forcer"),
      bg: "primary",
    },
    {
      icon: <FaTrash size={60 + "%"} />,
      text: "Mettre au rebut",
      onClick: () => setCurrentMode("rebus"),
      bg: "warning",
    },
  ];

  const handleReset = () => {
    setCurrentMode("default");
  };

  const handleError = (error) => {
    setError(error);
    setCurrentMode("error");
    timeOutErrorId.current = setTimeout(() => {
      clearError();
    }, 8000);
  };

  const clearError = () => {
    clearTimeout(timeOutErrorId);
    setError(false);
    handleReset();
    handleHideMenu();
  };

  const clearSuccess = () => {
    clearTimeout(timeOutSuccessId);
    setSuccess(false);
    handleReset();
    handleHideMenu();
  };

  const handleSuccess = (success) => {
    setSuccess(success);
    setCurrentMode("success");
    timeOutSuccessId.current = setTimeout(() => {
      clearSuccess();
    }, 5000);
  };

  const handleShowMenu = () => {
    setIsShowMenu(!isShowMenu);
    !isShowMenu ? setCurrentMode("menu") : handleReset();
  };

  const handleHideMenu = () => {
    setIsShowMenu(false);
    handleReset();
  };

  useEffect(() => {
    handleReset();
  }, []);

  return (
    <>
      {containerAction &&
        createPortal(
          <>Action en cours: {modes[currentMode].label}</>,
          containerAction
        )}
      {!isFocused && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999,
            backgroundColor: "rgba(0,0,0,0.7)",
            textAlign: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "white",
            }}
          >
            <h1>
              <PiWarningBold size={80} /> <br /> Les actions menées avec le
              scanner ne seront pas prises en compte si la fenêtre n'est pas au
              premier plan.
            </h1>
          </div>
        </div>
      )}
      <Row className="w-100 m-0 overflow-hidden">
        <Col xs={12} className="mt-3" style={{ height: bodyHeight }}>
          <AnimatePresence mode="wait">
            <motion.div
              key={currentMode}
              initial={{ x: 50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ y: -50, opacity: 0 }}
              transition={{ duration: 0.25 }}
              className="h-100"
              style={{ whiteSpace: "pre-line" }}
            >
              {error ? (
                <AtelierError
                  errorIcon={error.icon}
                  errorMessage={error.message}
                  clearError={clearError}
                />
              ) : success ? (
                <AtelierSuccess
                  successIcon={success.icon}
                  successMessage={success.message}
                  clearSuccess={clearSuccess}
                />
              ) : currentMode === "default" ? (
                <AtelierDefault
                  handleError={handleError}
                  handleSuccess={handleSuccess}
                />
              ) : currentMode === "forcer" ? (
                <AtelierForcerAjout
                  handleError={handleError}
                  handleSuccess={handleSuccess}
                />
              ) : currentMode === "attente" ? (
                <AtelierWait
                  handleError={handleError}
                  handleSuccess={handleSuccess}
                />
              ) : currentMode === "menu" ? (
                <AtelierMenu
                  links={linksMenuAtelier}
                  handleError={handleError}
                  handleSuccess={handleSuccess}
                />
              ) : currentMode === "localiser" ? (
                <AtelierLocaliser
                  handleError={handleError}
                  handleSuccess={handleSuccess}
                />
              ) : currentMode === "rebus" ? (
                <AtelierRebus
                  handleError={handleError}
                  handleSuccess={handleSuccess}
                />
              ) : currentMode === "retour" ? (
                <AtelierPostesPrecedents
                  handleError={handleError}
                  handleSuccess={handleSuccess}
                />
              ) : currentMode === "stockage_groupe" ? (
                <AtelierStockageGroupe handleSuccess={handleSuccess} />
              ) : null}
            </motion.div>
          </AnimatePresence>
          <BigButtonMenu onClick={handleShowMenu} isShowMenu={isShowMenu} />
        </Col>
      </Row>
    </>
  );
};

export default Atelier;
