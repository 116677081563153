import React from "react";
import { Stack } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import { FaPallet } from "react-icons/fa";
import {
  PiCheckFatFill,
  PiClockFill,
  PiHammerFill,
  PiTrashFill,
} from "react-icons/pi";
import "./ProductStatutBadge.css";

const libelleStatutBadge = ({ idStatut, libelleStatut, type, size }) => {
  const objectSize = {
    xs: {
      iconSize: 15,
      textSize: "",
    },
    sm: {
      iconSize: 20,
      textSize: "h4",
    },
    md: {
      iconSize: 30,
      textSize: "h4",
    },
    lg: {
      iconSize: 34,
      textSize: "h4",
    },
    xl: {
      iconSize: 36,
      textSize: "h4",
    },
  };

  size = size ? size : "xs";

  const getBadgeContent = () => {
    switch (idStatut) {
      case "1":
        return (
          <Badge
            bg="light-primary d-flex align-items-enter justify-content-center product-statut-badge"
            className="w-100"
            text="primary"
          >
            <Stack direction="horizontal">
              <PiHammerFill className="me-2" size={objectSize[size].iconSize} />{" "}
              <span
                className={
                  "d-none d-lg-inline-block mt-1 mb-1 " +
                  objectSize[size].textSize
                }
              >
                {libelleStatut}
              </span>
            </Stack>
          </Badge>
        );
      case "2":
      case "3":
      case "4":
        return (
          <Badge
            bg="light-warning d-flex align-items-enter justify-content-center product-statut-badge"
            className="w-100"
            text="warning"
          >
            <Stack direction="horizontal">
              <PiClockFill className="me-2" size={objectSize[size].iconSize} />{" "}
              <span
                className={
                  "d-none d-lg-inline-block mt-1 mb-1 " +
                  objectSize[size].textSize
                }
              >
                {libelleStatut}
              </span>
            </Stack>
          </Badge>
        );
      case "5":
        return (
          <Badge
            bg="light-secondary d-flex align-items-enter justify-content-center product-statut-badge"
            className="w-100"
            text="secondary"
          >
            <Stack direction="horizontal">
              <PiTrashFill className="me-2" size={objectSize[size].iconSize} />{" "}
              <span
                className={
                  "d-none d-lg-inline-block mt-1 mb-1 " +
                  objectSize[size].textSize
                }
              >
                {libelleStatut}
              </span>
            </Stack>
          </Badge>
        );
      case "6":
        return (
          <Badge
            bg="light-success d-flex align-items-enter justify-content-center product-statut-badge"
            className="w-100"
            text="success"
          >
            <Stack direction="horizontal">
              <PiCheckFatFill
                className="me-2"
                size={objectSize[size].iconSize}
              />{" "}
              <span
                className={
                  "d-none d-lg-inline-block mt-1 mb-1 " +
                  objectSize[size].textSize
                }
              >
                {libelleStatut}
              </span>
            </Stack>
          </Badge>
        );
      case "7":
        return (
          <Badge
            bg="light-primary d-flex align-items-enter justify-content-center product-statut-badge"
            className="w-100"
            text="primary"
          >
            <Stack direction="horizontal">
              <FaPallet className="me-2" size={objectSize[size].iconSize} />{" "}
              <span
                className={
                  "d-none d-lg-inline-block mt-1 mb-1 " +
                  objectSize[size].textSize
                }
              >
                {libelleStatut}
              </span>
            </Stack>
          </Badge>
        );
      default:
        return <>{libelleStatut}</>;
    }
  };

  const getOutput = () => {
    switch (type) {
      case "badge":
        return getBadgeContent();
      case "bg":
        return (
          "text-" +
          getBadgeContent().props.text +
          " bg-" +
          getBadgeContent().props.bg
        );
      case "icon":
        return getBadgeContent().props.children[0];
      default:
        return getBadgeContent();
    }
  };

  return getOutput();
};

export default libelleStatutBadge;
