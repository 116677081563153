import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { PiFilePdfThin, PiPlusCircleThin, PiQrCodeThin } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { FooterContext } from "../../../App";
import AlignBox from "../../../components/AlignBox/AlignBox";
import StockageTypeIcon from "../../../components/CustomIcons/StockageTypeIcon/StockageTypeIcon";
import NavLinkItem from "../../../components/NavLinkItem/NavLinkItem";
import QRCodesPDF from "../../../components/PDF/QRCodesPDF/QRCodesPDF";
import ResizableTable from "../../../components/ResizableTable/ResizableTable";
import ToolNavBar from "../../../components/ToolNavBar/ToolNavBar";
import config from "../../../config/config";
import { FreeSecondNavBarContext } from "../Production";
import ModalNouveauStockage from "./ModalNouveauStockage/ModalNouveauStockage";

function Stockage() {
  const [showNouveauStockage, setShowNouveauStockage] = useState(false);
  const searchBoxRefContext = useContext(FreeSecondNavBarContext);
  const [freeToolNavBarRef, setFreeToolNavBarRef] = useState(null);
  const footerRefs = useContext(FooterContext);
  const apiUrl = config.apiUrl;
  const resizableTableRef = useRef();
  const navigate = useNavigate();

  const handleFreeToolNavBarRef = (freeToolNavBarRef) => {
    setFreeToolNavBarRef(freeToolNavBarRef.current);
  };

  const handleShowNouveauStockageModal = () => setShowNouveauStockage(true);
  const handleHideNouveauStockageModal = () => {
    resizableTableRef.current.refreshTable();
    setShowNouveauStockage(false);
  };

  const selectionMenuLinks = [
    {
      text: "",
      icon: <PiFilePdfThin size={23} />,
      type: "dropdown",
      links: [
        {
          icon: <PiQrCodeThin size={23} />,
          text: "Etiquette 105x42mm",
          component: QRCodesPDF,
          componentProps: () => ({
            title: "Etiquettes 105x42mm",
            qrcodeKey: "qrcode",
            getTitle: (item) => item.nom,
            downloadButton: (props) => (
              <NavLinkItem
                text="Etiquettes 105x42mm avec QR Codes"
                className={"w-100"}
                icon={<PiQrCodeThin size={23} />}
                {...props}
              />
            ),
          }),
        },
      ],
    },
  ];

  const tableConfig = [
    {
      nom: "Nom",
      render: (row) => (
        <AlignBox>
          <StockageTypeIcon
            idStockageType={row.type.id}
            size={2}
            className="me-2"
            reverse
          />
          {row.nom}
        </AlignBox>
      ),
      onClick: (id) => navigate("/production/stockage_fiche/" + id),
    },
    {
      nom: "ID",
      render: (row) => row.id,
      width: 120,
      center: true,
    },
    {
      nom: "Type",
      render: (row) => row.type.libelle,
      width: 120,
    },
    {
      nom: "Capacité",
      render: (row) => <>{row.capacite} places</>,
      width: 120,
    },
    {
      nom: "Création",
      render: (row) => row.creation,
      width: 240,
      center: true,
    },
  ];

  const toolBarLinks = [
    {
      text: "Nouveau Stockage",
      icon: <PiPlusCircleThin size={20} />,
      onClick: handleShowNouveauStockageModal,
      type: "link",
    },
    {
      type: "vr",
    },
  ];

  const cardConfig = {
    header: (row) => row.nom,
    headerClass: () => "bg-perform text-black",
    icon: (row) => (
      <StockageTypeIcon
        idStockageType={row.type.id}
        size={3.4}
        className="mt-2"
        reverse
      />
    ),
    title: (row) => <>{row.type.libelle}</>,
    text: (row) => (
      <>
        id: {row.id} <br />
        creation: {row.creation} <br />
        capacite: {row.capacite} places
      </>
    ),
  };

  return (
    <>
      <ModalNouveauStockage
        show={showNouveauStockage}
        handleClose={handleHideNouveauStockageModal}
      ></ModalNouveauStockage>
      <ToolNavBar links={toolBarLinks} setRef={handleFreeToolNavBarRef} />
      <Container fluid className="ps-0">
        <Row>
          <Col>
            <Card className="border-0">
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0, y: 25 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <ResizableTable
                    ref={resizableTableRef}
                    urlFetch={apiUrl + "/stockage-datatable"}
                    limit={50}
                    indexOrder="stockage_nom"
                    viewModeInit="table"
                    indexSort={0}
                    sortInit="ASC"
                    heightAdjust={82}
                    searchInputRef={searchBoxRefContext}
                    paginationRef={footerRefs && footerRefs.middle}
                    filterResultRef={footerRefs && footerRefs.start}
                    totalRef={footerRefs && footerRefs.end}
                    TableConfig={tableConfig}
                    switchViewRef={searchBoxRefContext}
                    selectionMenuRef={freeToolNavBarRef && freeToolNavBarRef}
                    selectionMenuLinks={selectionMenuLinks}
                    tableConfig={tableConfig}
                    cardConfig={cardConfig}
                    checkable
                  />
                </motion.div>
              </AnimatePresence>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Stockage;
