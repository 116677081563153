import React from "react";
import { Nav } from "react-bootstrap";
import NavbarDropdown from "../../CustomDropdown/NavbarDropdown/NavbarDropdown";
import NavLinkItem from "../../NavLinkItem/NavLinkItem";
import "./SelectionMenu.css";

const SelectionMenu = ({ links, checkedItems, disabled }) => {
  return (
    <Nav className="selection-menu me-auto m-0 ms-2">
      {links &&
        links.map((link, linkIndex) => {
          if (link.component) {
            const Component = link.component;
            const ComponentProps = link.componentProps
              ? link.componentProps()
              : {};

            return (
              <React.Fragment key={linkIndex}>
                <Component
                  data={checkedItems}
                  disabled={disabled}
                  {...ComponentProps}
                />
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={linkIndex}>
                {link.type === "link" || !link.type ? (
                  <NavLinkItem
                    to={link.to}
                    icon={link.icon}
                    text={link.text}
                    disabled={disabled}
                    data={checkedItems}
                    className="me-1"
                    onClick={link.onClick}
                  />
                ) : link.type === "vr" ? (
                  <div className="vr mx-1"></div>
                ) : (
                  link.type === "dropdown" && (
                    <NavbarDropdown
                      className={"h-100 " + (link.noArrow && "no-arrow")}
                      links={link.links}
                      libelle={link.text}
                      icon={link.icon}
                      disabled={disabled}
                      data={checkedItems}
                    />
                  )
                )}
              </React.Fragment>
            );
          }
        })}
    </Nav>
  );
};

export default SelectionMenu;
