import React from "react";
import { toast } from "react-toastify";

const AtelierErrorToast = ({ message }) => {
  toast.error(
    <div
      className="text-uppercase my-4"
      style={{
        fontSize: "4rem",
        paddingLeft: "4rem",
      }}
    >
      {message}
    </div>,
    {
      style: { width: "96vw", height: "auto" },
      position: "bottom-left",
      autoClose: 5000,
      icon: (
        <div
          className="custom-toast-icon"
          style={{
            fontSize: "4rem",
            color: "red",
          }}
        >
          ⚠️
        </div>
      ),
    }
  );
};

export default AtelierErrorToast;
