import { useEffect, useRef, useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { createPortal } from "react-dom";
import { PiCalendarThin } from "react-icons/pi";

import { BsSun, BsSunrise, BsSunset } from "react-icons/bs";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import HalfChartCard from "../../../components/CustomChart/HalfChartCard/HalfChartCard";
import DashBoardDatePicker from "../../../components/CustomDatePicker/DashBoardDatePicker/DashBoardDatePicker";
import NavbarDropdown from "../../../components/CustomDropdown/NavbarDropdown/NavbarDropdown";
import PosteTypeIcon from "../../../components/CustomIcons/PosteTypeIcon/PosteTypeIcon";
import ToolNavBar from "../../../components/ToolNavBar/ToolNavBar";
import config from "../../../config/config";
import useFetch from "../../../customHooks/useFetch";
import "./TableauDeBordProduction.css";

const TableauDeBordProduction = () => {
  const apiUrl = config.apiUrl;
  const [dateRange, setDateRange] = useState({
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
  });
  const [poste, setPoste] = useState("journee");
  const navigate = useNavigate();
  const urlCountMouvementApi = apiUrl + "/production-dashboard";
  const urlPosteTypeApi = apiUrl + "/poste-types";
  const isComponentMounted = useRef(true);
  const [freeToolNavBarRef, setFreeToolNavBarRef] = useState(null);
  const { data: dashboardProductionData, triggerFetch } = useFetch(
    urlCountMouvementApi,
    "POST",
    isComponentMounted,
    [],
    {},
    false
  );
  const { data: posteTypeData } = useFetch(
    urlPosteTypeApi,
    "GET",
    isComponentMounted,
    []
  );

  const handleFreeToolNavBarRef = (freeToolNavBarRef) => {
    setFreeToolNavBarRef(freeToolNavBarRef.current);
  };

  const dateRangeeNavBarLinks = [
    {
      component: DashBoardDatePicker,
      componentProps: () => ({
        onChange: setDateRange,
      }),
    },
  ];

  const postes = [
    {
      name: "matin",
      icon: <BsSunrise size={20} />,
      text: "Matin",
      onClick: () => setPoste("matin"),
    },
    {
      name: "apres-midi",
      icon: <BsSunset size={20} />,
      text: "Après-midi",
      onClick: () => setPoste("apres-midi"),
    },
    {
      name: "journee",
      icon: <BsSun size={20} />,
      text: "Journée complète",
      onClick: () => setPoste("journee"),
    },
  ];

  const toolBarLinks = [{}];

  const getdateRangeeLibelle = () => (
    <>
      <PiCalendarThin size={23} className="me-1" />{" "}
      <Moment format="DD-MM-yyyy">{dateRange.startDate}</Moment>
      {" > "}
      <Moment format="DD-MM-yyyy">{dateRange.endDate}</Moment>
    </>
  );

  const findPoste = (posteName) => {
    return postes.find((poste) => poste.name === posteName);
  };

  const getPosteLibelle = (posteName) => {
    return findPoste(posteName).text;
  };

  const getPosteIcon = (posteName) => {
    return findPoste(posteName).icon;
  };

  const getStartTime = (posteName) => {
    return findPoste(posteName).startTime;
  };

  const getEndTime = (posteName) => {
    return findPoste(posteName).endTime;
  };

  useEffect(() => {
    if (!dateRange.endDate && dateRange.startDate) {
      setDateRange({
        ...dateRange,
        endDate: dateRange.startDate,
      });
    }

    if (dateRange.startDate && dateRange.endDate && poste) {
      const data = {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        poste: poste,
      };

      triggerFetch(urlCountMouvementApi, {
        method: "POST",
        body: JSON.stringify(data),
      });
    }
  }, [dateRange, poste]);

  const posteFabricationData = dashboardProductionData.postes;
  const posteFabricationDataForLineChart =
    dashboardProductionData["postes-lineChart"];
  const operateurFabricationData = dashboardProductionData.operateurs;
  const posteFabricationCount = dashboardProductionData.fabrication;
  const posteRebutCount = dashboardProductionData.rebut;
  const posteAttenteCount = dashboardProductionData.attente;

  return (
    <>
      <ToolNavBar links={toolBarLinks} setRef={handleFreeToolNavBarRef} />
      <div className="tableau-de-bord-production h-100">
        <div className="main">
          {freeToolNavBarRef &&
            createPortal(
              <Stack direction="horizontal" gap={0}>
                <NavbarDropdown
                  autoClose="outside"
                  links={dateRangeeNavBarLinks}
                  libelle={getdateRangeeLibelle(poste)}
                />
                <NavbarDropdown
                  autoClose="outside"
                  links={postes}
                  libelle={getPosteLibelle(poste)}
                  icon={getPosteIcon(poste)}
                />
              </Stack>,
              freeToolNavBarRef
            )}
          <Row className="Montserrat-SemiBold p-0 m-2">
            {posteFabricationData &&
              posteTypeData.map((posteType, index) => {
                return (
                  posteFabricationData[posteType.libelle] && (
                    <Col xxl={6} xl={6} md={12} sm={12} xs={12} key={index}>
                      <HalfChartCard
                        dataPostes={
                          posteFabricationData &&
                          posteFabricationData[posteType.libelle]
                        }
                        dataPostesLineChart={
                          posteFabricationDataForLineChart &&
                          posteFabricationDataForLineChart[posteType.libelle]
                        }
                        dataOperateurs={
                          operateurFabricationData &&
                          operateurFabricationData[posteType.libelle]
                        }
                        posteFabricationCount={
                          posteFabricationCount[posteType.libelle]
                        }
                        posteAttenteCount={posteAttenteCount[posteType.libelle]}
                        posteRebutCount={posteRebutCount[posteType.libelle]}
                        showLineChart={
                          dateRange.startDate !== dateRange.endDate
                        }
                        title={"Postes " + posteType.libelle}
                        subtitle="Opérations réalisées"
                        onClickRebutButton={() =>
                          navigate(
                            "/production/produits?filters=" +
                              JSON.stringify({
                                statut_libelle: { rebut: true },
                                mouvement_creation: {
                                  startDate: dateRange.startDate,
                                  endDate: dateRange.endDate,
                                },
                              })
                          )
                        }
                        icon={
                          <PosteTypeIcon
                            idPosteType={posteType.id}
                            size={3.5}
                          />
                        }
                      />
                    </Col>
                  )
                );
              })}
          </Row>
        </div>
      </div>
    </>
  );
};

export default TableauDeBordProduction;
