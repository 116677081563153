import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useRef, useState } from "react";
import { Card, Col, Container, NavLink, Row } from "react-bootstrap";
import { PiFilePdfThin, PiPlusCircleThin, PiQrCode } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { FooterContext } from "../../../App";
import OperateurGroupeIcon from "../../../components/CustomIcons/OperateurGroupeIcon/OperateurGroupeIcon";
import QRCodesPDF from "../../../components/PDF/QRCodesPDF/QRCodesPDF";
import ResizableTable from "../../../components/ResizableTable/ResizableTable";
import ToolNavBar from "../../../components/ToolNavBar/ToolNavBar";
import ActivationBadge from "../../../components/badgeComponents/ActivationBadge/ActivationBadge";
import config from "../../../config/config";
import { FreeSecondNavBarContext } from "../Production";
import ModalNouvelOperateur from "./ModalNouvelOperateur/ModalNouvelOperateur";

function Operateur() {
  const [showNouvelOperateur, setShowNouvelOperateur] = useState(false);
  const searchBoxRefContext = useContext(FreeSecondNavBarContext);
  const footerRefs = useContext(FooterContext);
  const [freeToolNavBarRef, setFreeToolNavBarRef] = useState(null);
  const apiUrl = config.apiUrl;
  const resizableTableRef = useRef();
  const navigate = useNavigate();

  const handleFreeToolNavBarRef = (freeToolNavBarRef) => {
    setFreeToolNavBarRef(freeToolNavBarRef.current);
  };

  const handleShowNouvelOperateurModal = () => setShowNouvelOperateur(true);

  const handleHideNouvelOperateurModal = () => {
    resizableTableRef.current.refreshTable();
    setShowNouvelOperateur(false);
  };

  const selectionMenuLinks = [
    {
      text: "",
      icon: <PiFilePdfThin size={23} />,
      type: "dropdown",
      links: [
        {
          component: QRCodesPDF,
          componentProps: () => ({
            title: "Etiquettes 105x42mm",
            qrcodeKey: "qrcode",
            getTitle: (item) => `${item.nom} ${item.prenom}`,
            downloadButton: (props) => (
              <NavLink to={props.to} onClick={props.onClick} {...props}>
                <span className="me-3">
                  <PiQrCode size={20} />
                </span>
                Etiquettes 105x42mm avec QR Codes
              </NavLink>
            ),
          }),
        },
      ],
    },
  ];

  const tableConfig = [
    {
      nom: "Groupe",
      key: "groupe.libelle",
      render: (row) => (
        <>
          <OperateurGroupeIcon
            idOperateurGroupe={row.groupe.id}
            size={3}
            className="me-2"
          />
          {row.groupe.libelle}
        </>
      ),
      width: 240,
    },
    {
      nom: "Prénom Nom",
      key: ["prenom", "nom"],
      render: (row) => (
        <>
          {row.prenom} {row.nom}
        </>
      ),
      onClick: (id) => navigate("/production/operateur_fiche/" + id),
    },
    {
      nom: "Création",
      key: "creation",
      render: (row) => row.creation,
      width: 240,
      center: true,
    },
    {
      nom: "Statut",
      key: "statut.libelle",
      render: (row) => row.statut.libelle,
      width: 180,
      center: true,
    },
    {
      nom: "Activation",
      key: "activation",
      render: (row) => (
        <ActivationBadge activationValue={row.activation}></ActivationBadge>
      ),
      width: 90,
      center: true,
    },
  ];

  const toolBarLinks = [
    {
      text: "Nouvel opérateur",
      icon: <PiPlusCircleThin size={20} />,
      onClick: handleShowNouvelOperateurModal,
      type: "link",
    },
    {
      type: "vr",
    },
  ];

  const exportExcelConfig = {
    fileName: "etat_operateurs",
  };

  const cardConfig = {
    header: (row) => (
      <>
        {row.prenom} {row.nom}
      </>
    ),
    icon: (row) => (
      <OperateurGroupeIcon
        idOperateurGroupe={row.groupe.id}
        size={3}
        className="mt-2"
      />
    ),
    headerClass: () => "bg-textural text-white",
    title: (row) => <>{row.groupe.libelle}</>,
    text: (row) => (
      <>
        id: {row.id} <br />
        etat: {row.statut.libelle}
        <br />
        creation: {row.creation}
      </>
    ),
  };

  return (
    <>
      <ModalNouvelOperateur
        show={showNouvelOperateur}
        handleClose={handleHideNouvelOperateurModal}
      ></ModalNouvelOperateur>
      <ToolNavBar links={toolBarLinks} setRef={handleFreeToolNavBarRef} />
      <Container fluid className="ps-0">
        <Row>
          <Col>
            <Card className="border-0">
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0, y: 25 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <ResizableTable
                    ref={resizableTableRef}
                    urlFetch={apiUrl + "/operateur-datatable"}
                    limit={50}
                    indexOrder="operateur_creation"
                    viewModeInit="table"
                    indexSort={2}
                    sortInit="DESC"
                    heightAdjust={82}
                    searchInputRef={searchBoxRefContext}
                    paginationRef={footerRefs && footerRefs.middle}
                    filterResultRef={footerRefs && footerRefs.start}
                    totalRef={footerRefs && footerRefs.end}
                    TableConfig={tableConfig}
                    switchViewRef={searchBoxRefContext}
                    tableConfig={tableConfig}
                    cardConfig={cardConfig}
                    checkable
                    selectionMenuRef={freeToolNavBarRef && freeToolNavBarRef}
                    selectionMenuLinks={selectionMenuLinks}
                    exportExcel={exportExcelConfig}
                  />
                </motion.div>
              </AnimatePresence>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Operateur;
