import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  usePDF,
} from "@react-pdf/renderer";
import React, { useEffect, useMemo } from "react";
import FormatString from "../../../utilitaires/FormatString/FormatString";
import QRCodeBase64 from "../../QRCodeBase64/QRCodeBase64";

const QRCodesPDF = ({
  title,
  data,
  onRender,
  downloadButton,
  getTitle,
  qrcodeKey,
}) => {
  const PAGE_ITEMS = 7; // Nombre d'éléments par pages

  const styles = StyleSheet.create({
    page: {
      padding: "5mm",
    },
    container: {
      flexDirection: "column",
    },
    etiquette105x42: {
      flexDirection: "row",
      width: "85mm",
    },
    row: {
      padding: 0,
      margin: 0,
      flexDirection: "row",
    },
    rowVSpace: {
      height: "18mm",
    },
    etiquette105x42HSpace: {
      margin: 0,
      width: "20mm",
    },
    imageContainer: {},
    image: {
      height: "25mm",
      width: "25mm",
    },
    text: {
      flex: 1,
      fontSize: 14,
      marginTop: "1mm",
      marginLeft: "2mm",
      maxHeight: "25mm",
    },
    pageBreak: {
      breakAfter: "page",
    },
  });

  const handleRenderComplete = () => {
    if (onRender) {
      onRender();
    }
  };

  const dataPairs = [];
  for (let i = 0; i < Object.keys(data).length; i += 2) {
    const pair = {};
    pair.first = data[Object.keys(data)[i]];
    if (i + 1 < Object.keys(data).length) {
      pair.second = data[Object.keys(data)[i + 1]];
    }
    dataPairs.push(pair);
  }

  const document = useMemo(
    () => (
      <Document onRender={() => handleRenderComplete()}>
        <Page style={styles.page}>
          <View style={styles.container}>
            {dataPairs.map((pair, pairIndex) => {
              return (
                <React.Fragment key={pairIndex}>
                  {pairIndex % PAGE_ITEMS !== 0 && (
                    <View style={styles.rowVSpace}></View>
                  )}
                  <View style={styles.row}>
                    {pair.first && (
                      <React.Fragment>
                        <View style={styles.etiquette105x42}>
                          <View style={styles.imageContainer}>
                            <Image
                              src={QRCodeBase64(pair.first[qrcodeKey])}
                              style={styles.image}
                            ></Image>
                          </View>
                          <View style={styles.text}>
                            <Text>{getTitle(pair.first)}</Text>
                          </View>
                        </View>
                        <View style={styles.etiquette105x42HSpace}></View>
                      </React.Fragment>
                    )}
                    {pair.second && (
                      <React.Fragment>
                        <View style={styles.etiquette105x42}>
                          <View style={styles.imageContainer}>
                            <Image
                              src={QRCodeBase64(pair.second.qrcode)}
                              style={styles.image}
                            ></Image>
                          </View>
                          <View style={styles.text}>
                            <Text>{getTitle(pair.second)}</Text>
                          </View>
                        </View>
                      </React.Fragment>
                    )}
                  </View>
                  {(pairIndex + 1) % PAGE_ITEMS === 0 &&
                    pairIndex !== dataPairs.length - 1 && (
                      <View style={styles.pageBreak}></View>
                    )}
                </React.Fragment>
              );
            })}
          </View>
        </Page>
      </Document>
    ),
    [data]
  );

  const [instance, updateInstance] = usePDF({ document: document });

  useEffect(() => {
    updateInstance(document);
  }, [document, updateInstance]);

  useEffect(() => {
    if (instance && instance.progress === 100 && onRender) {
      onRender();
    }
  }, [instance, onRender]);

  const DownloadButton = downloadButton({
    href: instance?.url,
    download: FormatString(title) + ".pdf",
  });

  return <>{instance && DownloadButton}</>;
};

export default QRCodesPDF;
