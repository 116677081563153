import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import UserProfermIcon from "../../../components/CustomIcons/UserProfermIcon/UserProfermIcon";
import DetailsPanel from "../../../components/Panels/DetailsPanel/DetailsPanel";
import ActivationBadge from "../../../components/badgeComponents/ActivationBadge/ActivationBadge";
import config from "../../../config/config";
import useFetch from "../../../customHooks/useFetch";
import UtilisateurProfermEditer from "./UtilisateurProfermEditer/UtilisateurProfermEditer";

const UtilisateurProfermFiche = () => {
  const { id } = useParams();
  const apiUrl = config.apiUrl + "/utilisateur-interne/" + id;
  const [contentPage, setContentPage] = useState("editer");
  const isComponentMounted = useRef(true);
  const {
    data: dataUtilisateurProferm,
    triggerFetch: triggerFetchUtilisateurProferm,
  } = useFetch(apiUrl, isComponentMounted, []);
  const triggerFetchUtilisateurProfermRef = useRef(
    triggerFetchUtilisateurProferm
  );
  const linksContentMenu = [
    {
      text: "Activité",
      nom: "activite",
      onClick: () => {
        setContentPage("activite");
      },
    },
    {
      text: "Editer",
      nom: "editer",
      onClick: () => {
        setContentPage("editer");
      },
    },
  ];
  const refreshData = () => {
    triggerFetchUtilisateurProfermRef.current(apiUrl, { method: "GET" });
  };

  useEffect(() => {
    triggerFetchUtilisateurProfermRef.current(apiUrl, {
      method: "GET",
    });
  }, [apiUrl]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 25 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
      className="h-100"
    >
      {dataUtilisateurProferm && (
        <DetailsPanel>
          <DetailsPanel.Main>
            <DetailsPanel.Main.Title
              title={
                dataUtilisateurProferm.nom + " " + dataUtilisateurProferm.prenom
              }
              subTitle={dataUtilisateurProferm.groupe.libelle}
              linksMenu={linksContentMenu}
              activePage={contentPage}
            />
            <DetailsPanel.Main.Content key={contentPage}>
              {contentPage === "editer" ? (
                <UtilisateurProfermEditer
                  utilisateurData={dataUtilisateurProferm}
                  onSubmitted={refreshData}
                />
              ) : (
                <>bonjour</>
              )}
            </DetailsPanel.Main.Content>
          </DetailsPanel.Main>
          <DetailsPanel.Details>
            <DetailsPanel.Details.Title
              title={
                dataUtilisateurProferm.nom + " " + dataUtilisateurProferm.prenom
              }
              subTitle={dataUtilisateurProferm.groupe.libelle}
              iconTitle={
                <UserProfermIcon
                  size={4.5}
                  idGroupe={dataUtilisateurProferm.groupe.id}
                />
              }
            />
            <DetailsPanel.Details.Content>
              <DetailsPanel.Infos>
                <DetailsPanel.Infos.Title>Détails</DetailsPanel.Infos.Title>
                <DetailsPanel.Infos.Row
                  libelle="Activation"
                  value={
                    <ActivationBadge
                      activationValue={dataUtilisateurProferm.activation}
                      size={22}
                    />
                  }
                />
                <DetailsPanel.Infos.Row
                  libelle="Nom"
                  value={dataUtilisateurProferm.nom}
                />
                <DetailsPanel.Infos.Row
                  libelle="Prénom"
                  value={dataUtilisateurProferm.prenom}
                />
                <DetailsPanel.Infos.Row
                  libelle="Login"
                  value={dataUtilisateurProferm.login}
                />
                <DetailsPanel.Infos.Row
                  libelle="Groupe"
                  value={
                    <div className="d-flex align-items-center">
                      {dataUtilisateurProferm.groupe.libelle}
                      <UserProfermIcon
                        idGroupe={dataUtilisateurProferm.groupe.id}
                        reverse
                        size={2}
                      ></UserProfermIcon>
                    </div>
                  }
                />
                <DetailsPanel.Infos.Row
                  libelle="Dernière connexion"
                  value={dataUtilisateurProferm.lastConnexion}
                />
                <DetailsPanel.Infos.Row
                  libelle="Date de création"
                  value={dataUtilisateurProferm.creation}
                />
              </DetailsPanel.Infos>
            </DetailsPanel.Details.Content>
          </DetailsPanel.Details>
        </DetailsPanel>
      )}
    </motion.div>
  );
};

export default UtilisateurProfermFiche;
