import { useEffect, useState } from "react";

export function useContainer(ref) {
  const [container, setContainer] = useState(null);

  useEffect(() => {
    const newContainer = document.createElement("div");
    newContainer.style.display = "flex";
    setContainer(newContainer);

    if (ref) {
      ref.appendChild(newContainer);
    }
  }, [ref]);

  return container;
}
