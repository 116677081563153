import { yupResolver } from "@hookform/resolvers/yup";
import React, { useRef } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { PiPlusCircleThin } from "react-icons/pi";
import { toast } from "react-toastify";
import * as yup from "yup";
import AlignBox from "../../../../components/AlignBox/AlignBox";
import OperateurGroupeIcon from "../../../../components/CustomIcons/OperateurGroupeIcon/OperateurGroupeIcon";
import SimpleModal from "../../../../components/CustomModal/SimpleModal/SimpleModal";
import BasicInput from "../../../../components/inputs/BasicInput/BasicInput";
import FetchSelect from "../../../../components/inputs/FetchSelect/FetchSelect";
import config from "../../../../config/config";
import useFetch from "../../../../customHooks/useFetch";

const schema = yup
  .object({
    groupe: yup.number().required("Type obligatoire").positive().integer(),

    nom: yup
      .string()
      .required("Nom de l'opérateur obligatoire")
      .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/, "Entrez un nom valide"),

    prenom: yup
      .string()
      .required("Prénom de l'opérateur obligatoire")
      .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/, "Entrez un prénom valide"),
  })
  .required();

const ModalNouvelOperateur = ({ show, handleClose }) => {
  const apiUrl = config.apiUrl;
  const isComponentMounted = useRef(true);
  const { loading, triggerFetch } = useFetch(apiUrl, isComponentMounted, []);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSuccess = () => {
    toast.success("Opérateur ajouté avec succès");
    handleClose();
  };

  const onSubmit = (data) => {
    console.log("Données soumises:", data);
    triggerFetch(
      apiUrl + "/operateur-create",
      {
        method: "POST",
        body: JSON.stringify(data),
      },
      onSuccess
    );
  };

  return (
    <SimpleModal
      show={show}
      handleClose={handleClose}
      iconTitle={<PiPlusCircleThin />}
      title={"Ajout d'un opérateur"}
      validationButtonText={"Enregistrer l'opérateur"}
      onSubmit={handleSubmit(onSubmit)}
      onExited={() => reset()}
      loading={loading}
    >
      <Form noValidate>
        <FetchSelect
          urlOptions={apiUrl + "/operateur-groupes"}
          labelText="Groupe"
          onSelectChange={(selectedValue) => setValue("groupe", selectedValue)}
          placeholder="Selectionnez le groupe de l'opérateur"
          control={control}
          name="groupe"
          option={{
            value: "id",
            text: (row) => (
              <>
                <AlignBox>
                  <OperateurGroupeIcon
                    idOperateurGroupe={row.id}
                    size={3}
                    className="me-3"
                  />
                  {row.libelle}
                </AlignBox>
              </>
            ),
          }}
          setValue={setValue}
          errorMessage={errors.type?.message}
          registerFunction={register("groupe")}
        />
        <BasicInput
          labelText="Nom de l'opérateur"
          controlId="nom"
          control={control}
          name="nom"
          placeholder="Entrez le nom de l'opérateur"
          autoFocus
          errorMessage={errors.nom?.message}
          registerFunction={register("nom")}
        />
        <BasicInput
          labelText="Prénom de l'opérateur"
          controlId="prenom"
          control={control}
          name="prenom"
          placeholder="Entrez le prénom de l'opérateur"
          errorMessage={errors.prenom?.message}
          registerFunction={register("prenom")}
        />
      </Form>
    </SimpleModal>
  );
};

export default ModalNouvelOperateur;
