import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FooterContext } from "../../../App";
import IconBarre from "../../../components/CustomIcons/IconBarre/IconBarre";
import PosteTypeIcon from "../../../components/CustomIcons/PosteTypeIcon/PosteTypeIcon";
import StockageTypeIcon from "../../../components/CustomIcons/StockageTypeIcon/StockageTypeIcon";
import ResizableTable from "../../../components/ResizableTable/ResizableTable";
import ToolNavBar from "../../../components/ToolNavBar/ToolNavBar";
import ProductStatutBadge from "../../../components/badgeComponents/ProductStatutBadge/ProductStatutBadge";
import config from "../../../config/config";
import { FreeSecondNavBarContext } from "../Production";

function Postes() {
  const searchBoxRefContext = useContext(FreeSecondNavBarContext);
  const [freeToolNavBarRef, setFreeToolNavBarRef] = useState(null);
  const footerRefs = useContext(FooterContext);
  const apiUrl = config.apiUrl;
  const resizableTableRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const filtersString = queryParams.get("filters");
  const initFilters = filtersString ? JSON.parse(filtersString) : {};

  const handleFreeToolNavBarRef = (freeToolNavBarRef) => {
    setFreeToolNavBarRef(freeToolNavBarRef.current);
  };

  const tableConfig = [
    {
      nom: "Statut",
      key: "statut.libelle",
      render: (row) => (
        <ProductStatutBadge
          idStatut={row.statut.id}
          libelleStatut={row.statut.libelle}
          type="badge"
          iconSize={15}
        />
      ),
      width: 200,
    },
    {
      nom: "Commande",
      key: "codeBarre.cmd",
      render: (row) => parseInt(row.codeBarre.cmd),
      onClick: (id) => navigate("/production/produit_fiche/" + id),
    },
    {
      nom: "Extension",
      key: "codeBarre.extension",
      render: (row) => row.codeBarre.extension,
      width: 90,
      center: true,
    },
    {
      nom: "Position",
      key: "codeBarre.position",
      render: (row) => parseInt(row.codeBarre.position),
      width: 80,
      center: true,
    },
    {
      nom: "Repère quantité",
      key: "codeBarre.repere_quantite",
      render: (row) => parseInt(row.codeBarre.repere_quantite),
      width: 150,
      center: true,
    },
    {
      nom: "Elément",
      key: "codeBarre.element",
      render: (row) =>
        parseInt(row.codeBarre.element) === 0 ? (
          <>Dormant</>
        ) : (
          <>Ouvrant {parseInt(row.codeBarre.element)}</>
        ),
      width: 80,
    },
    {
      nom: "ID",
      key: "id",
      render: (row) => row.id,
      width: 50,
      center: true,
    },
    {
      nom: "Dernier scan le",
      key: "last_mouvement.creation",
      render: (row) => row.last_mouvement.creation,
      width: 180,
      center: true,
    },
    {
      nom: "Dernier emplacement",
      key: "mouvement_stockage_or_poste.nom",
      render: (row) =>
        row.last_mouvement.emplacement.type.libelle === "Stockage" ? (
          <>
            <StockageTypeIcon
              idStockageType={row.mouvement_stockage_or_poste.type.id}
              size={2.7}
              className="me-2"
            />
            {row.mouvement_stockage_or_poste.nom}
          </>
        ) : (
          <>
            <PosteTypeIcon
              idPosteType={row.mouvement_stockage_or_poste.type.id}
              size={2.7}
              className="me-2"
            />
            {row.mouvement_stockage_or_poste.nom}
          </>
        ),
      width: 250,
    },
    {
      nom: "Indice de fabrication",
      key: "indice",
      render: (row) => row.indice,
      width: 150,
      center: true,
    },
    {
      nom: "Barre",
      key: "codeBarre.barre",
      render: (row) => <IconBarre barre={row.codeBarre.barre} size={20} />,
      width: 60,
      center: true,
    },
    {
      nom: "Enregistré le",
      key: "creation",
      render: (row) => row.creation,
      width: 180,
      center: true,
    },
    {
      nom: "Enregistré par",
      key: ["operateur.prenom", "operateur.nom"],
      render: (row) => <>{row.operateur.prenom + " " + row.operateur.nom}</>,
      width: 200,
      center: true,
    },
  ];

  const cardConfig = {
    header: (row) => row.codeBarre.cmd,
    headerClass: () => "bg-aluminium",
    text: (row) => (
      <>
        id: {row.id} <br />
        creation: {row.creation}
      </>
    ),
  };

  const exportExcelConfig = {
    fileName: "etat_production",
  };

  return (
    <>
      <ToolNavBar setRef={handleFreeToolNavBarRef} />
      <Container fluid className="ps-0">
        <Row>
          <Col>
            <Card className="border-0">
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0, y: 25 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <ResizableTable
                    ref={resizableTableRef}
                    urlFetch={apiUrl + "/product-datatable"}
                    limit={50}
                    heightAdjust={82}
                    indexOrder="mouvement_creation"
                    viewModeInit="table"
                    indexSort={7}
                    initFilters={initFilters}
                    sortInit="DESC"
                    searchInputRef={searchBoxRefContext}
                    paginationRef={footerRefs && footerRefs.middle}
                    filterResultRef={footerRefs && footerRefs.start}
                    totalRef={footerRefs && footerRefs.end}
                    TableConfig={tableConfig}
                    switchViewRef={searchBoxRefContext}
                    tableConfig={tableConfig}
                    cardConfig={cardConfig}
                    checkable
                    selectionMenuRef={freeToolNavBarRef && freeToolNavBarRef}
                    autoRefresh={60}
                    exportExcel={exportExcelConfig}
                  />
                </motion.div>
              </AnimatePresence>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Postes;
