import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import CustomCode128 from "../../../components/CustomCode128/CustomCode128";
import DetailsPanel from "../../../components/Panels/DetailsPanel/DetailsPanel";
import config from "../../../config/config";
import useFetch from "../../../customHooks/useFetch";
import TimelineProduit from "./TimelineProduit/TimelineProduit";

const ProduitFiche = () => {
  const { id } = useParams();
  const apiUrl = config.apiUrl + "/product/" + id;
  const [contentPage, setContentPage] = useState("activite");
  const isComponentMounted = useRef(true);
  const { data: dataProduit, triggerFetch: triggerFetchProduit } = useFetch(
    apiUrl,
    isComponentMounted,
    []
  );
  const codeBarre =
    dataProduit &&
    dataProduit.codeBarre.cmd +
      dataProduit.codeBarre.serie +
      dataProduit.codeBarre.position +
      dataProduit.codeBarre.repere_quantite +
      dataProduit.codeBarre.element +
      dataProduit.codeBarre.barre +
      dataProduit.codeBarre.extension;

  const triggerFetchProduitRef = useRef(triggerFetchProduit);
  const linksContentMenu = [
    {
      text: "Activité",
      nom: "activite",
      onClick: () => {
        setContentPage("activite");
      },
    },
  ];

  useEffect(() => {
    triggerFetchProduitRef.current(apiUrl, {
      method: "GET",
    });
  }, [apiUrl]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 25 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
      className="h-100"
    >
      {dataProduit && (
        <DetailsPanel>
          <DetailsPanel.Main>
            <DetailsPanel.Main.Title
              title={
                parseInt(dataProduit.codeBarre.cmd) +
                "." +
                dataProduit.codeBarre.extension
              }
              subTitle={
                <>
                  Pos. {parseInt(dataProduit.codeBarre.position)}, Quantite{" "}
                  {parseInt(dataProduit.codeBarre.repere_quantite)},{" "}
                  {parseInt(dataProduit.codeBarre.element) === 0
                    ? "Dormant"
                    : "Ouvrant " + parseInt(dataProduit.codeBarre.element)}
                </>
              }
              linksMenu={linksContentMenu}
              activePage={contentPage}
            />
            <DetailsPanel.Main.Content key={contentPage}>
              {contentPage === "activite" ? (
                <TimelineProduit idProduit={id} />
              ) : null}
            </DetailsPanel.Main.Content>
          </DetailsPanel.Main>
          <DetailsPanel.Details>
            <DetailsPanel.Details.Title
              title={
                parseInt(dataProduit.codeBarre.cmd) +
                "." +
                dataProduit.codeBarre.extension
              }
              subTitle={
                <>
                  Pos. {parseInt(dataProduit.codeBarre.position)}, Quantite{" "}
                  {parseInt(dataProduit.codeBarre.repere_quantite)},{" "}
                  {parseInt(dataProduit.codeBarre.element) === 0
                    ? "Dormant"
                    : "Ouvrant " + parseInt(dataProduit.codeBarre.element)}
                  <CustomCode128 value={codeBarre} height={30} />
                </>
              }
              linksMenu={linksContentMenu}
              activePage={contentPage}
            />
            <DetailsPanel.Details.Content>
              <DetailsPanel.Infos>
                <DetailsPanel.Infos.Title>Détails</DetailsPanel.Infos.Title>
                <DetailsPanel.Infos.Row
                  libelle="Numéro de commande"
                  value={parseInt(dataProduit.codeBarre.cmd)}
                />
                <DetailsPanel.Infos.Row
                  libelle="Extension"
                  value={dataProduit.codeBarre.extension}
                />
                <DetailsPanel.Infos.Row
                  libelle="Position"
                  value={parseInt(dataProduit.codeBarre.position)}
                />
                <DetailsPanel.Infos.Row
                  libelle="Repère quantité"
                  value={parseInt(dataProduit.codeBarre.repere_quantite)}
                />
                <DetailsPanel.Infos.Row
                  libelle="Elément"
                  value={
                    parseInt(dataProduit.codeBarre.element) === 0
                      ? "Dormant"
                      : "Ouvrant " + parseInt(dataProduit.codeBarre.element)
                  }
                />
                <DetailsPanel.Infos.Row
                  libelle="Série"
                  value={dataProduit.codeBarre.serie}
                />
                <DetailsPanel.Infos.Row
                  libelle="Indice de fabrication"
                  value={dataProduit.indice}
                />
                <DetailsPanel.Infos.Row
                  libelle="Date de création"
                  value={dataProduit.creation}
                />
              </DetailsPanel.Infos>
            </DetailsPanel.Details.Content>
          </DetailsPanel.Details>
        </DetailsPanel>
      )}
    </motion.div>
  );
};

export default ProduitFiche;
