import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { PiPlusCircleThin } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { FooterContext } from "../../../App";
import UserProfermIcon from "../../../components/CustomIcons/UserProfermIcon/UserProfermIcon";
import ResizableTable from "../../../components/ResizableTable/ResizableTable";
import ToolNavBar from "../../../components/ToolNavBar/ToolNavBar";
import ActivationBadge from "../../../components/badgeComponents/ActivationBadge/ActivationBadge";
import config from "../../../config/config";
import FormatDateDDMMYYYYHHMM from "../../../utilitaires/FormatDate/FormatDateDDMMYYYYHHMM";
import { AdministrationFreeRefContext } from "../Administration";
import ModalNouvelUtilisateurProferm from "./ModalNouvelUtilisateurProferm/ModelNouvelUtilisateurProferm";

function UtilisateurProferm() {
  const [showNouvelUtilisateurProferm, setShowNouvelUtilisateurProferm] =
    useState(false);
  const searchBoxRefContext = useContext(AdministrationFreeRefContext);
  const [freeToolNavBarRef, setFreeToolNavBarRef] = useState(null);
  const footerRefs = useContext(FooterContext);
  const apiUrl = config.apiUrl;
  const resizableTableRef = useRef();
  const navigate = useNavigate();

  const handleFreeToolNavBarRef = (freeToolNavBarRef) => {
    setFreeToolNavBarRef(freeToolNavBarRef.current);
  };

  const handleShowNouvelUtilisateurProfermModal = () =>
    setShowNouvelUtilisateurProferm(true);
  const handleHideNouvelUtilisateurProfermModal = () => {
    resizableTableRef.current.refreshTable();
    setShowNouvelUtilisateurProferm(false);
  };

  const tableConfig = [
    {
      nom: "Login",
      key: "login",
      render: (row) => row.login,
      onClick: (id) =>
        navigate("/administration/utilisateur_proferm_fiche/" + id),
    },
    {
      nom: "ID",
      key: "id",
      render: (row) => row.id,
      onClick: (id) =>
        navigate("/administration/utilisateur_proferm_fiche/" + id),
      width: 90,
      center: true,
    },
    {
      nom: "Nom",
      key: "nom",
      render: (row) => <span className="text-uppercase">{row.nom}</span>,
      onClick: (id) =>
        navigate("/administration/utilisateur_proferm_fiche/" + id),
      width: 180,
    },
    {
      nom: "Prénom",
      key: "prenom",
      render: (row) => <span className="text-capitalize">{row.prenom}</span>,
      onClick: (id) =>
        navigate("/administration/utilisateur_proferm_fiche/" + id),
      width: 180,
    },
    {
      nom: "Groupe",
      key: "groupe.id",
      render: (row) => (
        <>
          <UserProfermIcon size={2.5} idGroupe={row.groupe.id} />{" "}
          {row.groupe.libelle}
        </>
      ),
      width: 180,
    },
    {
      nom: "Dernière connexion",
      key: "lastConnexion",
      render: (row) => row.lastConnexion,
      width: 160,
      center: true,
    },
    {
      nom: "IP dernière connexion",
      key: "lastIp",
      render: (row) => row.lastIp,
      width: 180,
    },
    {
      nom: "Création",
      key: "creation",
      render: (row) => row.creation,
      width: 160,
      center: true,
    },
    {
      nom: "IP création",
      key: "creationIp",
      render: (row) => row.creationIp,
      width: 80,
    },
    {
      nom: "Activation",
      key: "activation",
      render: (row) => <ActivationBadge activationValue={row.activation} />,
      width: 50,
      center: true,
    },
  ];

  const exportExcelConfig = {
    fileName: "etat_utilisateurs_proferm",
  };

  const cardConfig = {
    header: (row) => (
      <>
        {row.prenom} {row.nom}
      </>
    ),
    headerClass: () => "bg-aluminium text-white",
    title: (row) => row.groupe.libelle,
    icon: (row) => <UserProfermIcon size={3} idGroupe={row.groupe.id} />,
    text: (row) => (
      <>
        id: {row.id}
        <br />
        login: <strong>{row.login}</strong>
        <br />
        création:{" "}
        <FormatDateDDMMYYYYHHMM>{row.creation.date}</FormatDateDDMMYYYYHHMM>
        <br />
        {">"} IP: {row.creation.ip}
        <br />
        dernière connexion:{" "}
        <FormatDateDDMMYYYYHHMM>
          {row.last_connexion.date}
        </FormatDateDDMMYYYYHHMM>
        <br />
        {">"} IP: {row.last_connexion.ip}
      </>
    ),
  };

  const toolBarConfig = [
    {
      text: "Nouvel utilisateur",
      icon: <PiPlusCircleThin size={20} />,
      onClick: () => handleShowNouvelUtilisateurProfermModal(),
    },
    {
      type: "vr",
    },
  ];

  return (
    <>
      <ModalNouvelUtilisateurProferm
        show={showNouvelUtilisateurProferm}
        handleClose={handleHideNouvelUtilisateurProfermModal}
      />
      <ToolNavBar links={toolBarConfig} setRef={handleFreeToolNavBarRef} />
      <Container fluid className="ps-0">
        <Row>
          <Col>
            <Card className="border-0">
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0, y: 25 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <ResizableTable
                    ref={resizableTableRef}
                    urlFetch={apiUrl + "/utilisateur-interne-datatable"}
                    limit={50}
                    heightAdjust={82}
                    indexOrder="login"
                    viewModeInit="table"
                    indexSort={1}
                    sortInit="ASC"
                    searchInputRef={searchBoxRefContext}
                    switchViewRef={searchBoxRefContext}
                    paginationRef={footerRefs && footerRefs.middle}
                    filterResultRef={footerRefs && footerRefs.start}
                    totalRef={footerRefs && footerRefs.end}
                    tableConfig={tableConfig}
                    cardConfig={cardConfig}
                    selectionMenuRef={freeToolNavBarRef && freeToolNavBarRef}
                    exportExcel={exportExcelConfig}
                    checkable
                  />
                </motion.div>
              </AnimatePresence>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UtilisateurProferm;
