import { yupResolver } from "@hookform/resolvers/yup";
import React, { useRef } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { PiPlusCircleThin } from "react-icons/pi";
import * as yup from "yup";
import AlignBox from "../../../../components/AlignBox/AlignBox";
import StockageTypeIcon from "../../../../components/CustomIcons/StockageTypeIcon/StockageTypeIcon";
import SimpleModal from "../../../../components/CustomModal/SimpleModal/SimpleModal";
import BasicInput from "../../../../components/inputs/BasicInput/BasicInput";
import FetchSelect from "../../../../components/inputs/FetchSelect/FetchSelect";
import config from "../../../../config/config";
import useFetch from "../../../../customHooks/useFetch";

const schema = yup
  .object({
    type: yup.number().required("Type obligatoire").positive().integer(),

    nom: yup.string().required("Nom du stockage obligatoire"),

    capacite: yup
      .string()
      .required("La capacité est requise")
      .matches(/^\d+$/, "La capacité doit être un nombre supérieur ou égal à 1")
      .test(
        "is-positive",
        "La capacité doit être un nombre supérieur ou égal à 1",
        (value) => parseInt(value, 10) >= 1
      ),
  })
  .required();

const ModalNouveauStockage = ({ show, handleClose }) => {
  const apiUrl = config.apiUrl;
  const isComponentMounted = useRef(true);
  const { loading, triggerFetch } = useFetch(apiUrl, isComponentMounted, []);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSuccess = () => {
    handleClose();
  };

  const onSelectChange = (selectedObject) => {
    setValue("type", selectedObject.id);
    setValue("capacite", selectedObject.capacite);
  };

  const onChange = (value) => {
    console.log("value :>> ", value);
  };

  const onSubmit = (data) => {
    console.log("Données soumises:", data);
    triggerFetch(
      apiUrl + "/stockage-create",
      {
        method: "POST",
        body: JSON.stringify(data),
      },
      onSuccess
    );
  };

  return (
    <SimpleModal
      show={show}
      handleClose={handleClose}
      iconTitle={<PiPlusCircleThin />}
      title={"Création d'un nouveau stockage"}
      validationButtonText={"Enregistrer le stockage"}
      onSubmit={handleSubmit(onSubmit)}
      onExited={() => reset()}
      loading={loading}
    >
      <Form noValidate>
        <FetchSelect
          urlOptions={apiUrl + "/stockage-types"}
          labelText="Type de Stockage"
          onSelectChange={onSelectChange}
          placeholder="Selectionnez un type de poste"
          control={control}
          name="type"
          option={{
            value: "id",
            text: (row) => (
              <>
                <AlignBox>
                  <StockageTypeIcon
                    idStockageType={row.id}
                    size={2}
                    className="me-3"
                    reverse
                  />
                  {row.libelle}
                </AlignBox>
              </>
            ),
          }}
          setValue={setValue}
          errorMessage={errors.type?.message}
          registerFunction={register("type")}
        />
        <BasicInput
          labelText="Nom du stockage"
          controlId="nom"
          control={control}
          name="nom"
          placeholder="Sélectionnez un type au dessus"
          errorMessage={errors.nom?.message}
          registerFunction={register("nom")}
        />
        <BasicInput
          onChange={onChange}
          control={control}
          labelText="Capacité"
          controlId="capacite"
          name="capacite"
          placeholder="Entrez la capacité de stockage"
          errorMessage={errors.capacite?.message}
          registerFunction={register("capacite")}
        />
      </Form>
    </SimpleModal>
  );
};

export default ModalNouveauStockage;
