import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  usePDF,
} from "@react-pdf/renderer";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { PiPrinter } from "react-icons/pi";
import FormatString from "../../../utilitaires/FormatString/FormatString";
import QRCodeBase64 from "../../QRCodeBase64/QRCodeBase64";

const QRCodePDF = ({
  qrCodeData,
  title,
  onRender,
  variant,
  text,
  buttonSize,
}) => {
  const styles = StyleSheet.create({
    page: {
      padding: "5mm",
    },
    container: {
      flexDirection: "column",
    },
    etiquette105x42: {
      flexDirection: "row",
      width: "85mm",
    },
    row: {
      padding: 0,
      margin: 0,
      flexDirection: "row",
    },
    rowVSpace: {
      height: "18mm",
    },
    etiquette105x42HSpace: {
      margin: 0,
      width: "20mm",
    },
    imageContainer: {},
    image: {
      height: "25mm",
      width: "25mm",
    },
    text: {
      flex: 1,
      fontSize: 14,
      marginTop: "1mm",
      marginLeft: "2mm",
      maxHeight: "25mm",
    },
    pageBreak: {
      breakAfter: "page",
    },
  });

  const handleRenderComplete = () => {
    if (onRender) {
      onRender();
    }
  };

  const document = (
    <Document onRender={() => handleRenderComplete()}>
      <Page style={styles.page}>
        <View style={styles.container}>
          <View style={styles.row}>
            <View style={styles.etiquette105x42}>
              <View style={styles.imageContainer}>
                <Image
                  src={QRCodeBase64(qrCodeData)}
                  style={styles.image}
                ></Image>
              </View>
              <View style={styles.text}>
                <Text>{title}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  const [instance, updateInstance] = usePDF({ document: document });

  useEffect(() => {
    if (instance && instance.progress === 100 && onRender) {
      onRender();
    }
  }, [instance, onRender]);

  return (
    <Button
      variant={variant}
      className="text-white Roboto-Light"
      href={instance.url}
      as="a"
      download={FormatString(title) + ".pdf"}
      size={buttonSize}
    >
      <PiPrinter size={18} className={text && "me-2"} />
      {text}
    </Button>
  );
};

export default QRCodePDF;
