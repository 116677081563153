import React from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import "./BasicInput.css";

const BasicInput = (props) => {
  const inputValue =
    props.value !== undefined ? props.value : props.defaultValue;

  return (
    <div className={props.hidden && "hidden"}>
      <Form.Group className="basic-input" controlId={props.controlId}>
        <Form.Label className={props.labelClassName}>
          {props.labelText}
        </Form.Label>
        <Controller
          name={props.name}
          control={props.control}
          defaultValue={inputValue}
          disabled={props.disabled && props.disabled}
          render={({ field }) => (
            <Form.Control
              ref={props.inputRef}
              onKeyDown={props.onKeyDown}
              className={props.className + (props.errorMessage ? " error" : "")}
              type={props.type}
              placeholder={props.placeholder}
              name={field.name}
              {...props.registerFunction}
              size={props.size}
              autoFocus={props.autoFocus}
              disabled={field.disabled}
              defaultValue={inputValue}
              spellCheck={props.spellCheck ? props.spellCheck : "false"}
              autoComplete={props.autoComplete}
              onChange={(e) => {
                field.onChange(e);
                if (props.onChange) {
                  props.onChange(e.target.value);
                }
              }}
              onBlur={field.onBlur}
            />
          )}
        />
      </Form.Group>
      <p className="text-danger mt-1 ms-1 mb-4 ">{props.errorMessage}</p>
    </div>
  );
};

export default BasicInput;
