import React, { useEffect, useRef, useState } from "react";
import "react-vertical-timeline-component/style.min.css";
import OperateurGroupeIcon from "../../../../components/CustomIcons/OperateurGroupeIcon/OperateurGroupeIcon";
import PosteTypeIcon from "../../../../components/CustomIcons/PosteTypeIcon/PosteTypeIcon";
import StockageTypeIcon from "../../../../components/CustomIcons/StockageTypeIcon/StockageTypeIcon";
import CustomTimeline from "../../../../components/CustomTimeline/CustomTimeline";
import config from "../../../../config/config";
import useFetch from "../../../../customHooks/useFetch";

const TimelineProduit = ({ idProduit }) => {
  const apiUrlProductActivity =
    config.apiUrl + "/mouvements-product/" + idProduit;
  const isComponentMounted = useRef(true);
  const [localDataProductActivity, setLocalDataProductActivity] =
    useState(null);

  const {
    data: dataEmplacementActivity,
    triggerFetch: triggerFetchEmplacementActivity,
  } = useFetch(apiUrlProductActivity, isComponentMounted, []);

  useEffect(() => {
    if (idProduit) {
      triggerFetchEmplacementActivity(apiUrlProductActivity, {
        method: "GET",
      });
    }
  }, [idProduit, apiUrlProductActivity]);

  useEffect(() => {
    setLocalDataProductActivity(dataEmplacementActivity);

    return () => {
      setLocalDataProductActivity(null);
    };
  }, [dataEmplacementActivity]);

  return (
    localDataProductActivity && (
      <CustomTimeline layout="1-column-left">
        {localDataProductActivity.map((event) => {
          return (
            <CustomTimeline.Element
              key={event.id}
              titleClassName={event.poste ? "text-hybride" : "text-perform"}
              contentArrowStyle={{
                borderRight: "none",
              }}
              date={<>Le {event.creation}</>}
              iconStyle={{
                background: event.poste ? "#52b876" : "#dacc2e",
                color: "#fff",
                width: "20px",
                height: "20px",
                marginLeft: "10px",
                marginTop: "30px",
              }}
              iconTitle={
                event.poste ? (
                  <PosteTypeIcon
                    idPosteType={event.poste.type.id}
                    size={3.5}
                    className="me-2"
                  />
                ) : (
                  <StockageTypeIcon
                    idStockageType={event.stockage.type.id}
                    size={3.5}
                    className="me-2"
                  />
                )
              }
              title={event.poste ? event.poste.nom : event.stockage.nom}
              subTitle={
                event.poste
                  ? event.poste.type.libelle
                  : event.stockage.type.libelle
              }
            >
              Opérateur:
              <br />
              <br />
              <OperateurGroupeIcon
                idOperateurGroupe={event.operateur.groupe.id}
                size={1.5}
              ></OperateurGroupeIcon>
              {event.operateur.nom + " " + event.operateur.prenom}
            </CustomTimeline.Element>
          );
        })}
      </CustomTimeline>
    )
  );
};

export default TimelineProduit;
