import { yupResolver } from "@hookform/resolvers/yup";
import React, { useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import SubmitFormButton from "../../../components/buttons/SubmitFormButton/SubmitFormButton";
import InputLoginFloatingLabel from "../../../components/inputs/InputLoginFloatingLabel/InputLoginFloatingLabel";
import config from "../../../config/config";
import useFetch from "../../../customHooks/useFetch";

const schema = yup
  .object({
    email: yup
      .string()
      .required("Veuillez entrer votre adresse e-mail.")
      .email("Veuillez entrer une adresse email valide."),
  })
  .required();

const Forgot = () => {
  const apiUrl = config.authentificationUrl;
  const isComponentMounted = useRef(true);
  const { loading, triggerFetch } = useFetch(apiUrl, isComponentMounted, []);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSuccess = (message) => {
    navigate("/auth/utilisateur/login");
    toast.success(message, {
      autoClose: 5000,
    });
  };

  const onSubmit = (data) => {
    triggerFetch(
      apiUrl + "/password-recovery",
      {
        method: "POST",
        body: JSON.stringify(data),
      },
      (response) => {
        onSuccess(response.message);
      }
    );
  };

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <h2 className="text-perform Montserrat-Bold mb-4">
        Mot de passe oublié ?
      </h2>
      <p className="Montserrat-Regular h6 pb-3">
        Veuillez entrer votre adresse e-mail de connexion.
      </p>
      <InputLoginFloatingLabel
        controlId="loginInput"
        label="Adresse e-mail"
        type="email"
        placeholder="entreprise@exemple.com"
        autoFocus
        name="email"
        errors={errors.email}
        registerFunction={register("email")}
      />
      <p className="text-danger mt-1 ms-1">{errors.login?.message}</p>
      <p className="text-danger mt-1 ms-1">{errors.email?.message}</p>

      <Link to="/auth/utilisateur/login">
        <Button
          type="button"
          variant="light"
          className="btn-light mt-3 px-4 Montserrat-Regular text-uppercase"
          size="lg"
        >
          Annuler
        </Button>
      </Link>

      <SubmitFormButton loading={loading} type="submit" className="mt-3 ms-3">
        ENVOYER
      </SubmitFormButton>
    </Form>
  );
};

export default Forgot;
