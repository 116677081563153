import React, { useEffect, useState } from "react";

const AutoRefresh = ({ autoRefresh, triggerFetch }) => {
  const [reloadTimer, setReloadTimer] = useState(autoRefresh);

  useEffect(() => {
    if (autoRefresh) {
      const reload = setInterval(() => {
        setReloadTimer((reloadTimer) => {
          if (reloadTimer > 0) {
            return reloadTimer - 1;
          } else {
            triggerFetch();
            return autoRefresh;
          }
        });
      }, 1000);

      return () => clearInterval(reload);
    }
  }, [triggerFetch, autoRefresh]);

  return (
    <>{autoRefresh && <>Actualisation automatique dans : {reloadTimer}s</>} </>
  );
};

export default AutoRefresh;
