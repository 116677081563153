import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { PiFilePdfThin, PiPlusCircleThin, PiQrCodeThin } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { FooterContext } from "../../../App";
import PosteTypeIcon from "../../../components/CustomIcons/PosteTypeIcon/PosteTypeIcon";
import NavLinkItem from "../../../components/NavLinkItem/NavLinkItem";
import QRCodesPDF from "../../../components/PDF/QRCodesPDF/QRCodesPDF";
import ResizableTable from "../../../components/ResizableTable/ResizableTable";
import ToolNavBar from "../../../components/ToolNavBar/ToolNavBar";
import config from "../../../config/config";
import { FreeSecondNavBarContext } from "../Production";
import ModalNouveauPoste from "./ModalNouveauPoste/ModalNouveauPoste";

function Postes() {
  const [showNouveauPoste, setShowNouveauPoste] = useState(false);
  const navigate = useNavigate();
  const searchBoxRefContext = useContext(FreeSecondNavBarContext);

  const [freeToolNavBarRef, setFreeToolNavBarRef] = useState(null);
  const footerRefs = useContext(FooterContext);
  const apiUrl = config.apiUrl;
  const resizableTableRef = useRef();

  const handleShowNouveauPosteModal = () => setShowNouveauPoste(true);
  const handleHideNouveauPosteModal = () => {
    resizableTableRef.current.refreshTable();
    setShowNouveauPoste(false);
  };

  const handleFreeToolNavBarRef = (freeToolNavBarRef) => {
    setFreeToolNavBarRef(freeToolNavBarRef.current);
  };

  const exportExcelConfig = {
    fileName: "etat_postes",
  };

  const selectionMenuLinks = [
    {
      text: "",
      icon: <PiFilePdfThin size={23} />,
      type: "dropdown",
      links: [
        {
          icon: <PiQrCodeThin size={23} />,
          text: "Etiquette 105x42mm",
          component: QRCodesPDF,
          componentProps: () => ({
            title: "Etiquettes 105x42mm",
            qrcodeKey: "qrcode",
            getTitle: (item) => item.nom,
            downloadButton: (props) => (
              <NavLinkItem
                text="Etiquettes 105x42mm avec QR Codes"
                className={"w-100"}
                icon={<PiQrCodeThin size={23} />}
                {...props}
              />
            ),
          }),
        },
      ],
    },
  ];

  const tableConfig = [
    {
      nom: "Type",
      key: "type.libelle",
      render: (row) => (
        <>
          <PosteTypeIcon idPosteType={row.type.id} size={2} className="me-2" />
          {row.type.libelle}
        </>
      ),
      width: 240,
    },
    {
      nom: "Nom",
      key: "nom",
      render: (row) => row.nom,
      onClick: (id) => navigate("/production/poste_fiche/" + id),
    },
    {
      nom: "ID",
      key: "id",
      render: (row) => row.id,
      width: 120,
      center: true,
    },
    {
      nom: "Création",
      key: "creation",
      render: (row) => row.creation,
      width: 280,
      center: true,
    },
  ];

  const toolBarLinks = [
    {
      text: "Nouveau poste",
      icon: <PiPlusCircleThin size={20} />,
      onClick: handleShowNouveauPosteModal,
    },
    {
      type: "vr",
    },
  ];

  const cardConfig = {
    header: (row) => row.nom,
    headerClass: () => "bg-hybride",
    icon: (row) => (
      <PosteTypeIcon idPosteType={row.type.id} size={4} className="pt-2" />
    ),
    title: (row) => <>{row.type.libelle}</>,
    text: (row) => (
      <>
        id: {row.id} <br />
        creation: {row.creation}
      </>
    ),
  };

  return (
    <>
      <ModalNouveauPoste
        show={showNouveauPoste}
        handleClose={handleHideNouveauPosteModal}
      ></ModalNouveauPoste>
      <ToolNavBar links={toolBarLinks} setRef={handleFreeToolNavBarRef} />
      <Container fluid className="ps-0">
        <Row>
          <Col>
            <Card className="border-0">
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0, y: 25 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <ResizableTable
                    ref={resizableTableRef}
                    urlFetch={apiUrl + "/poste-datatable"}
                    limit={50}
                    heightAdjust={82}
                    indexOrder="poste_id"
                    viewModeInit="table"
                    indexSort={2}
                    sortInit="DESC"
                    searchInputRef={searchBoxRefContext}
                    paginationRef={footerRefs && footerRefs.middle}
                    filterResultRef={footerRefs && footerRefs.start}
                    totalRef={footerRefs && footerRefs.end}
                    TableConfig={tableConfig}
                    switchViewRef={searchBoxRefContext}
                    tableConfig={tableConfig}
                    cardConfig={cardConfig}
                    checkable
                    selectionMenuRef={freeToolNavBarRef && freeToolNavBarRef}
                    selectionMenuLinks={selectionMenuLinks}
                    exportExcel={exportExcelConfig}
                  />
                </motion.div>
              </AnimatePresence>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Postes;
