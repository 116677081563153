import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  PiNumberSquareOneBold,
  PiNumberSquareThreeBold,
  PiNumberSquareTwoBold,
} from "react-icons/pi";
import * as yup from "yup";
import AtelierErrorToast from "../../../../components/CustomToast/AtelierErrorToast/AtelierErrorToast";
import BasicInput from "../../../../components/inputs/BasicInput/BasicInput";
import config from "../../../../config/config";
import useFetch from "../../../../customHooks/useFetch";
import ScanMultipleProducts from "../../scanComponents/ScanMultipleProducts";
import ScanOperateur from "../../scanComponents/ScanOperateur";
import ScanStockage from "../../scanComponents/ScanStockage";

const schema = yup.object({}).required();

const AtelierStockageGroupe = ({ handleSuccess }) => {
  const apiUrl = config.apiUrl;
  const apiUrlMouvementMultipleStockage =
    config.apiUrl + "/mouvement-multiple-stockage-create/";
  const inputRef = useRef(null);

  const regexProduct =
    /^[0-9]{8}[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}[1234HGBD]{1}[0-9A-Z]{3}$/;
  const [scannedStockage, setScannedStockage] = useState(null);
  const [scannedOperateur, setScannedOperateur] = useState(null);
  const [scannedProduct, setScannedProduct] = useState({});
  const [step, setStep] = useState(1);
  const urlStep = {
    1: "/scan-stockage/",
    2: "/scan-verify-product/",
    3: "/scan-operateur/",
  };

  const {
    register,
    formState: { isValid },
    handleSubmit,
    setFocus,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleError = (error) => {
    AtelierErrorToast(error);
  };

  const { data, triggerFetch: triggerFetchScan } = useFetch(
    apiUrl + urlStep[step],
    "GET",
    []
  );

  const { triggerFetch: triggerFetchMouvementMultipleStockage } = useFetch(
    apiUrlMouvementMultipleStockage,
    "POST",
    []
  );

  const handleKeyDown = (event) => {
    if (event && event.key === "Enter") {
      event.preventDefault();
      handleSubmit(handleFormSubmit)();
    }
  };

  const handleFormSubmit = (data) => {
    let finalUrl = "";
    if (data.code.match(regexProduct) && step === 3) {
      finalUrl = apiUrl + urlStep[2] + data.code;
    } else if (step === 1 || step === 2) {
      finalUrl = apiUrl + urlStep[step] + data.code;
    } else {
      finalUrl = apiUrl + urlStep[3] + data.code;
    }
    triggerFetchScan(
      finalUrl,
      {
        method: "GET",
      },
      false,
      handleError
    );
    inputRef.current.value = "";
  };

  useEffect(() => {
    switch (data?.type) {
      case "stockage":
        if (step === 1) {
          setScannedStockage(data);
          setStep(2);
        }
        break;
      case "cadre":
        if (step > 1) {
          const prevScannedProduct = scannedProduct || {};
          const newScannedProduct = { ...prevScannedProduct };

          // Vérifier si un objet avec le même code existe déjà
          const exists = Object.values(newScannedProduct).some(
            (item) => item === data.code
          );

          if (!exists) {
            // Déterminer la prochaine clé disponible
            const nextKey = Object.keys(newScannedProduct).length + 1;

            // Ajouter la nouvelle donnée scannée avec une clé numérotée
            newScannedProduct[nextKey] = data.code;

            setScannedProduct(newScannedProduct);
            setStep(3);
          } else {
            AtelierErrorToast({
              message: "Ce cadre fait déjà partie du groupe",
            });
          }
        }
        break;
      case "operateur":
        setScannedOperateur(data);
        break;
      default:
        break;
    }
  }, [data]);

  useEffect(() => {
    if (scannedStockage && scannedProduct && scannedOperateur) {
      const data = {
        operateur: scannedOperateur.code,
        cadre: scannedProduct,
        emplacement: scannedStockage.code,
      };

      triggerFetchMouvementMultipleStockage(
        apiUrlMouvementMultipleStockage,
        {
          method: "POST",
          body: JSON.stringify(data),
        },
        handleSuccess
      );
    }
  }, [scannedStockage, scannedProduct, scannedOperateur]);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        // Clic à l'extérieur du champ, rétablir le focus
        inputRef.current.focus();
      }
    };

    // Ajouter un écouteur d'événements pour gérer les clics à l'extérieur
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Nettoyer l'écouteur d'événements lors du démontage du composant
      document.removeEventListener("click", handleClickOutside);
    };
  }, [inputRef]);

  return (
    <>
      <Form noValidate>
        <BasicInput
          inputRef={inputRef}
          labelText="code"
          controlId="code"
          control={control}
          name="code"
          setFocus={setFocus}
          placeholder="code"
          registerFunction={register}
          onKeyDown={handleKeyDown}
          hidden
        />
      </Form>
      <Row className="h-100">
        <motion.div
          key={1}
          className="col-4 h-100"
          initial={{ x: 200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0 }}
        >
          <ScanStockage
            stockage={scannedStockage}
            className="bg-perform text-black"
            step={<PiNumberSquareOneBold size={20 + "%"} />}
            message="scannez le stockage..."
          />
        </motion.div>
        <motion.div
          key={2}
          className="col-4 h-100"
          initial={{ x: 200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.1 }}
        >
          <ScanMultipleProducts
            disabled={step < 2}
            step={<PiNumberSquareTwoBold size={20 + "%"} />}
            product={scannedProduct}
            className="bg-perform text-black"
            message="scannez le ou les cadres à stocker..."
          />
        </motion.div>
        <motion.div
          key={3}
          className="col-4 h-100"
          initial={{ x: 200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <ScanOperateur
            disabled={step < 3}
            operateur={scannedOperateur}
            className="bg-perform text-black"
            message="scannez votre badge pour finaliser l'opération..."
            step={<PiNumberSquareThreeBold size={20 + "%"} />}
          />
        </motion.div>
      </Row>
    </>
  );
};

export default AtelierStockageGroupe;
