import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { PiCheck, PiFunnel } from "react-icons/pi";
import HeaderDatatableDatePicker from "../../CustomDatePicker/HeaderDatatableDatePicker/HeaderDatatableDatePicker";
import "./DatatableHeaderDropdown.css";

const DatatableHeaderDropdown = ({
  firstRender,
  onFirstRender,
  menuData,
  menuType,
  onToggle,
  onFilter,
}) => {
  const selectFilter = "select";
  const dateFilter = "date";
  const [checkedItems, setCheckedItems] = useState({});
  const [dateFilterData, setDateFilterData] = useState({});

  const handleToggle = (isOpen) => {
    onToggle(isOpen);
  };

  const handleSelectChange = useCallback((item) => {
    setCheckedItems((prev) => {
      const newCheckedItems = { ...prev };
      if (newCheckedItems[item]) {
        delete newCheckedItems[item];
      } else {
        newCheckedItems[item] = true;
      }
      return newCheckedItems;
    });
  }, []);

  const handleDateChange = useCallback((filterData) => {
    setDateFilterData(filterData);
  }, []);

  useEffect(() => {
    if (!firstRender) {
      if (menuType === selectFilter) {
        if (Object.keys(checkedItems).length > 0) {
          onFilter(true, checkedItems);
        } else {
          onFilter(false, {});
        }
      } else if (menuType === dateFilter) {
        if (Object.keys(dateFilterData).length > 0) {
          onFilter(true, dateFilterData);
        } else {
          onFilter(false, {});
        }
      }
    }
    onFirstRender();
  }, [checkedItems, dateFilterData]);

  return (
    <Dropdown
      className="datatable-header-dropdown"
      autoClose="outside"
      onToggle={handleToggle}
    >
      <Dropdown.Toggle as="div" id="dropdown-basic" className="no-arrow">
        {Object.keys(checkedItems).length === 0 &&
        Object.keys(dateFilterData).length === 0 ? (
          <PiFunnel size={13} />
        ) : (
          <PiCheck size={13} />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {menuData && menuType === selectFilter ? (
          menuData.map((item, index) => {
            return (
              <Dropdown.Item
                as="div"
                key={index}
                onClick={() => handleSelectChange(item)}
              >
                <Form.Check
                  checked={checkedItems[item] ? true : false}
                  label={item}
                  type="switch"
                  onChange={() => {}}
                />
              </Dropdown.Item>
            );
          })
        ) : menuType === dateFilter ? (
          <HeaderDatatableDatePicker onChange={handleDateChange} />
        ) : null}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DatatableHeaderDropdown;
