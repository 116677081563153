import React, { useEffect, useState } from "react";
import PaginationButton from "./PaginationButton/PaginationButton";

const Pagination = ({ countRows, limit, changePage, currentPageTable }) => {
  const [currentPage, setCurrentPage] = useState(currentPageTable);
  const countPages = countRows ? Math.ceil(countRows / limit) : 1;
  const maxPagesToShow = 3;

  const handleFirst = () => {
    setCurrentPage(1);
    changePage(1);
  };

  const handleLast = () => {
    setCurrentPage(countPages);
    changePage(countPages);
  };

  const handleNext = () => {
    setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
    changePage(currentPage + 1);
  };

  const handlePrevious = () => {
    setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
    changePage(currentPage - 1);
  };

  const handlePage = (page) => {
    setCurrentPage(page);
    changePage(page);
  };

  useEffect(() => {
    setCurrentPage(currentPageTable);
  }, [currentPageTable]);

  const generatePaginationButtons = () => {
    const buttons = [];

    // Bouton "Précédent"
    buttons.push(
      <PaginationButton
        key={"prev"}
        disabled={currentPage <= 1}
        onClick={handlePrevious}
      >
        {"<"}
      </PaginationButton>
    );

    if (countPages <= maxPagesToShow + 3) {
      for (let i = 1; i <= countPages; i++) {
        buttons.push(
          <PaginationButton
            key={i}
            className={currentPage === i ? "active" : undefined}
            onClick={() => handlePage(i)}
          >
            {i}
          </PaginationButton>
        );
      }
    } else {
      // Bouton "First"
      buttons.push(
        <PaginationButton
          key={"first"}
          className={currentPage <= 1 ? "active" : undefined}
          onClick={handleFirst}
        >
          1
        </PaginationButton>
      );

      // Deuxième bouton
      if (currentPage - 1 <= 3) {
        buttons.push(
          <PaginationButton
            key={"second"}
            onClick={() => handlePage(2)}
            className={currentPage === 2 ? "active" : undefined}
          >
            2
          </PaginationButton>
        );
      } else {
        buttons.push(
          <PaginationButton key={"..."} disabled={true}>
            ...
          </PaginationButton>
        );
      }

      for (
        let i = Math.min(
          countPages - maxPagesToShow - 1,
          Math.max(currentPage - 1, 3)
        );
        i <=
        Math.max(
          maxPagesToShow + 2,
          Math.min(currentPage + maxPagesToShow - 2, countPages - 2)
        );
        i++
      ) {
        buttons.push(
          <PaginationButton
            key={i}
            className={currentPage === i ? "active" : undefined}
            onClick={() => handlePage(i)}
          >
            {i}
          </PaginationButton>
        );
      }

      // Bouton "beforeLast"
      if (currentPage + 2 >= countPages - 1) {
        buttons.push(
          <PaginationButton
            key={"beforeLast"}
            onClick={() => handlePage(countPages - 1)}
            className={currentPage === countPages - 1 ? "active" : undefined}
          >
            {countPages - 1}
          </PaginationButton>
        );
      } else {
        buttons.push(
          <PaginationButton key={"beforeLast"} disabled={true}>
            ...
          </PaginationButton>
        );
      }

      // Bouton "Last"
      buttons.push(
        <PaginationButton
          key={"last"}
          className={currentPage === countPages ? "active" : undefined}
          onClick={handleLast}
        >
          {countPages}
        </PaginationButton>
      );
    }

    // Bouton "Suivant"
    buttons.push(
      <PaginationButton
        key={"next"}
        disabled={currentPage >= countPages}
        onClick={handleNext}
      >
        {">"}
      </PaginationButton>
    );

    return buttons;
  };

  return <>{generatePaginationButtons()}</>;
};

export default Pagination;
