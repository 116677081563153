import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import AlignBox from "../../../../components/AlignBox/AlignBox";
import DashboardCard from "../../../../components/CustomCard/DashboardCard/DashboardCard";
import UserProfermIcon from "../../../../components/CustomIcons/UserProfermIcon/UserProfermIcon";
import CancelFormButton from "../../../../components/buttons/CancelFormButton/CancelFormButton";
import SubmitFormButton from "../../../../components/buttons/SubmitFormButton/SubmitFormButton";
import BasicInput from "../../../../components/inputs/BasicInput/BasicInput";
import FetchSelect from "../../../../components/inputs/FetchSelect/FetchSelect";
import FormSwitch from "../../../../components/inputs/FormSwitch/FormSwitch";
import config from "../../../../config/config";
import useFetch from "../../../../customHooks/useFetch";

const schema = yup
  .object({
    activation: yup.boolean().required(),

    groupe: yup.number().required("Type obligatoire").positive().integer(),

    nom: yup
      .string()
      .required("Nom de l'utilisateur obligatoire")
      .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]{2,}$/, "Entrez un nom valide"),

    prenom: yup
      .string()
      .required("Prénom de l'utilisateur obligatoire")
      .matches(
        /^[A-Za-z0-9À-ÖØ-öø-ÿ\s'-/.,;:!?]{2,}$/,
        "Entrez un prénom valide"
      ),

    email: yup
      .string()
      .required("Adresse e-mail obligatoire")
      .email("Entrez une adresse e-mail valide"),
  })
  .required();

const UtilisateurProfermEditer = ({ utilisateurData, onSubmitted }) => {
  const apiUrl = config.apiUrl;
  const isComponentMounted = useRef(true);
  const { nom, prenom, login, groupe, activation } = utilisateurData;
  const utilisateurDataRelevant = {
    nom,
    prenom,
    email: login,
    groupe: groupe.id,
    activation,
  };
  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: utilisateurDataRelevant,
  });

  const formValues = watch();

  const { loading, triggerFetch } = useFetch(apiUrl, isComponentMounted, []);

  const setInitValues = () => {
    reset(utilisateurDataRelevant);
  };

  const hasChanged =
    JSON.stringify(formValues) !== JSON.stringify(utilisateurDataRelevant);

  const onSuccess = () => {
    onSubmitted();
    toast.success("Utilisateur modifié avec succès");
  };

  const onSubmit = (data) => {
    const fullData = { ...data, id: utilisateurData.id };
    triggerFetch(
      apiUrl + "/utilisateur-interne-update",
      {
        method: "POST",
        body: JSON.stringify(fullData),
      },
      onSuccess
    );
  };

  useEffect(() => {
    setInitValues();
  }, []);

  return (
    <Row className="w-100 mt-5">
      <Col xl={3} />
      <Col xl={6}>
        <Form noValidate>
          <DashboardCard theme="light" className="p-5">
            <FormSwitch
              name="activation"
              registerFunction={register("activation")}
              text="Activation"
              setValue={setValue}
              control={control}
            />
            <BasicInput
              labelText="Nom"
              controlId="nom"
              control={control}
              name="nom"
              placeholder="Entrez le nom de l'utilisateur"
              autoFocus
              errorMessage={errors.nom?.message}
              registerFunction={register("nom")}
            />
            <BasicInput
              labelText="Prénom"
              controlId="prenom"
              control={control}
              name="prenom"
              placeholder="Entrez le prénom de l'utilisateur"
              errorMessage={errors.prenom?.message}
              registerFunction={register("prenom")}
            />
            <BasicInput
              labelText="Adresse e-mail"
              controlId="email"
              control={control}
              name="email"
              placeholder="Entrez l'adresse e-mail de l'utilisateur"
              errorMessage={errors.email?.message}
              registerFunction={register("email")}
            />
            <FetchSelect
              urlOptions={apiUrl + "/groupes-internes"}
              labelText="Groupe"
              onSelectChange={(selectedValue) =>
                setValue("groupe", selectedValue)
              }
              defaultIdValue={
                formValues.groupe ||
                (utilisateurDataRelevant && utilisateurDataRelevant.groupe)
              }
              placeholder="Selectionnez le groupe de l'utilisateur"
              control={control}
              name="groupe"
              option={{
                value: "id",
                text: (row) => (
                  <>
                    <AlignBox>
                      <UserProfermIcon
                        idGroupe={row.id}
                        size={2.3}
                        className="me-3"
                      />
                      {row.libelle}
                    </AlignBox>
                  </>
                ),
              }}
              setValue={setValue}
              errorMessage={errors.type?.message}
              registerFunction={register("groupe")}
            />
            <div className="text-end mt-5">
              <CancelFormButton
                disabled={!hasChanged}
                onClick={() => setInitValues()}
              />
              <SubmitFormButton
                disabled={!hasChanged}
                className="ms-3"
                onClick={handleSubmit(onSubmit)}
                loading={loading}
              ></SubmitFormButton>
            </div>
          </DashboardCard>
        </Form>
      </Col>
      <Col xl={3} />
    </Row>
  );
};

export default UtilisateurProfermEditer;
