import React from "react";
import { Dropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./NavbarDropdown.css";

const NavbarDropdown = ({
  links,
  libelle,
  icon,
  disabled,
  className,
  data,
  ...props
}) => {
  return (
    <Dropdown className="dropdown-nav-bar" {...props}>
      <Dropdown.Toggle
        id="dropdown-basic"
        disabled={disabled}
        className={"Roboto-Light " + className && className}
      >
        {icon} {libelle}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {links.map((link, linkIndex) => {
          if (link.component) {
            const Component = link.component;
            const ComponentProps = link.componentProps
              ? link.componentProps()
              : {};

            return (
              <div key={linkIndex}>
                <Dropdown.Item key={linkIndex} as="div">
                  <Component {...ComponentProps} data={data} />
                </Dropdown.Item>
              </div>
            );
          } else {
            return (
              <Dropdown.Item key={linkIndex} as="div">
                <NavLink to={link.to} onClick={link.onClick}>
                  {link.icon && <span className="me-3">{link.icon}</span>}{" "}
                  {link.text}
                </NavLink>
              </Dropdown.Item>
            );
          }
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarDropdown;
