import { format } from "date-fns";
import { fr } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./HeaderDatatableDatePicker.css";

const HeaderDatatableDatePicker = ({ onChange }) => {
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [checked, setChecked] = useState(false);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    if (checked) {
      const startDate =
        start &&
        format(start, "yyyy-MM-dd", {
          locale: fr,
        });
      const endDate =
        end &&
        format(end, "yyyy-MM-dd", {
          locale: fr,
        });

      setFilterData({ startDate, endDate });
    } else {
      setFilterData({});
    }
  }, [checked, start, end]);

  useEffect(() => {
    onChange(filterData);
  }, [filterData]);

  return (
    <div className="header-datatable-datepicker">
      <Form.Check
        checked={checked}
        className="ms-2"
        label="Sélectionner une plage de dates"
        type="switch"
        onChange={() => {
          setChecked(!checked);
        }}
      />
      <DatePicker
        selected={start}
        onChange={(dates) => {
          const [start, end] = dates;
          setStart(start);
          setEnd(end);
          if (start && end) setChecked(true);
          else setChecked(false);
        }}
        startDate={start}
        endDate={end}
        selectsRange
        inline
        locale={fr}
        showWeekNumbers
      ></DatePicker>
    </div>
  );
};

export default HeaderDatatableDatePicker;
