import React from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

function SortArrow(props) {
  switch (props.sort) {
    case "DESC":
      return <MdKeyboardArrowDown size={"1.5em"} style={{ marginTop: -10 }} />;
    case "ASC":
      return <MdKeyboardArrowUp size={"1.5em"} style={{ marginTop: -10 }} />;
    default:
      return <></>;
  }
}

export default SortArrow;
