import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Badge, Col, Stack } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import { PiBarcode } from "react-icons/pi";
import PosteIcon from "../../../components/CustomIcons/PosteIcon/PosteIcon";
import "./ScanComponents.css";

const ScanMultipleProducts = ({
  product,
  className,
  disabled,
  step,
  message = "attente scan \n cadre...",
  icon = <PosteIcon tache="scan_product" size={70 + "%"} />,
}) => {
  const dataProduct = Object.keys(product).length > 0 && product;

  return (
    <AnimatePresence mode="wait">
      {!dataProduct ? (
        <motion.div
          className={"h-100 " + (disabled && " opacity-25")}
          key="default"
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          transition={{ duration: 0.25, ease: [0.17, 0.67, 0.83, 0.67] }}
        >
          <Col
            xs={12}
            className={
              "bg-perform text-black text-uppercase h2 fw-bold rounded justify-content-center align-items-center text-center h-100 position-relative " +
              className
            }
          >
            {icon}
            <div>{message}</div>
            {step && (
              <div className="text-center position-absolute bottom-0 w-100 mb-4">
                {step}
              </div>
            )}
          </Col>
        </motion.div>
      ) : (
        <motion.div
          className="h-100"
          key="success"
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          transition={{ duration: 0.25, ease: [0.17, 0.67, 0.83, 0.67] }}
        >
          <Col
            xs={12}
            className={
              className + " bg-hybride rounded h-100 position-relative "
            }
          >
            <div className="text-uppercase w-100 h3 pt-4 text-center">
              <PosteIcon tache="scan_product" size={25 + "%"} />
              <br />
              <br />
              {Object.keys(dataProduct).length} produit
              {Object.keys(dataProduct).length > 1 && "s"} scanné
              {Object.keys(dataProduct).length > 1 && "s"}
            </div>
            <div
              style={{
                height: "calc(100% - 28rem)",
              }}
              className="card overflow-y-auto overflow-x-hidden mx-3"
            >
              {Object.keys(dataProduct)
                .reverse()
                .map((key, index) => {
                  return (
                    <div className="card h2 m-1 p-2 border-0" key={index}>
                      <Stack direction="horizontal" gap={2}>
                        <div>
                          <Badge
                            bg="light-secondary"
                            text="secondary"
                            className="text-uppercase"
                          >
                            <Stack direction="horizontal" gap={2}>
                              <PiBarcode size={30} className="me-1" />
                              <div>{key}</div>
                            </Stack>
                          </Badge>
                        </div>
                        <div>{dataProduct[key]}</div>
                      </Stack>
                    </div>
                  );
                })}
            </div>
            <div className="text-center position-absolute bottom-0 w-100 mb-4">
              <FaCheckCircle size={20 + "%"} />
            </div>
          </Col>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ScanMultipleProducts;
