import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import DashboardCard from "../../../components/CustomCard/DashboardCard/DashboardCard";
import PfIconPolygon from "../../../components/CustomIcons/PfIconPolygon/PfIconPolygon";
import PosteTypeIcon from "../../../components/CustomIcons/PosteTypeIcon/PosteTypeIcon";
import QRCodePDF from "../../../components/PDF/QRCodePDF/QRCodePDF";
import DetailsPanel from "../../../components/Panels/DetailsPanel/DetailsPanel";
import QRCodeLogo from "../../../components/QRCodeLogo/QRCodeLogo";
import config from "../../../config/config";
import useFetch from "../../../customHooks/useFetch";
import PosteEditer from "./PosteEditer/PosteEditer";
import TimelinePoste from "./TimelinePoste/TimelinePoste";

const PosteFiche = () => {
  const { id } = useParams();
  const apiUrl = config.apiUrl + "/poste/" + id;
  const [contentPage, setContentPage] = useState("activite");
  const isComponentMounted = useRef(true);
  const { data: dataPoste, triggerFetch: triggerFetchPoste } = useFetch(
    apiUrl,
    isComponentMounted,
    []
  );
  const triggerFetchPosteRef = useRef(triggerFetchPoste);
  const linksContentMenu = [
    {
      text: "Activité",
      nom: "activite",
      onClick: () => {
        setContentPage("activite");
      },
    },
    {
      text: "Editer",
      nom: "editer",
      onClick: () => {
        setContentPage("editer");
      },
    },
  ];
  const refreshData = () => {
    triggerFetchPosteRef.current(apiUrl, { method: "GET" });
  };

  useEffect(() => {
    triggerFetchPosteRef.current(apiUrl, {
      method: "GET",
    });
  }, [apiUrl]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 25 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
      className="h-100"
    >
      {dataPoste && (
        <DetailsPanel>
          <DetailsPanel.Main>
            <DetailsPanel.Main.Title
              title={dataPoste.nom}
              subTitle={dataPoste.type.libelle}
              linksMenu={linksContentMenu}
              activePage={contentPage}
            />
            <DetailsPanel.Main.Content key={contentPage}>
              {contentPage === "editer" ? (
                <PosteEditer posteData={dataPoste} onSubmitted={refreshData} />
              ) : (
                <TimelinePoste idEmplacement={dataPoste.emplacement} />
              )}
            </DetailsPanel.Main.Content>
          </DetailsPanel.Main>
          <DetailsPanel.Details>
            <DetailsPanel.Details.Title
              title={dataPoste.nom}
              subTitle={dataPoste.type.libelle}
              iconTitle={
                <PosteTypeIcon size={4.5} idPosteType={dataPoste.type.id} />
              }
            />
            <DetailsPanel.Details.Content>
              <DashboardCard className="mb-4">
                <Stack direction="horizontal" gap={3}>
                  <div>
                    <DashboardCard theme="light" className="d-inline-block">
                      <QRCodeLogo
                        size={100}
                        viewBox={`0 0 100 100`}
                        value={dataPoste.qrcode}
                      >
                        <PfIconPolygon size={50}></PfIconPolygon>
                      </QRCodeLogo>
                    </DashboardCard>
                  </div>
                  <div className="w-50">
                    <div className="Montserrat-SemiBold h5 mb-0">
                      Identification
                    </div>
                    <div className="Montserrat-Regular h6 text-uppercase">
                      QR Code
                    </div>
                    <div className="w-100">{dataPoste.qrcode}</div>
                  </div>
                </Stack>
                <Stack direction="horizontal" gap={3}>
                  <div className="ms-auto">
                    <QRCodePDF
                      qrCodeData={dataPoste.qrcode}
                      title={dataPoste.nom}
                      text="Imprimer"
                      variant="textural"
                      buttonSize="sm"
                    />
                  </div>
                </Stack>
              </DashboardCard>
              <DetailsPanel.Infos>
                <DetailsPanel.Infos.Title>Détails</DetailsPanel.Infos.Title>
                <DetailsPanel.Infos.Row libelle="Nom" value={dataPoste.nom} />
                <DetailsPanel.Infos.Row
                  libelle="Type"
                  value={
                    <div className="d-flex align-items-center">
                      {dataPoste.type.libelle}
                      <PosteTypeIcon
                        idPosteType={dataPoste.type.id}
                        reverse
                        size={2.5}
                      />
                    </div>
                  }
                />
                <DetailsPanel.Infos.Row
                  libelle="Date de création"
                  value={dataPoste.creation}
                />
              </DetailsPanel.Infos>
            </DetailsPanel.Details.Content>
          </DetailsPanel.Details>
        </DetailsPanel>
      )}
    </motion.div>
  );
};

export default PosteFiche;
