import React from "react";
import "./PosteIcon.css";

const PosteIcon = ({ className, size, tache, reverse, fillOpacity }) => {
  return (
    <>
      {(() => {
        switch (tache) {
          case "scan_product":
            return (
              <svg
                className={`stockage-icon ${className}`}
                xmlns={"http://www.w3.org/2000/svg"}
                width={`${size}`}
                height={`${size}`}
                style={reverse && { transform: "scaleX(-1)" }}
                viewBox="0 0 255 265"
              >
                <path
                  className={"poste-icon fill-25 stroke-5 " + fillOpacity}
                  d="m 43.186333,191.45993 c -0.366169,0.17075 54.697347,-25.5058 79.716457,-37.1724 l 10.70685,10.76222 -103.707427,48.35957 z M 122.90279,49.267426 134.19067,27.236442 133.60964,165.04975 122.86503,154.30514 Z m -93.000577,164.141894 13.28412,-21.94939 0,-105.02011 -13.28412,-10.572872 z M 29.737741,76.152018 134.0262,27.521512 122.90279,49.267426 43.186333,86.43982 Z"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 125.29564,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={"poste-icon stroke-5 no-round " + fillOpacity}
                  d="m 134.29148,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 143.28732,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 147.78524,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 156.65751,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 165.65335,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 170.15127,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 174.64919,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 183.64503,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 188.14295,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 102.80604,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 107.30396,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 116.2998,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 93.201605,210.23955 v -8.92537 l 8.92538,-1e-5"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 102.126995,247.73891 h -8.92537 l -2e-5,-8.92538"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 197.35046,238.81353 v 8.92537 l -8.92538,1e-5"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 188.42509,201.59894 h 8.92537 l 1e-5,8.92538"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="M 19.178162,65.142898 30.21332,76.178056 M 19.178162,65.142898 122.90279,16.77531 134.19067,27.236438 30.05479,75.795798 Z"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 19.178162,202.68527 1e-6,-137.542358 10.72405,10.724036 0,137.542372 z"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 113.92046,145.36058 0,-91.904624 8.98233,-4.18853 -0.0378,105.037714 z m -70.734127,32.98386 70.734127,-32.98386 8.94457,8.94456 -79.678697,37.15479 z"
                />
              </svg>
            );
          case "scan_operateur":
            return (
              <svg
                className={`stockage-icon ${className}`}
                xmlns={"http://www.w3.org/2000/svg"}
                width={`${size}`}
                height={`${size}`}
                style={reverse && { transform: "scaleX(-1)" }}
                viewBox="0 0 255 265"
              >
                <path
                  className={"poste-icon fill-25 stroke-5 " + fillOpacity}
                  d="m 146.13506,159.20905 -58.116424,27.10014 -58.11642,27.10013 c -10e-7,-28.76886 -0.291989,-62.251 58.11642,-86.27413 47.787784,-19.65491 58.116424,3.305 58.116424,32.07386 z"
                />
                <ellipse
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  cx="109.20477"
                  cy="56.292614"
                  rx="28.36734"
                  ry="31.625977"
                  transform="matrix(0.95480261,0.29724062,-0.32049019,0.94725184,0,0)"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 130.97048,141.62942 3.25296,-1.51689 v 3.67488 m 0,7.34976 v 3.67488 l -3.25296,1.51689 m -6.50594,3.03376 -3.25297,1.51689 v -3.67488 m 0,-7.34976 v -3.67488 l 3.25297,-1.51688"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 149.00342,210.23954 v -8.92537 l 8.92538,-1e-5"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 157.92881,247.7389 h -8.92537 l -2e-5,-8.92538"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 194.5,238.50424 v 9 l -8.92538,1e-5"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="155.35808"
                  y="207.72438"
                  ry="2.0436025e-16"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="155.35808"
                  y="230.49046"
                  ry="2.0436025e-16"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="176.94482"
                  y="230.62279"
                  ry="2.0436025e-16"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 155.29373,224.65236 h 11.66132"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 172.82752,224.65236 h 4.98031"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.05765,213.73814 v -6.01376"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.05765,213.73814 h 3.65096"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.00796,219.19525 h 7.04883"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 185.27496,219.19525 h 2.72069"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 171.69639,240.66093 v -8.82609"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 183.026,224.65236 h 5.02042"
                />
                <path
                  className={"poste-icon stroke-4 " + fillOpacity}
                  d="m 182.14573,213.73814 h 5.89089"
                />
                <path
                  className={"poste-icon stroke-4 " + fillOpacity}
                  d="m 178.93687,207.76771 8.98377,-0.0433"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 185.42509,201.59894 h 8.92537 l 1e-5,8.92538"
                />
              </svg>
            );
          case "scan_emplacement":
            return (
              <svg
                className={`stockage-icon ${className}`}
                xmlns={"http://www.w3.org/2000/svg"}
                width={`${size}`}
                height={`${size}`}
                style={reverse && { transform: "scaleX(-1)" }}
                viewBox="0 0 255 265"
              >
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 119.5158,91.823308 5.54952,-2.587782 56.3618,53.516564 -107.827564,50.28082 -54.421394,-54.42139 26.47179,-12.344 34.426653,-16.05341"
                />
                <path
                  className={"poste-icon stroke-5 fill-25 " + fillOpacity}
                  d="m 96.363624,57.688467 c -13.656853,10e-7 -24.728206,11.071352 -24.728206,24.728205 10e-7,13.656852 20.522076,45.073238 24.728206,51.733788 4.259166,-6.74127 24.727686,-38.076936 24.727686,-51.733788 0,-13.656855 -11.07083,-24.728207 -24.727686,-24.728205 z m 0,14.468367 a 9.0993452,9.0899792 0 0 1 9.099186,9.089884 9.0993452,9.0899792 0 0 1 -9.099186,9.089884 9.0993452,9.0899792 0 0 1 -9.099186,-9.089884 9.0993452,9.0899792 0 0 1 9.099186,-9.089884 z"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 149.00342,210.23954 v -8.92537 l 8.92538,-1e-5"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 157.92881,247.7389 h -8.92537 l -2e-5,-8.92538"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 194.5,238.50424 v 9 l -8.92538,1e-5"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="155.35808"
                  y="207.72438"
                  ry="2.0436025e-16"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="155.35808"
                  y="230.49046"
                  ry="2.0436025e-16"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="176.94482"
                  y="230.62279"
                  ry="2.0436025e-16"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 155.29373,224.65236 h 11.66132"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 172.82752,224.65236 h 4.98031"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.05765,213.73814 v -6.01376"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.05765,213.73814 h 3.65096"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.00796,219.19525 h 7.04883"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 185.27496,219.19525 h 2.72069"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 171.69639,240.66093 v -8.82609"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 183.026,224.65236 h 5.02042"
                />
                <path
                  className={"poste-icon stroke-4 " + fillOpacity}
                  d="m 182.14573,213.73814 h 5.89089"
                />
                <path
                  className={"poste-icon stroke-4 " + fillOpacity}
                  d="m 178.93687,207.76771 8.98377,-0.0433"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 185.42509,201.59894 h 8.92537 l 1e-5,8.92538"
                />
              </svg>
            );
          case "scan_stockage":
            return (
              <svg
                className={`stockage-icon ${className}`}
                xmlns={"http://www.w3.org/2000/svg"}
                width={`${size}`}
                height={`${size}`}
                style={reverse && { transform: "scaleX(-1)" }}
                viewBox="0 0 255 265"
              >
                <path
                  className={"poste-icon stroke-4 fill-25 " + fillOpacity}
                  d="m 144.97927,124.87789 5.836,-2.72137 v 49.60668 l 5.10016,5.10016 5.16894,-2.41031 1e-5,-41.13796 17.70195,17.70196 1e-5,7.57926 5.10016,5.10015 5.16892,-2.41031 -30.55552,14.37205 -30.55553,14.37205 -30.555512,14.37204 -30.55553,14.37205 -39.292435,-39.13403 5.168937,-2.41031 0.05911,-7.27534 17.739155,-8.24096 5.100142,4.83859 5.739731,-2.67648 v 49.47554 l 5.485357,5.42299 4.787049,-2.25163 -0.0034,-64.65228 9.402113,-4.38422 5.10015,4.83858 3.29612,-1.53701 v 49.48292 l 5.100159,5.10016 4.763844,-2.49983 0.405101,-64.08863 12.223619,-5.69997 5.10016,4.83858 2.91808,-1.36071 -1e-5,49.27633 5.10016,5.10016 5.16893,-2.41031 1e-5,-63.97157 9.68372,-4.5156 z"
                />
                <path
                  className={"poste-icon stroke-4 fill-25 " + fillOpacity}
                  d="m 22.440734,174.54015 44.007396,43.91873 v 7.08321 L 22.381925,181.47561 Z"
                />
                <path
                  className={"poste-icon stroke-4 fill-25 " + fillOpacity}
                  d="m 189.05542,161.28615 -0.17698,7.16575 -122.43031,57.09019 v -7.08321 L 97.099957,204.1657 127.75178,189.87252 158.4036,175.57934 Z"
                />
                <path
                  className={"poste-icon stroke-4 fill-25 " + fillOpacity}
                  d="m 27.540893,84.173764 5.256006,5.256007 -0.08707,87.800229 -5.168937,2.41031 z"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="M 22.440734,71.344536 66.44813,115.35194 v 103.10694 l -5.100159,-5.10016 V 117.98084 L 27.540893,84.173764 v 95.466546 l -5.100159,-5.10016 z"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="M 27.540893,68.934224 71.617067,112.94163 66.44813,115.35194 22.440734,71.344544 Z"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 66.44813,115.35194 5.168937,-2.41031 v 103.10694 l -5.168937,2.41031 z"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 58.234129,114.867 -0.01964,28.53009 -0.02178,21.95005 -2.584469,1.20516 V 142.68566 124.61 m 0,-27.636666 V 71.085745 l 5.256007,5.256008 v 25.887587"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="M 50.508098,91.873202 V 58.256527 L 94.515489,102.26392 V 205.37086 L 89.41533,200.2707 v -95.37788 l -33.807073,-33.807075 -2.5e-5,25.887589 m 0,15.267766 2.5e-5,30.44456 v 23.86664 l -5.100159,-5.10016 v -54.31116"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="M 55.608257,55.84621 99.684426,99.853614 94.515489,102.26392 50.508098,58.256536 Z"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 94.515489,102.26392 5.168937,-2.410306 V 202.96055 l -5.168937,2.41031 z"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 139.87911,49.856542 -1e-5,-33.274413 44.0074,44.0074 V 163.69646 l -5.10016,-5.10015 V 63.218428 l -33.80708,-33.807081 1e-5,24.98836 m 0,17.209597 -1e-5,29.401946 v 23.86665 l -5.10016,-5.10016 V 93.978843 l 1e-5,-27.38569"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 144.97926,14.171817 44.07617,44.0074 -5.16893,2.410312 -44.0074,-44.007392 z"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 183.8865,60.589529 5.16893,-2.410312 V 161.28615 l -5.16893,2.41031 z"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 150.23527,75.974351 -0.0218,21.950063 -0.0654,24.543176 -5.16894,2.41031 v -23.86664 l 7e-5,-29.401956 m 0,-17.209597 V 29.411349 l 5.256,5.256006 v 25.291203"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 88.735263,104.21276 -0.0098,24.75026 -0.02178,21.95006 -2.584469,1.20515 v -23.86664 -18.07566 m 1.7e-5,-23.827833 -1.7e-5,-29.696425 5.256007,5.256011 v 29.696403"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 81.01906,81.247946 -9e-6,-37.425494 44.007389,44.007408 v 103.10693 l -5.10016,-5.10016 V 90.458759 l -33.80707,-33.807087 -2.5e-5,25.887595 m 2.5e-5,19.057433 c 6.86e-4,101.28159 -8e-6,16.50671 0,26.65489 v 23.86664 l -5.100159,-5.10016 9e-6,-50.521516"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="M 86.11921,41.412135 130.19537,85.419548 125.02644,87.82986 81.01906,43.822461 Z"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 125.02644,87.82986 5.16893,-2.410312 V 188.52648 l -5.16893,2.41031 z"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 119.62426,90.139338 -0.01,24.750252 -0.0218,21.95005 -2.58447,1.20516 V 114.17815 96.102514 m 2e-5,-23.827843 -2e-5,-29.696435 5.256,5.256015 v 29.696412"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="M 111.90805,67.174512 V 29.749017 l 44.00738,44.007417 V 176.86336 l -5.10016,-5.10016 V 76.385327 l -33.80707,-33.807091 -2e-5,25.887597 m 2e-5,19.057443 c 6.9e-4,101.281584 0,16.506694 0,26.654884 v 23.86663 l -5.10015,-5.10015 V 82.423126"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 117.0082,27.338697 44.07617,44.007425 -5.16894,2.410312 -44.00738,-44.007408 z"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 155.91543,73.756434 5.16894,-2.410312 V 174.45305 l -5.16894,2.41031 z"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 149.00342,210.23954 v -8.92537 l 8.92538,-1e-5"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 157.92881,247.7389 h -8.92537 l -2e-5,-8.92538"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 194.5,238.50424 v 9 l -8.92538,1e-5"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="155.35808"
                  y="207.72438"
                  ry="2.0436025e-16"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="155.35808"
                  y="230.49046"
                  ry="2.0436025e-16"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="176.94482"
                  y="230.62279"
                  ry="2.0436025e-16"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 155.29373,224.65236 h 11.66132"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 172.82752,224.65236 h 4.98031"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.05765,213.73814 v -6.01376"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.05765,213.73814 h 3.65096"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.00796,219.19525 h 7.04883"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 185.27496,219.19525 h 2.72069"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 171.69639,240.66093 v -8.82609"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 183.026,224.65236 h 5.02042"
                />
                <path
                  className={"poste-icon stroke-4 " + fillOpacity}
                  d="m 182.14573,213.73814 h 5.89089"
                />
                <path
                  className={"poste-icon stroke-4 " + fillOpacity}
                  d="m 178.93687,207.76771 8.98377,-0.0433"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 185.42509,201.59894 h 8.92537 l 1e-5,8.92538"
                />
              </svg>
            );
          case "scan_responsable":
            return (
              <svg
                className={`stockage-icon ${className}`}
                xmlns={"http://www.w3.org/2000/svg"}
                width={`${size}`}
                height={`${size}`}
                style={reverse && { transform: "scaleX(-1)" }}
                viewBox="0 0 255 265"
              >
                <path
                  className={"poste-icon fill-25 stroke-5 " + fillOpacity}
                  d="m 158.61318,73.301283 -11.45434,-7.860249 -12.96873,4.979715 3.93596,-13.322674 -8.74355,-10.795174 13.88689,-0.373617 7.56492,-11.6515 4.64662,13.091767 13.41892,3.594151 -11.01513,8.464773 z"
                />
                <path
                  className={"poste-icon fill-25 stroke-5 " + fillOpacity}
                  d="m 146.13506,159.20905 -58.116424,27.10014 -58.11642,27.10013 c -10e-7,-28.76886 -0.291989,-62.251 58.11642,-86.27413 47.787784,-19.65491 58.116424,3.305 58.116424,32.07386 z"
                />
                <ellipse
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  cx="109.20477"
                  cy="56.292614"
                  rx="28.36734"
                  ry="31.625977"
                  transform="matrix(0.95480261,0.29724062,-0.32049019,0.94725184,0,0)"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 130.97048,141.62942 3.25296,-1.51689 v 3.67488 m 0,7.34976 v 3.67488 l -3.25296,1.51689 m -6.50594,3.03376 -3.25297,1.51689 v -3.67488 m 0,-7.34976 v -3.67488 l 3.25297,-1.51688"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 149.00342,210.23954 v -8.92537 l 8.92538,-1e-5"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 157.92881,247.7389 h -8.92537 l -2e-5,-8.92538"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 194.5,238.50424 v 9 l -8.92538,1e-5"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="155.35808"
                  y="207.72438"
                  ry="2.0436025e-16"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="155.35808"
                  y="230.49046"
                  ry="2.0436025e-16"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="176.94482"
                  y="230.62279"
                  ry="2.0436025e-16"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 155.29373,224.65236 h 11.66132"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 172.82752,224.65236 h 4.98031"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.05765,213.73814 v -6.01376"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.05765,213.73814 h 3.65096"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.00796,219.19525 h 7.04883"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 185.27496,219.19525 h 2.72069"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 171.69639,240.66093 v -8.82609"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 183.026,224.65236 h 5.02042"
                />
                <path
                  className={"poste-icon stroke-4 " + fillOpacity}
                  d="m 182.14573,213.73814 h 5.89089"
                />
                <path
                  className={"poste-icon stroke-4 " + fillOpacity}
                  d="m 178.93687,207.76771 8.98377,-0.0433"
                />
                <path
                  className={"poste-icon stroke-4 " + fillOpacity}
                  d="m 185.42509,201.59894 h 8.92537 l 1e-5,8.92538"
                />
              </svg>
            );
          default:
            return <></>;
        }
      })()}
    </>
  );
};

export default PosteIcon;
