import { createCanvas, loadImage } from "canvas";
import qrcode from "qrcode";
import LogoQRCodeProferm from "../../assets/logo/logo-qrcode-proferm.png";

// Fonction pour générer un QR code en base64 (format PNG)
const QRCodeBase64 = async (text) => {
  try {
    const base64Image = await qrcode.toDataURL(text, { margin: 0 });

    const canvas = createCanvas(250, 250);
    const context = canvas.getContext("2d");

    const qrImage = await loadImage(base64Image);
    if (!qrImage) {
      console.error("Erreur lors du chargement de l'image du QR code");
      return null;
    }

    context.drawImage(qrImage, 0, 0, canvas.width, canvas.height);

    const logo = await loadImage(LogoQRCodeProferm);
    if (!logo) {
      console.error("Erreur lors du chargement du logo");
      return null;
    }

    context.drawImage(
      logo,
      canvas.width / 2 - 25,
      canvas.height / 2 - 25,
      50,
      50
    );

    const finalBase64Image = canvas.toDataURL("image/png");

    return finalBase64Image;
  } catch (error) {
    console.error("Erreur lors de la génération du QR code en base64 :", error);
    return null;
  }
};

export default QRCodeBase64;
