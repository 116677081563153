import { yupResolver } from "@hookform/resolvers/yup";
import React, { useRef } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { PiPlusCircleThin } from "react-icons/pi";
import * as yup from "yup";
import AlignBox from "../../../../components/AlignBox/AlignBox";
import UserProfermIcon from "../../../../components/CustomIcons/UserProfermIcon/UserProfermIcon";
import SimpleModal from "../../../../components/CustomModal/SimpleModal/SimpleModal";
import BasicInput from "../../../../components/inputs/BasicInput/BasicInput";
import FetchSelect from "../../../../components/inputs/FetchSelect/FetchSelect";
import PasswordInput from "../../../../components/inputs/PasswordInput/PasswordInput";
import config from "../../../../config/config";
import useFetch from "../../../../customHooks/useFetch";

const schema = yup
  .object({
    groupe: yup.number().required("Groupe obligatoire").positive().integer(),
    email: yup
      .string()
      .email("Ceci n'est pas une adresse e-mail valide.")
      .required("Adresse e-mail obligatoire."),
    prenom: yup
      .string()
      .required("Veuillez entrer votre prénom.")
      .matches(
        /^[A-Za-zÀ-ÖØ-öø-ÿ0-9\s'-.,;:!?]{2,}$/,
        "Entrez un prénom valide"
      ),
    nom: yup
      .string()
      .required("Veuillez entrer votre nom.")
      .matches(
        /^[A-Za-zàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ\s/-]{2,}$/,
        'Le nom ne peut contenir que des minuscules, majuscules, caractères accentués et tiret "-".'
      ),
    password: yup
      .string()
      .required("Veuillez saisir un mot de passe")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{}|;:'<>,.?/èéêëàáâäçùúûü])[A-Za-z\d!@#$%^&*()_+\-=\[\]{}|;:'<>,.?/èéêëàáâäçùúûü]{8,}$/,
        "Le mot de passe doit contenir au moins: 8 caractères, 1 minuscule, 1 majuscule et 1 caractère spécial"
      ),
    passwordConfirmation: yup
      .string()
      .required("Veuillez confirmer le mot de passe")
      .oneOf(
        [yup.ref("password"), null],
        "Les mots de passes doivent être identiques."
      ),
  })
  .required();

const ModalNouvelUtilisateurProferm = ({ show, handleClose }) => {
  const apiUrl = config.apiUrl;
  const isComponentMounted = useRef(true);
  const { loading, data, triggerFetch } = useFetch(
    apiUrl,
    isComponentMounted,
    []
  );

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSuccess = () => {
    handleClose();
  };

  const onSubmit = (data) => {
    console.log("Données soumises:", data);
    triggerFetch(
      apiUrl + "/utilisateur-interne-create",
      {
        method: "POST",
        body: JSON.stringify(data),
      },
      onSuccess
    );
  };

  return (
    <SimpleModal
      show={show}
      handleClose={handleClose}
      iconTitle={<PiPlusCircleThin />}
      title={"Création d'un nouvel utilisateur Proferm"}
      validationButtonText={"Enregistrer l'utilisateur"}
      onSubmit={handleSubmit(onSubmit)}
      onExited={() => reset()}
    >
      <Form noValidate>
        <BasicInput
          labelText="Adresse e-mail"
          controlId="email"
          control={control}
          name="email"
          placeholder="Adresse e-mail de l'utilisateur Proferm"
          errorMessage={errors.email?.message}
          registerFunction={register("email")}
        />
        <BasicInput
          labelText="Nom de l'utilisateur"
          controlId="nom"
          control={control}
          autoComplete="new-nom"
          name="nom"
          placeholder="Nom de l'utilisateur Proferm"
          errorMessage={errors.nom?.message}
          registerFunction={register("nom")}
        />
        <BasicInput
          labelText="Prénom de l'utilisateur"
          controlId="prenom"
          control={control}
          name="prenom"
          placeholder="Prénom de l'utilisateur Proferm"
          errorMessage={errors.prenom?.message}
          registerFunction={register("prenom")}
        />
        <FetchSelect
          urlOptions={apiUrl + "/groupes-internes"}
          labelText="Groupe"
          onSelectChange={(selectedValue) => setValue("groupe", selectedValue)}
          placeholder="Selectionnez un groupe"
          control={control}
          name="groupe"
          option={{
            value: "id",
            text: (row) => (
              <>
                <AlignBox>
                  <UserProfermIcon
                    size={2}
                    idGroupe={row.id}
                    className="me-3"
                  />
                  {row.libelle}
                </AlignBox>
              </>
            ),
          }}
          setValue={setValue}
          errorMessage={errors.groupe?.message}
          registerFunction={register("groupe")}
        />
        <PasswordInput
          labelText="Mot de passe"
          controlId="password"
          control={control}
          name="password"
          autoComplete="new-password"
          placeholder="Mot de passe de l'utilisateur Proferm"
          errorMessage={errors.password?.message}
          registerFunction={register("password")}
        />
        <PasswordInput
          labelText="Confirmation du mot de passe"
          controlId="passwordConfirm"
          control={control}
          name="passwordConfirmation"
          autoComplete="new-passwordConfirmation"
          placeholder="Confirmez le mot de passe de l'utilisateur Proferm"
          errorMessage={errors.passwordConfirmation?.message}
          registerFunction={register("passwordConfirmation")}
        />
      </Form>
    </SimpleModal>
  );
};

export default ModalNouvelUtilisateurProferm;
