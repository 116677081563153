import React from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import "./FormSwitch.css";

const FormSwitch = ({
  className,
  text,
  name,
  control,
  value,
  setValue,
  defaultValue,
  registerFunction,
  ...props
}) => {
  const inputValue = value ? value : defaultValue;

  const render = (field) => (
    <Form.Switch
      {...setValue}
      className="ms-0"
      name={field.name}
      {...registerFunction}
      {...props}
    />
  );

  return (
    <Form.Group className={`custom-form-switch mb-4 ${className}`}>
      <Form.Label>{text}</Form.Label>
      <Controller
        name={name}
        control={control}
        defaultValue={inputValue}
        render={({ field }) => render(field)}
      />
    </Form.Group>
  );
};

export default FormSwitch;
