import React from "react";
import "./DashboardButton.css";

const DashboardButton = ({ className, children, ...props }) => {
  return (
    <button className={"dashboard-button mt-1 " + className} {...props}>
      {children}
    </button>
  );
};

export default DashboardButton;
