import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import DashboardCard from "../../../components/CustomCard/DashboardCard/DashboardCard";
import PfIconPolygon from "../../../components/CustomIcons/PfIconPolygon/PfIconPolygon";
import StockageTypeIcon from "../../../components/CustomIcons/StockageTypeIcon/StockageTypeIcon";
import QRCodePDF from "../../../components/PDF/QRCodePDF/QRCodePDF";
import DetailsPanel from "../../../components/Panels/DetailsPanel/DetailsPanel";
import QRCodeLogo from "../../../components/QRCodeLogo/QRCodeLogo";
import config from "../../../config/config";
import useFetch from "../../../customHooks/useFetch";
import StockageEditer from "./StockageEditer/StockageEditer";
import TimelineStockage from "./TimelineStockage/TimelineStockage";

const StockageFiche = () => {
  const { id } = useParams();
  const apiUrl = config.apiUrl + "/stockage/" + id;
  const [contentPage, setContentPage] = useState("activite");
  const isComponentMounted = useRef(true);
  const { data: dataStockage, triggerFetch: triggerFetchStockage } = useFetch(
    apiUrl,
    isComponentMounted,
    []
  );

  const triggerFetchStockageRef = useRef(triggerFetchStockage);
  const linksContentMenu = [
    {
      text: "Activité",
      nom: "activite",
      onClick: () => {
        setContentPage("activite");
      },
    },
    {
      text: "Editer",
      nom: "editer",
      onClick: () => {
        setContentPage("editer");
      },
    },
  ];
  const refreshData = () => {
    triggerFetchStockageRef.current(apiUrl, { method: "GET" });
  };

  useEffect(() => {
    triggerFetchStockageRef.current(apiUrl, {
      method: "GET",
    });
  }, [apiUrl]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 25 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
      className="h-100"
    >
      {dataStockage && (
        <DetailsPanel>
          <DetailsPanel.Main>
            <DetailsPanel.Main.Title
              title={dataStockage.nom}
              subTitle={dataStockage.type.libelle}
              linksMenu={linksContentMenu}
              activePage={contentPage}
            />
            <DetailsPanel.Main.Content key={contentPage}>
              {contentPage === "editer" ? (
                <StockageEditer
                  stockageData={dataStockage}
                  onSubmitted={refreshData}
                />
              ) : (
                <TimelineStockage idEmplacement={dataStockage.emplacement} />
              )}
            </DetailsPanel.Main.Content>
          </DetailsPanel.Main>
          <DetailsPanel.Details>
            <DetailsPanel.Details.Title
              title={dataStockage.nom}
              subTitle={dataStockage.type.libelle}
              iconTitle={
                <StockageTypeIcon
                  size={4.5}
                  idStockageType={dataStockage.type.id}
                />
              }
            />
            <DetailsPanel.Details.Content>
              <DashboardCard className="mb-4">
                <Stack direction="horizontal" gap={3}>
                  <div>
                    <DashboardCard theme="light" className="d-inline-block">
                      <QRCodeLogo
                        size={100}
                        viewBox={`0 0 100 100`}
                        value={dataStockage.qrcode}
                      >
                        <PfIconPolygon size={50}></PfIconPolygon>
                      </QRCodeLogo>
                    </DashboardCard>
                  </div>
                  <div className="w-50">
                    <div className="Montserrat-SemiBold h5 mb-0">
                      Identification
                    </div>
                    <div className="Montserrat-Regular h6 text-uppercase">
                      QR Code
                    </div>
                    <div className="w-100">{dataStockage.qrcode}</div>
                  </div>
                </Stack>
                <Stack direction="horizontal" gap={3}>
                  <div className="ms-auto">
                    <QRCodePDF
                      qrCodeData={dataStockage.qrcode}
                      title={dataStockage.nom}
                      text="Imprimer"
                      variant="textural"
                      buttonSize="sm"
                    />
                  </div>
                </Stack>
              </DashboardCard>
              <DetailsPanel.Infos>
                <DetailsPanel.Infos.Title>Détails</DetailsPanel.Infos.Title>
                <DetailsPanel.Infos.Row
                  libelle="Nom"
                  value={dataStockage.nom}
                />
                <DetailsPanel.Infos.Row
                  libelle="Type"
                  value={
                    <div className="d-flex align-items-center">
                      {dataStockage.type.libelle}
                      <StockageTypeIcon
                        idStockageType={dataStockage.type.id}
                        reverse
                        size={2.5}
                      />
                    </div>
                  }
                />
                <DetailsPanel.Infos.Row
                  libelle="Date de création"
                  value={dataStockage.creation}
                />
              </DetailsPanel.Infos>
            </DetailsPanel.Details.Content>
          </DetailsPanel.Details>
        </DetailsPanel>
      )}
    </motion.div>
  );
};

export default StockageFiche;
