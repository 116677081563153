import React from "react";
import { Button, Spinner } from "react-bootstrap";
import "./SubmitFormButton.css";

const SubmitFormButton = ({
  className,
  size,
  variant,
  loading,
  children,
  disabled,
  ...props
}) => {
  return (
    <>
      {
        <Button
          size={size ? size : "lg"}
          variant={variant ? variant : "perform"}
          className={`submit-form-button Montserrat-Regular ${className}`}
          disabled={!loading ? disabled : loading}
          {...props}
        >
          {loading && (
            <Spinner
              animation="border"
              role="status"
              className="spinner-container"
            />
          )}
          <span className="children">
            {children ? children : "Enregistrer"}
          </span>
        </Button>
      }
    </>
  );
};

export default SubmitFormButton;
