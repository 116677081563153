import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AlignBox from "../AlignBox/AlignBox";

const NavLinkItem = ({
  to,
  icon,
  text,
  onClick,
  disabled,
  className,
  download,
  href,
  as,
}) => {
  return (
    <Nav.Link
      as={to ? NavLink : as && as}
      href={href && href}
      to={to && to}
      onClick={onClick && (() => onClick())}
      disabled={disabled}
      className={className}
      download={download}
    >
      <AlignBox>
        {icon && icon}
        {text && <div className="ms-1">{text}</div>}
      </AlignBox>
    </Nav.Link>
  );
};
export default NavLinkItem;
