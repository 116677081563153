import React, { useEffect, useRef } from "react";
import { Nav, Navbar, Stack } from "react-bootstrap";
import NavbarDropdown from "../CustomDropdown/NavbarDropdown/NavbarDropdown";
import NavLinkItem from "../NavLinkItem/NavLinkItem";
import "./ToolNavBar.css";

const ToolNavBar = React.forwardRef(({ links, setRef }, ref) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (setRef) {
      setRef(containerRef);
    }
  }, [setRef, containerRef]);

  return (
    <Navbar expand="sm" className="tool-navbar" data-bs-theme="light">
      <Stack direction="horizontal" className="my-1">
        {links && (
          <Nav className="me-auto m-0 ms-2">
            {links.map((link, linkIndex) => {
              return (
                <React.Fragment key={linkIndex}>
                  {link.type === "link" || !link.type ? (
                    <NavLinkItem
                      to={link.to}
                      icon={link.icon}
                      text={link.text}
                      onClick={link.onClick}
                      disabled={link.disabled}
                      className="me-1"
                    />
                  ) : link.type === "vr" ? (
                    <div className="vr mx-1"></div>
                  ) : (
                    link.type === "dropdown" && (
                      <NavbarDropdown
                        className="h-100"
                        links={link.links}
                        libelle={link.text}
                        icon={link.icon}
                        disabled={link.disabled}
                      />
                    )
                  )}
                </React.Fragment>
              );
            })}
          </Nav>
        )}
        <div ref={containerRef}></div>
      </Stack>
    </Navbar>
  );
});

export default ToolNavBar;
