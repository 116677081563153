import React, { useState } from "react";
import { PiUserCircleGear } from "react-icons/pi";
import { Outlet } from "react-router-dom";
import SecondNavBar from "../../components/SecondNavBar/SecondNavBar";

export const AdministrationFreeRefContext = React.createContext();

const Administration = () => {
  const [freeRef, setFreeRef] = useState(null);

  const handleAdministrationFreeRef = (freeSecondNavBarRef) => {
    setFreeRef(freeSecondNavBarRef.current);
  };

  const links = [
    {
      to: "utilisateur_proferm",
      icon: <PiUserCircleGear size={"1.3em"} />,
      text: "Utilisateurs Proferm",
      type: "link",
    },
  ];

  return (
    <AdministrationFreeRefContext.Provider value={freeRef}>
      <SecondNavBar links={links} setRef={handleAdministrationFreeRef} />
      <Outlet />
    </AdministrationFreeRefContext.Provider>
  );
};

export default Administration;
