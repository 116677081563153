import { format } from "date-fns";
import { fr } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DashboardButton from "../../buttons/DashboardButton/DashboardButton";
import "./DashBoardDatePicker.css";

const DashBoardDatePicker = ({ onChange }) => {
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    const startDate =
      start &&
      format(start, "yyyy-MM-dd", {
        locale: fr,
      });
    const endDate =
      end &&
      format(end, "yyyy-MM-dd", {
        locale: fr,
      });

    setFilterData({ startDate, endDate });
  }, [start, end]);

  const periodMenu = [
    {
      label: "Aujourd'hui",
      value: {
        startDate: format(new Date(), "yyyy-MM-dd", { locale: fr }),
        endDate: format(new Date(), "yyyy-MM-dd", { locale: fr }),
      },
    },
    {
      label: "Hier",
      value: {
        startDate: format(
          new Date(new Date().setDate(new Date().getDate() - 1)),
          "yyyy-MM-dd",
          { locale: fr }
        ),
        endDate: format(
          new Date(new Date().setDate(new Date().getDate() - 1)),
          "yyyy-MM-dd",
          { locale: fr }
        ),
      },
    },
    {
      label: "Cette semaine",
      value: {
        startDate: format(
          new Date(
            new Date().setDate(new Date().getDate() - (new Date().getDay() - 1))
          ),
          "yyyy-MM-dd",
          { locale: fr }
        ),
        endDate: format(new Date(), "yyyy-MM-dd", { locale: fr }),
      },
    },
    {
      label: "La semaine dernière",
      value: {
        startDate: format(
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay() - 6)
          ),
          "yyyy-MM-dd",
          { locale: fr }
        ),
        endDate: format(
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay())
          ),
          "yyyy-MM-dd",
          { locale: fr }
        ),
      },
    },
    {
      label: "Ce mois",
      value: {
        startDate: format(new Date(new Date().setDate(1)), "yyyy-MM-dd", {
          locale: fr,
        }),
        endDate: format(new Date(), "yyyy-MM-dd", { locale: fr }),
      },
    },
    {
      label: "Le mois dernier",
      value: {
        startDate: format(
          new Date(
            new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)
          ),
          "yyyy-MM-dd",
          { locale: fr }
        ),
        endDate: format(new Date(new Date().setDate(0)), "yyyy-MM-dd", {
          locale: fr,
        }),
      },
    },
    {
      label: "Cette année",
      value: {
        startDate: format(
          new Date(new Date().setMonth(0)).setDate(1),
          "yyyy-MM-dd",
          {
            locale: fr,
          }
        ),
        endDate: format(new Date(), "yyyy-MM-dd", { locale: fr }),
      },
    },
    {
      label: "L'année dernière",
      value: {
        startDate: format(
          new Date(
            new Date(
              new Date().setFullYear(new Date().getFullYear() - 1)
            ).setDate(1)
          ).setMonth(0),
          "yyyy-MM-dd",
          { locale: fr }
        ),
        endDate: format(new Date(new Date().setMonth(0)), "yyyy-MM-dd", {
          locale: fr,
        }),
      },
    },
  ];

  useEffect(() => {
    onChange(filterData);
  }, [filterData]);

  return (
    <div className="dashboard-datepicker mt-2 ms-2 me-2">
      <Stack direction="horizontal" gap={3} className="ps-3">
        <Stack direction="vertical">
          {periodMenu.map((item, index) => (
            <DashboardButton
              key={index}
              onClick={() => {
                setStart(new Date(item.value.startDate));
                setEnd(new Date(item.value.endDate));
              }}
            >
              {item.label}
            </DashboardButton>
          ))}
        </Stack>
        <DatePicker
          selected={start}
          onChange={(dates) => {
            const [start, end] = dates;
            setStart(start);
            setEnd(end);
          }}
          inline
          startDate={start}
          endDate={end}
          selectsRange
          locale={fr}
          showWeekNumbers
        />
      </Stack>
    </div>
  );
};

export default DashBoardDatePicker;
