import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Form, Stack } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { createPortal } from "react-dom";
import { MdOutlineDataset } from "react-icons/md";
import {
  PiArrowsDownUpLight,
  PiDotsThreeOutlineLight,
  PiFileXlsThin,
  PiSquaresFourFill,
  PiSquaresFourLight,
} from "react-icons/pi";
import FooterDropdown from "../../components/CustomDropdown/FooterDropdown/FooterDropdown";
import { useBodyHeight } from "../../customHooks/useBodyHeight";
import { useContainer } from "../../customHooks/useContainer";
import useFetch from "../../customHooks/useFetch";
import { FlattenedObject } from "../../utilitaires/FlattenedObject/FlattenedObject";
import Loader from "../Loader/Loader";
import InputSearchBar from "../inputs/InputSearchBar/InputSearchBar";
import AutoRefresh from "./AutoRefresh/AutoRefresh";
import CardView from "./CardView/CardView";
import HeadColumn from "./HeadColumn/HeadColumn";
import Pagination from "./Pagination/Pagination";
import RTNoResult from "./RTNoResult/RTNoResult";
import "./ResizableTable.css";
import SelectionMenu from "./SelectionMenu/SelectionMenu";
import SwitchView from "./SwitchView/SwitchView";
import generateXLSX from "./generateXLSX/generateXLSX";
import { getNestedPropertyValue } from "./getNestedPropertyValue/gerNestedPropertyValue";

const ResizableTable = React.forwardRef(
  (
    {
      urlFetch,
      indexOrder,
      indexSort,
      sortInit,
      heightAdjust,
      limit,
      tableConfig,
      searchInputRef,
      switchViewRef,
      paginationRef,
      filterResultRef,
      selectionMenuLinks,
      selectionMenuRef,
      totalRef,
      cardConfig,
      viewModeInit,
      autoRefresh,
      checkable,
      exportExcel,
      initFilters,
    },
    ref
  ) => {
    const tableHeight = useBodyHeight(heightAdjust);
    const [indexColumn, setIndexColumn] = useState(indexSort);
    const [queryParameters, setQueryParameters] = useState({
      order: indexOrder,
      sort: sortInit,
      limit: limit ? limit : 50,
      searchValue: "",
      filters: { ...initFilters },
      currentPage: 1,
    });
    const [previousQueryParameters, setPreviousQueryParameters] =
      useState(queryParameters);
    const [viewMode, setViewMode] = useState(viewModeInit);
    const [timerId, setTimerId] = useState(null);
    const theadRef = useRef(null);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const tableContainerRef = useRef(null);
    const isComponentMounted = useRef(true);
    const [filter, setFilter] = useState("aucun");
    const [checkedItems, setCheckedItems] = useState({}); // Etat pour suivre les éléments cochés
    const [checkedPages, setCheckedPages] = useState({});
    const containerPagination = useContainer(paginationRef && paginationRef);
    const containerFilterResult = useContainer(
      filterResultRef && filterResultRef
    );
    const containerSelectionMenu = useContainer(
      selectionMenuRef && selectionMenuRef
    );
    const containerTotal = useContainer(totalRef && totalRef);
    const containerSwitchView = useContainer(switchViewRef && switchViewRef);
    const containerSearchBar = useContainer(searchInputRef && searchInputRef);
    const { data, loading, triggerFetch } = useFetch(
      urlFetch,
      "POST",
      isComponentMounted,
      [],
      {
        method: "POST",
        body: JSON.stringify(queryParameters),
      }
    );
    const rows = data.rows || {};
    const fields = data.fieldsNames;
    const fieldsFilter = data.fieldsFilter;
    const [filters, setFilters] = useState({ ...initFilters });
    const countResult = data.rows && data.rows.length;
    const countFiltered = data.recordsFiltered;
    const countTotal = data.recordsTotal;
    const dataToExport = () => {
      const flattenedCheckedItems = FlattenedObject(checkedItems);
      const dataToExport = [];

      const getNestedPropertyValuesOfArray = (row, keys) => {
        let stringValue = "";
        keys.forEach((key) => {
          const stringKeyValue = getNestedPropertyValue(row, key);
          stringValue += stringKeyValue + " ";
        });
        return stringValue;
      };

      Object.keys(flattenedCheckedItems).length > 0 &&
        Object.keys(flattenedCheckedItems).forEach((checkedItem) => {
          const rowToExport = {};
          tableConfig.forEach((config) => {
            rowToExport[config.nom] =
              config.key && Array.isArray(config.key)
                ? getNestedPropertyValuesOfArray(
                    flattenedCheckedItems[checkedItem],
                    config.key
                  )
                : getNestedPropertyValue(
                    flattenedCheckedItems[checkedItem],
                    config.key
                  );
          });

          dataToExport.push(rowToExport);
        });

      return dataToExport;
    };

    const fullSelectionMenuLinks = exportExcel
      ? [
          ...(selectionMenuLinks || []),
          {
            text: "",
            icon: <PiFileXlsThin size={23} />,
            type: "link",
            onClick: () =>
              generateXLSX({
                csvData: dataToExport(),
                filename: exportExcel.fileName,
              }),
            disabled: Object.keys(checkedItems).length === 0,
          },
          {
            type: "vr",
          },
        ]
      : selectionMenuLinks;

    const optionLinks = fields && [
      {
        text: "Trier",
        icon: <PiArrowsDownUpLight size={20} />,
        type: "dropdown",
        links: Object.keys(fields).map((field) => {
          return {
            text: tableConfig[field].nom,
            onClick: () => handleSort(fields[field], field),
            icon: (
              <PiArrowsDownUpLight
                size={20}
                className={parseInt(field) === indexColumn ? "" : " opacity-0"}
              />
            ),
          };
        }),
        noArrow: true,
      },
      {
        type: "vr",
      },
      {
        text: "",
        icon: <PiDotsThreeOutlineLight size={20} />,
        type: "dropdown",
        links: [
          {
            text: "Afficher tout",
            icon: <MdOutlineDataset size={20} />,
            onClick: () => handleShowAll(),
          },
          {
            text: "Sélectionner tout",
            icon: <PiSquaresFourFill size={20} />,
            onClick: () => handleCheckAllChange(),
          },
          {
            text: "Aucun",
            icon: <PiSquaresFourLight size={20} />,
            onClick: () => handleUncheckAll(),
          },
        ],
        noArrow: true,
      },
    ];

    const refreshTable = () => {
      triggerFetch(urlFetch, {
        method: "POST",
        body: JSON.stringify(queryParameters),
      });
    };

    const handleSort = (sortKey, newIndexColumn) => {
      setCheckedItems({});
      setCheckedPages({});

      setQueryParameters((prevQueryParameters) => ({
        ...prevQueryParameters,
        order: sortKey,
        sort: prevQueryParameters.sort === "ASC" ? "DESC" : "ASC",
      }));
      setIndexColumn(parseInt(newIndexColumn));
    };

    const handleSearch = (InputSearchValue) => {
      setCheckedItems({});
      setCheckedPages({});

      if (timerId) {
        clearTimeout(timerId);
      }

      const newTimerId = setTimeout(() => {
        setQueryParameters((prevQueryParameters) => ({
          ...prevQueryParameters,
          searchValue: InputSearchValue,
        }));
        if (InputSearchValue !== "") setFilter("'" + InputSearchValue + "'");
        else setFilter("aucun");
      }, 600);

      handlePage(1);
      setTimerId(newTimerId);
    };

    const handlePage = (page) => {
      setQueryParameters((prevQueryParameters) => ({
        ...prevQueryParameters,
        currentPage: page,
      }));
    };

    const handleShowAll = () => {
      setQueryParameters((prevQueryParameters) => ({
        ...prevQueryParameters,
        limit: countTotal,
      }));

      handlePage(1);
    };

    const handleViewMode = () => {
      setViewMode((prevViewMode) =>
        prevViewMode === "card" ? "table" : "card"
      );
    };

    const handleCheckChange = useCallback(
      (row) => {
        setCheckedItems((prev) => {
          const newCheckedItems = { ...prev };
          if (newCheckedItems[queryParameters.currentPage]) {
            if (newCheckedItems[queryParameters.currentPage][row.id]) {
              delete newCheckedItems[queryParameters.currentPage][row.id];
            } else {
              newCheckedItems[queryParameters.currentPage][row.id] = row;
            }
            if (
              Object.keys(newCheckedItems[queryParameters.currentPage])
                .length === 0
            ) {
              delete newCheckedItems[queryParameters.currentPage];
            }
          } else {
            newCheckedItems[queryParameters.currentPage] = { [row.id]: row };
          }

          // Vérifiez si tous les éléments de la page sont cochés
          const allChecked = Object.keys(rows).every(
            (rowKey) =>
              newCheckedItems[queryParameters.currentPage] &&
              newCheckedItems[queryParameters.currentPage][rows[rowKey].id]
          );

          // Si tous les éléments sont cochés, cochez la case à cocher de l'en-tête
          // Sinon, décochez la case à cocher de l'en-tête
          setCheckedPages((prev) => ({
            ...prev,
            [queryParameters.currentPage]: allChecked,
          }));

          return newCheckedItems;
        });
      },
      [queryParameters.currentPage, rows]
    );

    const handleCheckAllChange = useCallback(() => {
      setCheckedPages((prev) => {
        const newCheckedPages = {
          ...prev,
          [queryParameters.currentPage]: !prev[queryParameters.currentPage],
        };
        return newCheckedPages;
      });
      setCheckedItems((prev) => {
        const newCheckedItems = { ...prev };
        newCheckedItems[queryParameters.currentPage] = {};
        if (checkedPages[queryParameters.currentPage]) {
          Object.keys(rows).forEach((rowKey) => {
            delete newCheckedItems[queryParameters.currentPage][
              rows[rowKey].id
            ];
          });
          if (
            Object.keys(newCheckedItems[queryParameters.currentPage]).length ===
            0
          ) {
            delete newCheckedItems[queryParameters.currentPage];
          }
        } else {
          Object.keys(rows).forEach((rowKey) => {
            newCheckedItems[queryParameters.currentPage][rows[rowKey].id] =
              rows[rowKey];
          });
        }
        return newCheckedItems;
      });
    }, [checkedPages, queryParameters.page, rows]);

    const handleFilter = (fieldsName, filterData) => {
      setFilters((prev) => {
        const newFilters = { ...prev };
        newFilters[fieldsName] = filterData;

        setQueryParameters((prevQueryParameters) => ({
          ...prevQueryParameters,
          filters: newFilters,
        }));

        return newFilters;
      });
    };

    const handleUncheckAll = useCallback(() => {
      setCheckedItems({});
      setCheckedPages({});
    }, []);

    useEffect(() => {
      if (
        JSON.stringify(queryParameters) !==
        JSON.stringify(previousQueryParameters)
      ) {
        triggerFetch(urlFetch, {
          method: "POST",
          body: JSON.stringify(queryParameters),
        });
        setPreviousQueryParameters(queryParameters);
      }
    }, [queryParameters]);

    useEffect(() => {
      if (initFilters && Object.keys(initFilters).length > 0) {
        Object.keys(initFilters).forEach((key) => {
          console.log("key :>> ", key);
          handleFilter(key, initFilters[key]);
        });
      }
    }, []);

    useImperativeHandle(ref, () => ({
      refreshTable,
    }));

    return (
      <div
        style={{ maxHeight: `${tableHeight}px` }}
        ref={tableContainerRef}
        className={"conteneur-table "}
      >
        {containerSelectionMenu &&
          createPortal(
            <SelectionMenu
              links={fullSelectionMenuLinks}
              checkedItems={FlattenedObject(checkedItems)}
              disabled={Object.keys(checkedItems).length === 0}
            ></SelectionMenu>,
            containerSelectionMenu
          )}
        {containerSelectionMenu &&
          checkable &&
          createPortal(
            <SelectionMenu
              links={optionLinks}
              checkedItems={FlattenedObject(checkedItems)}
            ></SelectionMenu>,
            containerSelectionMenu
          )}
        {containerFilterResult
          ? createPortal(
              <Stack className="h-100" direction="horizontal" gap={3}>
                <div>Filtres: {filter}</div>
                <div className="vr" />
                <div> Résultats filtrés: {countFiltered}</div>
              </Stack>,
              containerFilterResult
            )
          : null}
        {containerTotal
          ? createPortal(
              <Stack direction="horizontal" gap={3}>
                <AutoRefresh
                  autoRefresh={autoRefresh}
                  triggerFetch={() =>
                    triggerFetch(urlFetch, {
                      method: "POST",
                      body: JSON.stringify(queryParameters),
                    })
                  }
                />
                <div className="vr" />
                <FooterDropdown
                  links={[
                    {
                      icon: <MdOutlineDataset size={20} />,
                      text: "Afficher tout",
                      onClick: () => handleShowAll(),
                    },
                  ]}
                  libelle={`Affichés: ${countResult} / ${countTotal}`}
                />
              </Stack>,
              containerTotal
            )
          : null}
        {containerPagination
          ? createPortal(
              <Stack direction="horizontal" style={{ marginTop: 2 }}>
                <Pagination
                  currentPageTable={queryParameters.currentPage}
                  changePage={handlePage}
                  countRows={countFiltered}
                  limit={queryParameters.limit}
                />
              </Stack>,
              containerPagination
            )
          : null}
        {containerSwitchView
          ? createPortal(
              <SwitchView
                onChange={handleViewMode}
                initViewMode={viewModeInit}
              />,
              containerSwitchView
            )
          : null}
        {containerSearchBar
          ? createPortal(
              <InputSearchBar onChange={handleSearch} />,
              containerSearchBar
            )
          : null}
        {viewMode === "table" ? (
          <Table responsive="lg" className="table-fixe">
            <thead ref={theadRef}>
              <tr>
                {checkable && (
                  <td
                    width={1}
                    className="text-center h6"
                    style={{ paddingBottom: "6px" }}
                    onClick={() => handleCheckAllChange()}
                  >
                    <div className="ms-2" style={{ paddingBottom: "2px" }}>
                      <Form.Check
                        checked={
                          checkedPages[queryParameters.currentPage] || false
                        }
                        onChange={() => {}}
                      />
                    </div>
                  </td>
                )}
                {tableConfig &&
                  fields &&
                  fieldsFilter &&
                  tableConfig.map((thead, index) => {
                    return (
                      <td key={fields[index]} width={thead.width}>
                        <HeadColumn
                          sortShow={index === indexColumn}
                          sort={queryParameters.sort}
                          filterData={
                            fieldsFilter && fieldsFilter[fields[index]]
                          }
                          title={thead.nom}
                          firstRender={isFirstRender}
                          onFirstRender={() => setIsFirstRender(false)}
                          onClickTitle={() => handleSort(fields[index], index)}
                          onFilter={(filterData) =>
                            handleFilter(fields[index], filterData)
                          }
                        />
                      </td>
                    );
                  })}
              </tr>
            </thead>
            {countResult > 0 ? (
              <tbody className="tableContainer">
                {rows &&
                  Object.keys(rows).map((rowKey, rowIndex) => {
                    const row = rows[rowKey];
                    return (
                      <tr key={rowIndex}>
                        {checkable && (
                          <td
                            width={1}
                            className="h6 text-center"
                            onClick={() => handleCheckChange(row)}
                          >
                            <div className="ms-2 mb-1">
                              <Form.Check
                                checked={
                                  checkedItems[queryParameters.currentPage]
                                    ? checkedItems[queryParameters.currentPage][
                                        row.id
                                      ] || false
                                    : false
                                }
                                onChange={() => {}}
                              />
                            </div>
                          </td>
                        )}
                        {tableConfig.map((config, colIndex) => {
                          return (
                            <td
                              key={`${rowIndex}-${colIndex}`}
                              width={config.width}
                              className={config.center && "text-center"}
                              onClick={
                                config.onClick && (() => config.onClick(row.id))
                              }
                            >
                              {config.render
                                ? config.render(row)
                                : getNestedPropertyValue(row, config.key)}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            ) : !loading ? (
              <tbody>
                <tr>
                  <td colSpan={20} style={{ border: 0 }}>
                    <RTNoResult
                      message={
                        'Aucun resultat trouvé correspondant à "' +
                        queryParameters.searchValue +
                        '"'
                      }
                    />
                  </td>
                </tr>
              </tbody>
            ) : (
              <Loader>Chargement en cours ...</Loader>
            )}
          </Table>
        ) : (
          <CardView rows={rows} config={cardConfig} />
        )}
      </div>
    );
  }
);

export default ResizableTable;
