import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import Auth from "./authentification/Auth";
import AuthentificationAtelier from "./authentification/AuthentificationAtelier/AuthentificationAtelier";
import AuthentificationUtilisateur from "./authentification/AuthentificationUtilisateur/AuthentificationUtilisateur";
import Forgot from "./authentification/AuthentificationUtilisateur/Forgot/Forgot";
import Login from "./authentification/AuthentificationUtilisateur/Login/Login";
import Register from "./authentification/AuthentificationUtilisateur/Register/Register";
import ResetPassword from "./authentification/AuthentificationUtilisateur/ResetPassword/ResetPassword";
import "./custom.scss";
import "./customVariablesToastify.scss";
import "./fonts.css";
import "./index.css";
import Administration from "./services/administration/Administration";
import UtilisateurProferm from "./services/administration/UtilisateurProferm/UtilisateurProferm";
import UtilisateurProfermFiche from "./services/administration/UtilisateurProfermFiche/UtilisateurProfermFiche";
import Atelier from "./services/atelier/Atelier";
import OperateurFiche from "./services/production/OperateurFiche/OperateurFiche";
import { default as Operateurs } from "./services/production/Operateurs/Operateurs";
import PosteFiche from "./services/production/PosteFiche/PosteFiche";
import Postes from "./services/production/Postes/Postes";
import Production from "./services/production/Production";
import ProduitFiche from "./services/production/ProduitFiche/ProduitFiche";
import Produits from "./services/production/Produits/Produits";
import StatistiquesProduction from "./services/production/StatistiquesProduction/StatistiquesProduction";
import StockageFiche from "./services/production/StockageFiche/StockageFiche";
import Stockages from "./services/production/Stockages/Stockages";
import TableauDeBordProduction from "./services/production/TableauDeBordProduction/TableauDeBordProduction";

const router = createBrowserRouter([
  {
    path: "/auth",
    element: <Auth />,
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: "utilisateur",
        element: <AuthentificationUtilisateur />,
        children: [
          {
            index: true,
            element: <Login />,
          },
          {
            path: "login",
            element: <Login />,
          },
          {
            path: "register",
            element: <Register />,
          },
          {
            path: "forgot",
            element: <Forgot />,
          },
          {
            path: "reset_password/:token",
            element: <ResetPassword />,
          },
        ],
      },
      {
        path: "atelier",
        element: <AuthentificationAtelier />,
      },
    ],
  },
  {
    path: "/",
    element: <App />,
    children: [
      // {
      //   //COMMANDES
      //   path: "commandes",
      //   element: <Commandes />,
      // },
      // {
      //   //APPROVISIONNEMENT
      //   path: "approvisionnement",
      //   element: <Approvisionnement />,
      // },
      {
        //PRODUCTION
        path: "production",
        element: <Production />,
        children: [
          { index: true, element: <TableauDeBordProduction /> },
          {
            path: "tableau_de_bord",
            element: <TableauDeBordProduction />,
          },
          {
            path: "produits",
            element: <Produits />,
          },
          {
            path: "produit_fiche/:id",
            element: <ProduitFiche />,
          },
          {
            path: "operateurs",
            element: <Operateurs />,
          },
          {
            path: "operateur_fiche/:id",
            element: <OperateurFiche />,
          },
          {
            path: "postes",
            element: <Postes />,
          },
          {
            path: "poste_fiche/:id",
            element: <PosteFiche />,
          },
          {
            path: "stockages",
            element: <Stockages />,
          },
          {
            path: "stockage_fiche/:id",
            element: <StockageFiche />,
          },
          {
            path: "statistiques",
            element: <StatistiquesProduction />,
          },
        ],
      },
      // {
      //   //SERVICE APRES-VENTE
      //   path: "service_apres_vente",
      //   element: <ServiceApresVentes />,
      //   children: [
      //     { index: true, element: <TableauDeBordServiceApresVente /> },
      //     {
      //       path: "tableau_de_bord",
      //       element: <TableauDeBordServiceApresVente />,
      //     },
      //     {
      //       path: "nouvelle_demande",
      //       element: <NouvelleDemande />,
      //     },
      //     {
      //       path: "statistiques",
      //       element: <Statistiques />,
      //     },
      //   ],
      // },
      // {
      //   //LOGISTIQUE
      //   path: "logistique",
      //   element: <Logistique />,
      // },
      // {
      //   //CLIENTS
      //   path: "clients",
      //   element: <Clients />,
      //   children: [
      //     { index: true, element: <Clients /> },
      //     {
      //       path: "tableau_de_bord",
      //       element: <TableauDeBordClients />,
      //     },
      //     {
      //       path: "nouveau_client",
      //       element: <NouveauClient />,
      //     },
      //   ],
      // },
      {
        //ADMINISTRATION
        path: "administration",
        element: <Administration />,
        children: [
          { index: true, element: <UtilisateurProferm /> },
          {
            path: "utilisateur_proferm",
            element: <UtilisateurProferm />,
          },
          {
            path: "utilisateur_proferm_fiche/:id",
            element: <UtilisateurProfermFiche />,
          },
        ],
      },
      {
        path: "atelier",
        element: <Atelier />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <RouterProvider router={router} />
    <ToastContainer
      position="bottom-center"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={true}
      theme="light"
    />
  </>
);
