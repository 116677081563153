import React, { useEffect, useRef, useState } from "react";
import "react-vertical-timeline-component/style.min.css";
import PosteTypeIcon from "../../../../components/CustomIcons/PosteTypeIcon/PosteTypeIcon";
import StockageTypeIcon from "../../../../components/CustomIcons/StockageTypeIcon/StockageTypeIcon";
import CustomTimeline from "../../../../components/CustomTimeline/CustomTimeline";
import config from "../../../../config/config";
import useFetch from "../../../../customHooks/useFetch";

const TimelineOperateur = ({ idOperateur }) => {
  const apiUrlOperateurActivity =
    config.apiUrl + "/mouvements-operateur/" + idOperateur;
  const isComponentMounted = useRef(true);
  const [localDataOperateurActivity, setLocalDataOperateurActivity] =
    useState(null);

  const {
    data: dataOperateurActivity,
    triggerFetch: triggerFetchOperateurActivity,
  } = useFetch(apiUrlOperateurActivity, isComponentMounted, []);

  useEffect(() => {
    if (idOperateur) {
      triggerFetchOperateurActivity(apiUrlOperateurActivity, {
        method: "GET",
      });
    }
  }, [idOperateur, apiUrlOperateurActivity]);

  useEffect(() => {
    setLocalDataOperateurActivity(dataOperateurActivity);

    return () => {
      setLocalDataOperateurActivity(null);
    };
  }, [dataOperateurActivity]);

  return (
    localDataOperateurActivity && (
      <CustomTimeline layout="1-column-left">
        {localDataOperateurActivity.map((event, index) => {
          return (
            <CustomTimeline.Element
              key={index}
              titleClassName={event.poste ? "text-hybride" : "text-perform"}
              contentArrowStyle={{
                borderRight: "none",
              }}
              date={<>le {event.creation}</>}
              iconStyle={{
                background: event.poste ? "#52b876" : "#dacc2e",
                color: "#fff",
                width: "20px",
                height: "20px",
                marginLeft: "10px",
                marginTop: "30px",
              }}
              iconTitle={
                event.poste ? (
                  <PosteTypeIcon
                    idPosteType={event.poste.type.id}
                    size={3.5}
                    className="me-2"
                  />
                ) : (
                  <StockageTypeIcon
                    idStockageType={event.stockage.type.id}
                    size={3.5}
                    className="me-2"
                  />
                )
              }
              title={event.poste ? event.poste.nom : event.stockage.nom}
              subTitle={
                event.poste
                  ? event.poste.type.libelle
                  : event.stockage.type.libelle
              }
            >
              N° de commande : {parseInt(event.product.codeBarre.cmd)}.
              {event.product.codeBarre.extension} <br />
              Position : {parseInt(event.product.codeBarre.position)} <br />
              Repère quantité :{" "}
              {parseInt(event.product.codeBarre.repere_quantite)}
            </CustomTimeline.Element>
          );
        })}
      </CustomTimeline>
    )
  );
};

export default TimelineOperateur;
