import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import SubmitFormButton from "../../../components/buttons/SubmitFormButton/SubmitFormButton";
import InputLoginFloatingLabel from "../../../components/inputs/InputLoginFloatingLabel/InputLoginFloatingLabel";
import config from "../../../config/config";
import useFetch from "../../../customHooks/useFetch";

const schema = yup
  .object({
    prenom: yup
      .string()
      .required("Veuillez entrer votre prénom.")
      .matches(
        /^[A-Za-zàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ\s/-]{2,}$/,
        'Le prénom ne peut contenir que des minuscules, majuscules, caractères accentués et tiret "-".'
      ),
    nom: yup
      .string()
      .required("Veuillez entrer votre nom.")
      .matches(
        /^[A-Za-zàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ\s/-]{2,}$/,
        'Le nom ne peut contenir que des minuscules, majuscules, caractères accentués et tiret "-".'
      ),
    email: yup
      .string()
      .required("Veuillez entrer une adresse e-mail")
      .email("Veuillez entrer une adresse email valide."),
    password: yup
      .string()
      .required("Veuillez saisir un mot de passe")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{}|;:'<>,.?/èéêëàáâäçùúûü])[A-Za-z\d!@#$%^&*()_+\-=\[\]{}|;:'<>,.?/èéêëàáâäçùúûü]{8,}$/,
        "Le mot de passe doit contenir au moins: 8 caractères, 1 minuscule, 1 majuscule et 1 caractère spécial"
      ),
    passwordConfirmation: yup
      .string()
      .required("Veuillez confirmer votre mot de passe")
      .oneOf(
        [yup.ref("password"), null],
        "Les mots de passes doivent être identiques."
      ),
  })
  .required();

const Register = () => {
  const apiUrl = config.authentificationUrl;
  const [ip, setIP] = useState("");
  const isComponentMounted = useRef(true);
  const { loading, triggerFetch } = useFetch(apiUrl, isComponentMounted, []);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSuccess = (message) => {
    navigate("/auth/utilisateur/login");
    toast.success(message, {
      position: "bottom-center",
      autoClose: 6000,
    });
  };

  const onSubmit = (data) => {
    const dataToSend = {
      ...data,
      ip: ip,
    };
    triggerFetch(
      apiUrl + "/register",
      {
        method: "POST",
        body: JSON.stringify(dataToSend),
      },
      (response) => {
        onSuccess(response.message);
      }
    );
  };

  const getIP = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };

  useEffect(() => {
    getIP();
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  return (
    <>
      <h2 className="text-perform Montserrat-Bold mx-md-5">
        Créez votre compte
      </h2>
      <p className="Montserrat-Regular h6 d-none d-sm-block pb-lg-3">
        Veuillez remplir les champs ci-dessous.
      </p>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <InputLoginFloatingLabel
          controlId="prenomInput"
          label="Prénom"
          type="text"
          placeholder="Votre prénom"
          autoFocus
          name="prenom"
          errors={errors.prenom}
          registerFunction={register("prenom")}
        />
        <p className="text-danger mt-1 ms-1">{errors.prenom?.message}</p>
        <InputLoginFloatingLabel
          controlId="nomInput"
          label="Nom"
          type="text"
          placeholder="Votre Nom"
          name="nom"
          errors={errors.nom}
          registerFunction={register("nom")}
        />
        <p className="text-danger mt-1 ms-1">{errors.entreprise?.message}</p>
        <InputLoginFloatingLabel
          controlId="emailInput"
          label="Adresse e-mail"
          type="email"
          placeholder="Votre adresse e-mail"
          name="email"
          errors={errors.email}
          registerFunction={register("email")}
        />
        <p className="text-danger mt-1 ms-1">{errors.email?.message}</p>
        <InputLoginFloatingLabel
          controlId="passwordInput"
          label="Mot de passe"
          type="password"
          placeholder=""
          name="password"
          setFocus={setFocus}
          errors={errors.password}
          registerFunction={register("password")}
        />
        <p className="text-danger mt-1 ms-1">{errors.password?.message}</p>
        <InputLoginFloatingLabel
          controlId="passwordConfirmationInput"
          label="Confirmation du mot de passe"
          type="password"
          placeholder=""
          name="passwordConfirmation"
          setFocus={setFocus}
          errors={errors.passwordConfirmation}
          registerFunction={register("passwordConfirmation")}
        />
        <p className="text-danger mt-1 ms-1">
          {errors.passwordConfirmation?.message}
        </p>

        <Link to="/auth/utilisateur/login">
          <Button
            type="button"
            variant="light"
            className="btn-light mt-3 px-4 Montserrat-Regular text-uppercase"
            size="lg"
          >
            Annuler
          </Button>
        </Link>
        <SubmitFormButton
          loading={loading}
          className="mt-3 ms-3"
          size="lg"
          type="submit"
        >
          S'ENREGISTRER
        </SubmitFormButton>
      </Form>
    </>
  );
};

export default Register;
