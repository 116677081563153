import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import SubmitFormButton from "../../../components/buttons/SubmitFormButton/SubmitFormButton";
import InputLoginFloatingLabel from "../../../components/inputs/InputLoginFloatingLabel/InputLoginFloatingLabel";
import config from "../../../config/config";
import useFetch from "../../../customHooks/useFetch";

const schema = yup
  .object({
    password: yup
      .string()
      .required("Veuillez saisir un mot de passe")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{}|;:'<>,.?/èéêëàáâäçùúûü])[A-Za-z\d!@#$%^&*()_+\-=\[\]{}|;:'<>,.?/èéêëàáâäçùúûü]{8,}$/,
        "Le mot de passe doit contenir au moins: 8 caractères, 1 minuscule, 1 majuscule et 1 caractère spécial"
      ),
    passwordConfirmation: yup
      .string()
      .required("Veuillez confirmer votre mot de passe")
      .oneOf(
        [yup.ref("password"), null],
        "Les mots de passes doivent être identiques."
      ),
  })
  .required();

const ResetPassword = () => {
  const apiUrl = config.authentificationUrl;
  const [ip, setIP] = useState("");
  const isComponentMounted = useRef(true);
  const { loading, triggerFetch } = useFetch(apiUrl, isComponentMounted, []);
  const navigate = useNavigate();
  const { token } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSuccess = (message) => {
    navigate("/auth/utilisateur/login");
    toast.success(message, {
      position: "bottom-center",
      autoClose: 6000,
    });
  };

  const onSubmit = (data) => {
    const dataToSend = {
      ...data,
      token: token,
    };
    triggerFetch(
      apiUrl + "/password-reset",
      {
        method: "POST",
        body: JSON.stringify(dataToSend),
      },
      (response) => {
        onSuccess(response.message);
      }
    );
  };

  const getIP = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };

  useEffect(() => {
    getIP();
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  return (
    <>
      <h2 className="text-perform Montserrat-Bold mx-md-5">
        Réinitialisation du mot de passe
      </h2>
      <p className="Montserrat-Regular h6 d-none d-sm-block pb-lg-3">
        Veuillez remplir les champs ci-dessous.
      </p>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <InputLoginFloatingLabel
          controlId="passwordInput"
          label="Nouveau mot de passe"
          type="password"
          placeholder=""
          name="password"
          setFocus={setFocus}
          errors={errors.password}
          registerFunction={register("password")}
        />
        <p className="text-danger mt-1 ms-1">{errors.password?.message}</p>
        <InputLoginFloatingLabel
          controlId="passwordConfirmationInput"
          label="Confirmation du nouveau mot de passe"
          type="password"
          placeholder=""
          name="passwordConfirmation"
          setFocus={setFocus}
          errors={errors.passwordConfirmation}
          registerFunction={register("passwordConfirmation")}
        />
        <p className="text-danger mt-1 ms-1">
          {errors.passwordConfirmation?.message}
        </p>

        <SubmitFormButton
          loading={loading}
          className="mt-3"
          size="lg"
          type="submit"
        >
          REINITIALISER
        </SubmitFormButton>
        <Link to="/auth/utilisateur/login">
          <Button
            type="button"
            variant="light"
            className="btn-light mt-3 px-4 Montserrat-Regular text-uppercase"
            size="lg"
          >
            Annuler
          </Button>
        </Link>
      </Form>
    </>
  );
};

export default ResetPassword;
