import React from "react";
import { Button } from "react-bootstrap";

const CancelFormButton = ({ className, size, variant, children, ...props }) => {
  return (
    <Button
      size={size ? size : "lg"}
      variant={variant ? variant : "light-secondary"}
      className={`Montserrat-Regular ${className}`}
      {...props}
    >
      {children ? children : "Annuler"}
    </Button>
  );
};

export default CancelFormButton;
