import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import EyePassword from "../../EyePassword/EyePassword";
import "./PasswordInput.css";

const PasswordInput = (props) => {
  const inputValue =
    props.value !== undefined ? props.value : props.defaultValue;

  const [passwordShow, setPasswordShow] = useState(true);

  const handleClickShowPassword = () => {
    setPasswordShow(!passwordShow);
  };

  return (
    <div className={props.hidden && "hidden"}>
      <Form.Group
        className="password-input w-100 position-relative"
        controlId={props.controlId}
        style={{ height: 64 + "px" }}
      >
        <Form.Label className={props.labelClassName}>
          {props.labelText}
        </Form.Label>
        <Controller
          name={props.name}
          control={props.control}
          defaultValue={inputValue}
          disabled={props.disabled && props.disabled}
          render={({ field }) => (
            <>
              <Form.Control
                ref={props.inputRef}
                onKeyDown={props.onKeyDown}
                className={
                  "position-absolute pe-5 " +
                  props.className +
                  (props.errorMessage ? " error" : "")
                }
                type={passwordShow ? "password" : "text"}
                placeholder={props.placeholder}
                name={field.name}
                {...props.registerFunction}
                size={props.size}
                autoFocus={props.autoFocus}
                autoComplete={props.autoComplete}
                disabled={field.disabled}
                defaultValue={inputValue}
                onChange={(e) => {
                  field.onChange(e);
                  if (props.onChange) {
                    props.onChange(e.target.value);
                  }
                }}
                onBlur={field.onBlur}
                style={{ maxWidth: "100%" }}
              />
              <EyePassword
                style={{ marginTop: 6 + "px", marginRight: 10 + "px" }}
                passwordVisible={passwordShow}
                handleClick={handleClickShowPassword}
              />
            </>
          )}
        />
      </Form.Group>
      <p className="text-danger mt-1 ms-1 mb-4 ">{props.errorMessage}</p>
    </div>
  );
};

export default PasswordInput;
