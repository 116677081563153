import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dropdown } from "react-bootstrap";
import { Controller } from "react-hook-form";
import useFetch from "../../../customHooks/useFetch";
import "./FetchSelect.css";

const FetchSelect = ({
  className,
  urlOptions,
  labelText,
  defaultIdValue,
  onSelectChange,
  control,
  placeholder,
  option,
  name,
  setValue,
  errorMessage,
}) => {
  const isComponentMounted = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const [valueText, setValueText] = useState(null);

  const { data, loading } = useFetch(urlOptions, "GET", isComponentMounted, []);

  const getNestedPropertyValue = (obj, key) => {
    const keys = key.split(".");
    return keys.reduce(
      (acc, currentKey) => (acc ? acc[currentKey] : undefined),
      obj
    );
  };

  const memoizedOnSelectChange = useCallback(onSelectChange, []);
  const memoizedOption = useMemo(() => option, []);

  const handleDropdownClick = useCallback(
    (value, valueText, object) => {
      setValue(name, value);
      setValueText(valueText);

      if (memoizedOnSelectChange) memoizedOnSelectChange(object);
    },
    [memoizedOnSelectChange, name, setValue]
  );

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (defaultIdValue && data) {
      // Recherche de l'objet correspondant à l'ID
      const selectedItem = data.find((row) => {
        const value = getNestedPropertyValue(row, memoizedOption.value);
        return value === defaultIdValue;
      });

      // Initialisation du composant avec les données trouvées
      if (selectedItem) {
        const selectedValue = getNestedPropertyValue(
          selectedItem,
          memoizedOption.value
        );
        setValue(name, selectedValue);
        setValueText(memoizedOption.text(selectedItem));
      }
    }
  }, [data, defaultIdValue, memoizedOption, name, setValue]);

  return (
    <>
      {isLoading ? (
        <>Chargement de la liste en cours ...</>
      ) : (
        <>
          <Dropdown className="fetch-select">
            <div className="form-label">{labelText}</div>

            <Controller
              name={name}
              control={control}
              defaultValue={1}
              render={({ field }) => (
                <>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className={
                      className +
                      " w-100 " +
                      (!valueText && "novalue ") +
                      (errorMessage ? " error" : "")
                    }
                    {...field}
                  >
                    {valueText ? valueText : placeholder}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="w-100 mt-0 menu">
                    {data &&
                      data.map((row) => {
                        const value = getNestedPropertyValue(row, option.value);

                        return (
                          <Dropdown.Item
                            key={value}
                            value={value}
                            onClick={() => {
                              handleDropdownClick(value, option.text(row), row);
                              field.onChange(value);
                            }}
                            className="p-2 ps-3"
                          >
                            {option.text(row)}
                          </Dropdown.Item>
                        );
                      })}
                  </Dropdown.Menu>
                </>
              )}
            />
          </Dropdown>
          <p className="text-danger mt-1 ms-1 mb-4">{errorMessage}</p>
        </>
      )}
    </>
  );
};

export default FetchSelect;
